/*COMMON STYLES*/
/**font face**/
@font-face {
  font-family: 'latoregular';
  src: url("../../assets/fonts/lato-regular-webfont.eot");
  src: url("../../assets/fonts/lato-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/lato-regular-webfont.woff2") format("woff2"), url("../../assets/fonts/lato-regular-webfont.woff") format("woff"), url("../../assets/fonts/lato-regular-webfont.ttf") format("truetype"), url("../../assets/fonts/lato-regular-webfont.svg#latoregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'latolight';
  src: url("../../assets/fonts/lato-light-webfont.eot");
  src: url("../../assets/fonts/lato-light-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/lato-light-webfont.woff2") format("woff2"), url("../../assets/fonts/lato-light-webfont.woff") format("woff"), url("../../assets/fonts/lato-light-webfont.ttf") format("truetype"), url("../../assets/fonts/lato-light-webfont.svg#latolight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'latobold';
  src: url("../../assets/fonts/lato-bold-webfont.eot");
  src: url("../../assets/fonts/lato-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/lato-bold-webfont.woff2") format("woff2"), url("../../assets/fonts/lato-bold-webfont.woff") format("woff"), url("../../assets/fonts/lato-bold-webfont.ttf") format("truetype"), url("../../assets/fonts/lato-bold-webfont.svg#latobold") format("svg");
  font-weight: normal;
  font-style: normal; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-touch-callout: default;
  -webkit-overflow-scrolling: touch;
  font-family: "latoregular", sans-serif;
  white-space: normal; }

body,
form,
input,
select,
textarea,
button,
label,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "latoregular", sans-serif; }

html,
body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  font-weight: normal;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #C0C8D0;
  font-family: "latoregular", sans-serif; }

html {
  height: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%; }

img {
  display: block;
  margin: 0 auto;
  max-width: 100%; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  ul li {
    list-style: none; }

.nopadding {
  padding: 0 !important; }

.clear {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden; }

.clearfix {
  *zoom: 1; }

.clearfix:after,
.container:after {
  clear: both;
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden; }

sup {
  vertical-align: top;
  padding: 0;
  margin: 0; }

:focus,
a {
  outline: none; }

a {
  color: #0093d0;
  font-size: 14px;
  line-height: auto;
  text-decoration: none; }
  a:hover {
    color: #06AAE5;
    text-decoration: none; }

h1 {
  font-size: 18px;
  line-height: 20px;
  color: #000;
  letter-spacing: 0.3px;
  font-family: "latoregular",sans-serif;
  font-weight: bold;
  margin-bottom: 8px; }

h2 {
  font-size: 15px;
  line-height: 17px;
  color: #000;
  text-transform: none;
  letter-spacing: 0.3px;
  font-family: "latoregular",sans-serif;
  font-weight: bold; }

h3 {
  font-size: 14px;
  line-height: 16px;
  color: #4a4a4a;
  font-family: "latoregular",sans-serif;
  letter-spacing: 0.3px;
  margin-bottom: 20px; }

h4 {
  font-size: 13px;
  line-height: 15px;
  color: #4a4a4a;
  font-family: "latoregular",sans-serif;
  letter-spacing: 0.3px; }

h5 {
  font-size: 12px;
  line-height: 14px;
  color: #4a4a4a;
  font-family: "latoregular",sans-serif;
  font-weight: bold;
  letter-spacing: 0.3px; }

h6 {
  font-size: 10px;
  line-height: 12px;
  color: #4a4a4a;
  font-family: "latoregular",sans-serif;
  letter-spacing: 0.3px; }

p,
li {
  font-size: 16px;
  line-height: 20px;
  text-transform: none; }

a {
  font-size: 18px;
  line-height: 26px;
  color: #0093d0;
  font-weight: 300;
  text-decoration: none; }

/*COMMON CLASSES*/
.floatLeft {
  float: left !important; }

.floatRight {
  float: right !important; }

.floatNone {
  float: none !important; }

.txtCenter {
  text-align: center !important; }

.txtLeft {
  text-align: left; }

.txtRight {
  text-align: right; }

.textJustify {
  text-align: justify; }

.textNowrap {
  white-space: nowrap !important; }

.lowercase {
  text-transform: lowercase !important; }

.uppercase {
  text-transform: uppercase !important; }

.capitalize {
  text-transform: capitalize !important; }

.transformNone {
  text-transform: none !important; }

.txtRed {
  color: #FF4C4C !important; }

.txtBlue {
  color: #0093d0 !important; }

.txtGreen {
  color: #01E98E !important; }

.txtBlack {
  color: #000 !important; }

.txtGray7E {
  color: #7e7e7e !important; }

.txtOrange {
  color: #fe6a2c !important; }

.lh16 {
  line-height: 16px !important; }

.relative {
  position: relative; }

.height-Auto {
  height: auto !important;
  min-height: 100%; }

.minH-auto {
  min-height: auto !important; }

.ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.breakAll {
  word-break: break-all; }

.noBreak {
  word-break: normal !important; }

.noScroll {
  overflow: hidden !important; }

.no_bg {
  background: none !important; }

.noBorder {
  border: none !important; }

.borderLeft {
  border-left: 1px solid #4a4a4a; }

.borderTop {
  border-top: 1px solid #4a4a4a; }

.borderRight {
  border-right: 1px solid #4a4a4a; }

.borderBottom {
  border-bottom: 1px solid #4a4a4a; }

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.paddingLR0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pt20 {
  padding-top: 20px !important; }

.pb4 {
  padding-bottom: 4px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl10 {
  padding-left: 10px !important; }

.pl20 {
  padding-left: 20px !important; }

.pl15 {
  padding-left: 15px !important; }

.pl25 {
  padding-left: 25px !important; }

.pr10 {
  padding-right: 10px !important; }

.pr20 {
  padding-right: 20px !important; }

.pr0 {
  padding-right: 0px !important; }

.margin0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mb16 {
  margin-bottom: 16px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mr5 {
  margin-right: 5px !important; }

.mr10 {
  margin-right: 10px !important; }

.mr20 {
  margin-right: 20px !important; }

.mr30 {
  margin-right: 30px !important; }

.ml5 {
  margin-left: 5px !important; }

.ml10 {
  margin-left: 10px !important; }

.ml20 {
  margin-left: 20px !important; }

.ml30 {
  margin-left: 30px !important; }

.ml-10 {
  margin-left: -10px !important; }

.middleIcon {
  display: inline-block;
  width: 24px;
  height: 22px;
  margin: 0 10px;
  vertical-align: middle; }

.leftIcon {
  display: inline-block;
  width: 24px;
  height: 22px;
  margin: 0 10px 0 0;
  vertical-align: middle; }

.valign svg {
  vertical-align: middle !important; }

.commonList {
  list-style: disc;
  margin-left: 30px; }

.mand {
  color: #FF4C4C; }

.font13 {
  font-size: 13px !important; }

.font11 {
  font-size: 11px !important; }

.font18 {
  font-size: 18px !important; }

.message {
  display: block;
  background: #01E98E;
  font-size: 15px;
  color: #01E98E;
  text-align: center;
  padding: 10px;
  border: 1px solid #01E98E; }

.commonBg {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999; }

.inlineB {
  display: inline-block !important; }

.inline {
  display: inline !important; }

.lineH30 {
  line-height: 30px !important; }

.pointer {
  cursor: pointer !important; }

.shortdes p {
  color: #000;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  font-family: "latoregular", sans-serif; }

.shortdes a {
  font-size: 12px; }

.custom-label {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.3px; }

.orange {
  color: #ff6c2f; }

.grey {
  color: #9b9b9b; }

.dgrey {
  color: #939393; }

.blue {
  color: #0093d0 !important; }

.white {
  color: #fff; }
  .white:before {
    color: #fff !important; }

.iconbgcolor {
  color: #bebebe; }

.tooltipWrap {
  position: relative; }
  .tooltipWrap .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 100px;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 8px 3px;
    z-index: 1;
    opacity: 0;
    bottom: -35px;
    left: 50%;
    margin-left: -50px;
    font-size: 12px;
    line-height: 12px;
    font-family: "latoregular", sans-serif;
    border-radius: 2px;
    transition: opacity .6s;
    letter-spacing: 0.2px; }
    .tooltipWrap .tooltiptext:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #000 transparent; }
    .tooltipWrap .tooltiptext.tooltip-top {
      bottom: 100%;
      left: 50%; }
      .tooltipWrap .tooltiptext.tooltip-top:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #000 transparent transparent transparent; }
  .tooltipWrap .topS {
    width: 420px !important;
    bottom: 20px;
    left: -110px; }
    .tooltipWrap .topS:after {
      top: 40px;
      border-color: #000 transparent transparent transparent; }
  .tooltipWrap .topN {
    width: 530px;
    bottom: 20px;
    left: -150px; }
    .tooltipWrap .topN:after {
      top: 40px;
      border-color: #000 transparent transparent transparent; }
  .tooltipWrap .topB {
    width: 620px !important;
    bottom: 20px;
    left: -240px; }
    .tooltipWrap .topB:after {
      top: 40px;
      border-color: #000 transparent transparent transparent; }
  .tooltipWrap:hover .tooltiptext {
    visibility: visible;
    opacity: 1; }

i:before {
  font-size: 24px; }

.userStatus {
  display: inline-block;
  padding: 0 13px; }
  .userStatus .activeUser {
    width: 8px;
    height: 8px;
    background: #0093d0;
    text-indent: -9999px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%; }
    .userStatus .activeUser.inactiveUser {
      background: #ff6c2f; }
  .userStatus .tooltiptext {
    width: 80px;
    margin-left: -40px; }

.helpTxt {
  color: #9b9b9b !important;
  font-size: 12px !important;
  line-height: 14px !important;
  font-family: "latoregular", sans-serif !important;
  letter-spacing: 0.4px !important;
  margin-top: 0px !important; }
  .helpTxt.active {
    color: #0093d0; }

.filterBar {
  position: relative;
  margin-bottom: 5px; }
  .filterBar ul li {
    display: inline-block; }
  .filterBar button {
    width: 30px;
    height: 30px; }
    .filterBar button i:before {
      color: #9b9b9b; }
    .filterBar button.active svg {
      color: #0093d0; }
    .filterBar button.active i:before {
      color: #0093d0; }
  .filterBar .contentDropdown {
    margin-left: -31px;
    max-width: 270px; }

.custom-scroll {
  min-height: 0;
  min-width: 0; }
  .custom-scroll .outer-container {
    overflow: hidden; }
    .custom-scroll .outer-container .positioning {
      position: relative; }
    .custom-scroll .outer-container:hover .custom-scrollbar {
      opacity: 1;
      transition-duration: 0.2s; }
  .custom-scroll .inner-container {
    overflow-x: hidden;
    overflow-y: scroll; }
    .custom-scroll .inner-container:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, transparent 100%);
      pointer-events: none;
      transition: height 0.1s ease-in;
      will-change: height; }
    .custom-scroll .inner-container.content-scrolled:after {
      height: 5px;
      transition: height 0.15s ease-out; }
  .custom-scroll.scroll-handle-dragged .inner-container {
    user-select: none; }
  .custom-scroll.scroll-handle-dragged .custom-scrollbar {
    opacity: 1; }
  .custom-scroll .custom-scrollbar {
    position: absolute;
    height: 100%;
    width: 6px;
    right: 3px;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s ease-out;
    padding: 6px 0;
    box-sizing: border-box;
    will-change: opacity;
    pointer-events: none; }
    .custom-scroll .custom-scrollbar.custom-scrollbar-rtl {
      right: auto;
      left: 3px; }
  .custom-scroll .custom-scroll-handle {
    position: absolute;
    width: 100%;
    top: 0; }
  .custom-scroll .inner-handle {
    height: calc(100% - 12px);
    margin-top: 6px;
    background-color: #c9c9c9;
    border-radius: 3px; }

.displayNone {
  display: none; }

.displayBlock {
  display: block; }

.section-loader {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: calc(100vh);
  display: block;
  text-align: center;
  z-index: 999; }

.section-loader > div {
  position: relative;
  top: 50%; }

.userList {
  position: relative; }

/* error toast */
.Toastify {
  color: #fff; }
  .Toastify .Toastify__toast--error {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #ff6868 !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .Toastify .Toastify__toast--error > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--error > div h1 {
        color: #fff;
        font-family: 'latobold';
        font-size: 16px;
        font-weight: normal !important;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--error > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--error > div h1 span {
          font-size: 12px;
          font-family: 'latoregular';
          color: #ffdccd;
          padding-left: 10px; }
      .Toastify .Toastify__toast--error > div p {
        color: #fff;
        font-family: 'latoregular';
        font-size: 12px;
        font-weight: normal;
        padding-left: 23px; }
  .Toastify .Toastify__toast--info {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #417505 !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .Toastify .Toastify__toast--info > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--info > div h1 {
        color: #fff;
        font-family: 'latobold';
        font-size: 16px;
        font-weight: normal !important;
        margin: 0 0 1px 0px; }
        .Toastify .Toastify__toast--info > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--info > div h1 span {
          font-size: 12px;
          font-family: 'latoregular';
          color: #fff;
          padding-left: 10px; }
      .Toastify .Toastify__toast--info > div p {
        color: #fff;
        font-family: 'latoregular';
        font-size: 12px;
        font-weight: normal;
        padding-left: 23px; }
  .Toastify .Toastify__toast--success {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #0093d0 !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .Toastify .Toastify__toast--success > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--success > div h1 {
        color: #fff;
        font-family: 'latobold';
        font-size: 16px;
        font-weight: normal !important;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--success > div h1 i {
          color: red; }
          .Toastify .Toastify__toast--success > div h1 i:before {
            font-size: 14px;
            margin-right: 5px; }
        .Toastify .Toastify__toast--success > div h1 span {
          font-size: 12px;
          font-family: 'latoregular';
          color: #ffdccd;
          padding-left: 10px; }
      .Toastify .Toastify__toast--success > div p {
        color: #fff;
        font-family: 'latoregular';
        font-size: 12px;
        font-weight: normal;
        padding-left: 23px; }
  .Toastify .Toastify__toast--warning {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #f8e71c !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .Toastify .Toastify__toast--warning > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--warning > div h1 {
        color: #000;
        font-family: 'latobold';
        font-size: 16px;
        font-weight: normal !important;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--warning > div h1 i {
          color: #333; }
          .Toastify .Toastify__toast--warning > div h1 i:before {
            font-size: 14px;
            margin-right: 5px;
            color: #333 !important; }
        .Toastify .Toastify__toast--warning > div h1 span {
          font-size: 12px;
          font-family: 'latoregular';
          color: #333;
          padding-left: 10px; }
      .Toastify .Toastify__toast--warning > div p {
        color: #000;
        font-family: 'latoregular';
        font-size: 12px;
        font-weight: normal;
        padding-left: 23px; }
  .Toastify .Toastify__toast--neutral {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #7e7e7e !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .Toastify .Toastify__toast--neutral > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--neutral > div h1 {
        color: #fff;
        font-family: 'latobold';
        font-size: 16px;
        font-weight: normal !important;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--neutral > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--neutral > div h1 span {
          font-size: 12px;
          font-family: 'latoregular';
          color: #ffdccd;
          padding-left: 10px; }
      .Toastify .Toastify__toast--neutral > div p {
        color: #fff;
        font-family: 'latoregular';
        font-size: 12px;
        font-weight: normal;
        padding-left: 23px; }

.Toastify__close-button {
  font-weight: normal; }

.Toastify__toast-container {
  z-index: 100000 !important; }

.error {
  color: #ff6868 !important; }

.link1 {
  font-size: 14px;
  font-family: 'latobold';
  vertical-align: top; }
  .link1:hover {
    text-decoration: underline; }
  .link1 svg {
    margin-right: 4px;
    vertical-align: middle;
    margin-top: -5px; }

.iSize16 {
  vertical-align: middle;
  margin-right: 5px; }
  .iSize16:before {
    font-size: 16px; }

.pointerEv {
  cursor: pointer; }

.loading {
  float: left;
  margin-top: 3px; }

input:-webkit-autofill {
  -webkit-box-shadow: 0px 0 0 30px white inset; }

.errorIcon {
  color: #ff6868; }

.lightGrayIcon {
  color: #9b9b9b;
  border: 1px solid transparent !important; }
  .lightGrayIcon:before {
    font-size: 17px !important; }
  .lightGrayIcon:hover {
    background: transparent !important; }
    .lightGrayIcon:hover:before {
      color: #9b9b9b !important; }

.borderBottomGray {
  border-bottom: 1px solid #ddd; }

#getTopOffset {
  float: left;
  width: 100%;
  height: 1px;
  line-height: 1px; }

.overlayHoldar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: .001;
  width: calc(100% - 22px);
  height: 100%;
  z-index: 1; }

embed {
  width: 100%;
  position: relative;
  display: block; }

.data-table tbody tr td a.hlink {
  color: #4a4a4a;
  font-size: 12px; }

.data-table tbody tr td a.hlink:hover {
  color: #0093d0;
  font-size: 12px; }

.breakWord {
  word-break: break-word !important; }

.multiline {
  white-space: pre-wrap; }

.right40 {
  right: 40px !important; }

.right20 {
  right: 20px !important; }

.txtColor4a {
  color: #4a4a4a !important; }

.fSize12 {
  font-size: 12px !important; }

.pointerD {
  cursor: default !important; }

.tableCell {
  display: table-cell !important;
  vertical-align: middle !important; }

.zIndex10 {
  z-index: 10 !important; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.purgeBtn {
  background: #0093d0 !important; }
  .purgeBtn .purgeBtn-text {
    color: white;
    font-weight: bold; }
  .purgeBtn:hover {
    background: #06AAE5 !important; }

.btn {
  position: relative;
  display: inline-block;
  padding: 8px 57px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  font-family: "latobold";
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-box-sizing: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  text-decoration: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-delay: 0.2s; }
  .btn a {
    font-size: 14px;
    color: #fff; }
  .btn:hover, .btn:active {
    transition-delay: 0s; }

.btnWrapper {
  margin-top: 20px;
  width: 100%;
  float: left;
  clear: both; }

.btnWrap {
  margin-top: 16px;
  margin-bottom: 8px; }

.btnPrimary, .btnCancel, .btnInactive, .btnGray {
  background: #0093d0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) !important;
  padding: 10px 57px !important;
  font-size: 15px !important;
  line-height: 15px !important;
  font-family: "latoregular", sans-serif !important;
  font-weight: bold !important;
  color: #fff;
  text-transform: uppercase !important;
  border-radius: 3px !important;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer; }
  .btnPrimary span, .btnCancel span, .btnInactive span, .btnGray span {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
    text-transform: uppercase;
    font-weight: bold !important; }
  .btnPrimary:hover, .btnCancel:hover, .btnInactive:hover, .btnGray:hover {
    background: #06AAE5 !important; }
  .btnPrimary:active, .btnCancel:active, .btnInactive:active, .btnGray:active {
    background: #06AAE5 !important; }
  @media screen and (max-width: 992px) {
    .btnPrimary, .btnCancel, .btnInactive, .btnGray {
      padding: 10px 27px !important;
      min-width: auto !important; } }
  .btnPrimary i:before, .btnCancel i:before, .btnInactive i:before, .btnGray i:before {
    font-size: 16px;
    margin-right: 4px;
    line-height: 16px; }

a.btnPrimary:hover, a.btnCancel:hover, a.btnInactive:hover, a.btnGray:hover {
  color: #fff; }

.btnCancel {
  background: #fff !important;
  color: #000; }
  .btnCancel span {
    color: #000; }
  .btnCancel:hover {
    background: #eae4e4 !important; }

.btnInactive {
  background: #9b9b9b !important;
  cursor: default !important; }
  .btnInactive span {
    color: #fff; }
  .btnInactive:hover {
    background: #9b9b9b !important; }

.btnGray {
  background: #9b9b9b !important;
  cursor: default !important; }
  .btnGray span {
    color: #fff; }
  .btnGray:hover {
    background: #9b9b9b !important; }

.fsize14 {
  font-size: 14px !important; }

.fsize12 {
  font-size: 12px !important; }

label {
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #0093d0;
  margin-bottom: 10px; }

input,
select,
textarea,
.ui.dropdown {
  display: block;
  width: 100%;
  color: #000 !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 400;
  border: 0px;
  border-bottom: 1px solid #9b9b9b !important;
  padding: 5px 0px 8px 0 !important;
  font-family: "latoregular", sans-serif !important; }

textarea {
  width: 100%;
  height: 70px;
  max-height: 70px;
  max-width: 100%;
  resize: none;
  padding: 10px; }

label + input,
label + select,
label + textarea {
  margin-top: 0; }

input.valid,
select.valid,
textarea.valid {
  border: 1px solid #01E98E; }

input.invalid,
select.invalid,
textarea.invalid {
  background: rgba(255, 76, 76, 0.25);
  border: 1px solid #ff6868; }

.invalidTxt {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  color: #ff6868; }

input + .invalidTxt,
select + .invalidTxt,
textarea + .invalidTxt {
  margin-top: -22px; }

::-webkit-input-placeholder {
  color: #4a4a4a; }

:-moz-placeholder {
  color: #4a4a4a;
  opacity: 1; }

::-moz-placeholder {
  color: #4a4a4a;
  opacity: 1; }

:-ms-input-placeholder {
  color: #4a4a4a;
  opacity: 1; }

::placeholder {
  color: #4a4a4a;
  opacity: 1; }

.common-form {
  width: 100%;
  display: block; }
  .common-form .row {
    width: 100%;
    display: block;
    margin-bottom: 20px; }

.form-group {
  width: 100%;
  float: left;
  margin: 0; }

.form-inputField, .form-inputFieldrdonly, .form-inputPlaceholder, .form-inputFieldError, .form-inputFieldImp {
  width: 100% !important;
  margin-bottom: 7px !important;
  margin-top: 10px !important; }
  .form-inputField label, .form-inputFieldrdonly label, .form-inputPlaceholder label, .form-inputFieldError label, .form-inputFieldImp label {
    font-size: 14px;
    line-height: 16px;
    font-family: "latoregular", sans-serif !important;
    color: #4a4a4a !important; }
    .form-inputField label[data-shrink="true"], .form-inputFieldrdonly label[data-shrink="true"], .form-inputPlaceholder label[data-shrink="true"], .form-inputFieldError label[data-shrink="true"], .form-inputFieldImp label[data-shrink="true"] {
      transform: scale(0.85); }
  .form-inputField input, .form-inputFieldrdonly input, .form-inputPlaceholder input, .form-inputFieldError input, .form-inputFieldImp input {
    border-bottom: 0px !important; }
  .form-inputField [class*="MuiFormLabel-focused-"], .form-inputFieldrdonly [class*="MuiFormLabel-focused-"], .form-inputPlaceholder [class*="MuiFormLabel-focused-"], .form-inputFieldError [class*="MuiFormLabel-focused-"], .form-inputFieldImp [class*="MuiFormLabel-focused-"] {
    color: #0093d0 !important;
    font-family: "latoregular", sans-serif !important; }
    .form-inputField [class*="MuiFormLabel-focused-"]:before, .form-inputFieldrdonly [class*="MuiFormLabel-focused-"]:before, .form-inputPlaceholder [class*="MuiFormLabel-focused-"]:before, .form-inputFieldError [class*="MuiFormLabel-focused-"]:before, .form-inputFieldImp [class*="MuiFormLabel-focused-"]:before {
      border-bottom: 10px solid #0093d0; }
  .form-inputField [class*="MuiInputLabel-shrink-"], .form-inputFieldrdonly [class*="MuiInputLabel-shrink-"], .form-inputPlaceholder [class*="MuiInputLabel-shrink-"], .form-inputFieldError [class*="MuiInputLabel-shrink-"], .form-inputFieldImp [class*="MuiInputLabel-shrink-"] {
    font-family: "latoregular", sans-serif !important; }
  .form-inputField [class*="MuiInput-formControl-"]:before, .form-inputFieldrdonly [class*="MuiInput-formControl-"]:before, .form-inputPlaceholder [class*="MuiInput-formControl-"]:before, .form-inputFieldError [class*="MuiInput-formControl-"]:before, .form-inputFieldImp [class*="MuiInput-formControl-"]:before {
    border-bottom: 1px solid #9b9b9b; }
  .form-inputField [class*="MuiInput-formControl-"]:after, .form-inputFieldrdonly [class*="MuiInput-formControl-"]:after, .form-inputPlaceholder [class*="MuiInput-formControl-"]:after, .form-inputFieldError [class*="MuiInput-formControl-"]:after, .form-inputFieldImp [class*="MuiInput-formControl-"]:after {
    border-bottom: 1px solid #0093d0; }
  .form-inputField div:hover::before, .form-inputFieldrdonly div:hover::before, .form-inputPlaceholder div:hover::before, .form-inputFieldError div:hover::before, .form-inputFieldImp div:hover::before {
    border-bottom: 1px solid #0093d0 !important; }

.form-inputFieldrdonly [class*="MuiInput-formControl-"]:before {
  border-bottom: 0px !important; }

.form-inputFieldrdonly div:before {
  border-bottom: 0px !important; }

.form-inputFieldrdonly div:before {
  border-bottom: 0px !important; }

.form-inputFieldrdonly div:after {
  border-bottom: 0px !important; }

.form-inputFieldrdonly div:hover::before {
  border-bottom: 0px !important; }

.form-inputPlaceholder input {
  border: 0px !important;
  font-size: 14px !important;
  font-weight: bold; }

.form-inputPlaceholder ::-webkit-input-placeholder {
  color: #9b9b9b;
  font-style: italic;
  font-weight: normal; }

.form-inputPlaceholder :-moz-placeholder {
  color: #9b9b9b;
  opacity: 1;
  font-style: italic;
  font-weight: normal; }

.form-inputPlaceholder ::-moz-placeholder {
  color: #9b9b9b;
  opacity: 1;
  font-style: italic;
  font-weight: normal; }

.form-inputPlaceholder :-ms-input-placeholder {
  color: #9b9b9b;
  opacity: 1;
  font-style: italic;
  font-weight: normal; }

.form-inputPlaceholder ::placeholder {
  color: #9b9b9b;
  opacity: 1;
  font-style: italic;
  font-weight: normal; }

.form-inputPlaceholder:before {
  display: none; }

.form-inputPlaceholder:after {
  display: none; }

.checkBoxWrap [class*="MuiTypography-body1-"] {
  color: #000;
  line-height: 14px;
  font-family: "latoregular", sans-serif !important;
  letter-spacing: 0.3px !important; }

.dropDownr {
  z-index: 9;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16); }

#menu-list-grow {
  box-shadow: 24px 18px 27px 0 rgba(0, 16, 34, 0.05) !important;
  padding: 23px 0 30px !important;
  border-radius: 0px !important;
  min-width: 220px; }
  #menu-list-grow h1 {
    margin: 0 30px 20px 30px;
    padding: 0;
    font-family: 'latobold';
    font-weight: normal; }
  #menu-list-grow ul {
    padding: 0px !important; }
    #menu-list-grow ul li {
      padding: 0 30px !important;
      margin-bottom: 20px !important;
      font-size: 14px !important;
      line-height: 16px !important;
      font-family: "latobold", sans-serif !important;
      font-weight: bold !important;
      height: auto;
      color: #0093d0; }
      #menu-list-grow ul li:hover {
        background: none !important;
        color: #06AAE5; }
      #menu-list-grow ul li:last-child {
        margin-bottom: 0px !important; }
      #menu-list-grow ul li i {
        margin-right: 14px;
        vertical-align: middle; }
        #menu-list-grow ul li i:before {
          font-size: 21px; }
      #menu-list-grow ul li a {
        font-family: 'latobold';
        font-weight: normal;
        font-size: 14px !important;
        line-height: 24px; }
      #menu-list-grow ul li.seperator {
        border-top: 1px solid #d8d8d8;
        padding-top: 26px !important;
        margin-top: 26px !important; }

.contentDropdown {
  width: 100%;
  margin-top: 5px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16); }
  .contentDropdown #menu-list-grow {
    padding: 22px 0 !important;
    width: 100%;
    float: left; }
    .contentDropdown #menu-list-grow .rowWrap {
      padding: 0 29px 6px !important; }
      .contentDropdown #menu-list-grow .rowWrap h5 {
        margin-bottom: 3px; }
      .contentDropdown #menu-list-grow .rowWrap .radioGroup label {
        margin-right: 31px; }
  .contentDropdown.addtionalSecure {
    max-width: 433px;
    margin-left: -15px; }
    .contentDropdown.addtionalSecure #menu-list-grow {
      box-shadow: 24px 18px 27px 0 rgba(0, 16, 34, 0.14) !important; }
      .contentDropdown.addtionalSecure #menu-list-grow .rowWrap {
        padding: 0 19px 24px !important;
        width: 100%;
        float: left; }
        .contentDropdown.addtionalSecure #menu-list-grow .rowWrap h5 {
          margin-bottom: 5px;
          color: #000; }
        .contentDropdown.addtionalSecure #menu-list-grow .rowWrap label {
          margin-bottom: 0px; }
        .contentDropdown.addtionalSecure #menu-list-grow .rowWrap [class*="MuiSwitchBase-root-"] {
          height: 21px; }
      .contentDropdown.addtionalSecure #menu-list-grow .col-grid4 .col {
        width: 25%; }
        .contentDropdown.addtionalSecure #menu-list-grow .col-grid4 .col:last-child {
          /* width:19%;*/ }

.dropMenunotOpen {
  z-index: -9 !important;
  max-height: 0px;
  overflow: hidden; }

.formSelectBox, .formSelectBoxRdonly, .formSelectBoxImp {
  width: 100%; }
  .formSelectBox input, .formSelectBoxRdonly input, .formSelectBoxImp input {
    border-bottom: 0px !important; }
  .formSelectBox label, .formSelectBoxRdonly label, .formSelectBoxImp label {
    font-size: 14px;
    line-height: 16px;
    font-family: "latoregular", sans-serif !important;
    color: #4a4a4a;
    display: block; }
    .formSelectBox label[data-shrink="true"], .formSelectBoxRdonly label[data-shrink="true"], .formSelectBoxImp label[data-shrink="true"] {
      transform: scale(0.85); }
  .formSelectBox [class*="MuiChip-root-"], .formSelectBoxRdonly [class*="MuiChip-root-"], .formSelectBoxImp [class*="MuiChip-root-"] {
    margin: 0 4px;
    height: 29px; }
    .formSelectBox [class*="MuiChip-root-"] span, .formSelectBoxRdonly [class*="MuiChip-root-"] span, .formSelectBoxImp [class*="MuiChip-root-"] span {
      font-size: 14px;
      font-family: "latoregular", sans-serif !important;
      color: #4a4a4a; }
    .formSelectBox [class*="MuiChip-root-"]:first-child, .formSelectBoxRdonly [class*="MuiChip-root-"]:first-child, .formSelectBoxImp [class*="MuiChip-root-"]:first-child {
      margin-left: 0px; }
  .formSelectBox [class*="MuiFormLabel-focused-"], .formSelectBoxRdonly [class*="MuiFormLabel-focused-"], .formSelectBoxImp [class*="MuiFormLabel-focused-"] {
    color: #4a4a4a !important; }
    .formSelectBox [class*="MuiFormLabel-focused-"]:before, .formSelectBoxRdonly [class*="MuiFormLabel-focused-"]:before, .formSelectBoxImp [class*="MuiFormLabel-focused-"]:before {
      border-bottom: 1px solid #0093d0 !important; }
  .formSelectBox [class*="MuiSelect-select-"], .formSelectBoxRdonly [class*="MuiSelect-select-"], .formSelectBoxImp [class*="MuiSelect-select-"] {
    background: none !important;
    border-radius: 0px;
    border: 0px !important;
    padding-right: 32px !important; }
  .formSelectBox [class*="MuiFormLabel-focused-"], .formSelectBoxRdonly [class*="MuiFormLabel-focused-"], .formSelectBoxImp [class*="MuiFormLabel-focused-"] {
    color: #0093d0 !important;
    font-family: "latoregular", sans-serif !important; }
    .formSelectBox [class*="MuiFormLabel-focused-"]:before, .formSelectBoxRdonly [class*="MuiFormLabel-focused-"]:before, .formSelectBoxImp [class*="MuiFormLabel-focused-"]:before {
      border-bottom: 1px solid #0093d0; }
  .formSelectBox [class*="MuiInputLabel-shrink-"], .formSelectBoxRdonly [class*="MuiInputLabel-shrink-"], .formSelectBoxImp [class*="MuiInputLabel-shrink-"] {
    font-family: "latoregular", sans-serif !important; }
  .formSelectBox [class*="MuiInput-formControl-"]:before, .formSelectBoxRdonly [class*="MuiInput-formControl-"]:before, .formSelectBoxImp [class*="MuiInput-formControl-"]:before {
    border-bottom: 1px solid #9b9b9b; }
  .formSelectBox [class*="MuiInput-formControl-"]:after, .formSelectBoxRdonly [class*="MuiInput-formControl-"]:after, .formSelectBoxImp [class*="MuiInput-formControl-"]:after {
    border-bottom: 1px solid #0093d0; }
  .formSelectBox div:hover::before, .formSelectBoxRdonly div:hover::before, .formSelectBoxImp div:hover::before {
    border-bottom: 1px solid #0093d0 !important; }
  .formSelectBox [class*="MuiSelect-root-"], .formSelectBoxRdonly [class*="MuiSelect-root-"], .formSelectBoxImp [class*="MuiSelect-root-"] {
    overflow: hidden; }
  .formSelectBox [class*="MuiSelect-icon-"], .formSelectBoxRdonly [class*="MuiSelect-icon-"], .formSelectBoxImp [class*="MuiSelect-icon-"] {
    color: #4a4a4a;
    right: -6px; }

.formSelectBoxRdonly [class*="MuiInput-formControl-"]:before {
  border-bottom: 0px !important; }

.formSelectBoxRdonly [class*="MuiSelect-select-"] {
  cursor: default; }

.formSelectBoxRdonly [class*="MuiFormLabel-focused-"]:before {
  border-bottom: 0px !important; }

.formSelectBoxRdonly [class*="MuiFormLabel-focused-"] label {
  color: #9b9b9b; }

.formSelectBoxRdonly [class*="MuiFormLabel-focused-"] {
  color: #4a4a4a !important; }

.formSelectBoxRdonly [class*="MuiInput-formControl-"]:before {
  border-bottom: 0px !important; }

.formSelectBoxRdonly [class*="MuiInput-formControl-"]:after {
  border-bottom: 0px !important; }

.formSelectBoxRdonly div:hover::before {
  border-bottom: 0px !important; }

.formSelectBoxRdonly svg {
  display: none; }

.formSelectBoxRdonly [class*="MuiChip-root-"] {
  background: none;
  width: auto;
  padding: 0px;
  margin: 0 4px; }
  .formSelectBoxRdonly [class*="MuiChip-root-"]:first-child {
    margin-left: 0px !important; }
  .formSelectBoxRdonly [class*="MuiChip-root-"] span {
    font-size: 16px;
    color: #000; }

.radioGroupWrap {
  display: block !important; }
  .radioGroupWrap h5 {
    font-size: 12px;
    font-weight: normal;
    margin-top: 0px; }
  .radioGroupWrap .radioGroup {
    display: inline-block !important; }
    .radioGroupWrap .radioGroup h5 {
      margin-bottom: 3px; }
    .radioGroupWrap .radioGroup label {
      margin-right: 26px;
      margin-bottom: -6px; }
      .radioGroupWrap .radioGroup label span {
        font-size: 12px;
        line-height: 14px;
        font-family: "latoregular", sans-serif;
        letter-spacing: 0.3px !important; }

.radioGroup [class*="MuiTypography-body1-"] {
  color: #9b9b9b;
  font-size: 12px;
  line-height: 14px;
  font-family: "latoregular", sans-serif !important;
  letter-spacing: 0.3px !important; }

.radioGroup label span + span {
  color: #9b9b9b; }

.radioViewlabel {
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-top: 4px; }

.dropDownbtn {
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  padding: 10px 26px 0;
  clear: both; }
  .dropDownbtn button {
    padding: 10px 35px !important;
    margin: 0 10px; }
    .dropDownbtn button:last-child {
      margin-right: 0px; }
    .dropDownbtn button.btnCancel {
      box-shadow: none !important; }

[class*="MuiModal-root-"] [class*="MuiPaper-root-"] ul {
  padding-bottom: 0; }
  [class*="MuiModal-root-"] [class*="MuiPaper-root-"] ul li {
    background: none;
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: "latoregular", sans-serif !important;
    font-weight: bold !important;
    padding: 4px 20px 8px !important;
    margin-bottom: 0px !important; }
    [class*="MuiModal-root-"] [class*="MuiPaper-root-"] ul li:hover {
      color: #0093d0; }
    [class*="MuiModal-root-"] [class*="MuiPaper-root-"] ul li[class*="MuiMenuItem-selected-"] {
      color: #0093d0; }

ul[role="listbox"] {
  max-height: 264px; }
  ul[role="listbox"] li {
    background: none;
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: "latoregular", sans-serif !important;
    font-weight: bold !important;
    padding: 4px 20px 8px !important;
    margin-bottom: 0px !important;
    background: #fff !important;
    white-space: normal;
    height: auto; }
    ul[role="listbox"] li:hover {
      color: #0093d0;
      background: #fff; }
    ul[role="listbox"] li[class*="MuiMenuItem-selected-"] {
      color: #0093d0; }
    ul[role="listbox"] li em {
      font-style: normal; }

.formSelectBoxImp.formControl div > div > div[aria-pressed] {
  padding-left: 12px; }
  .formSelectBoxImp.formControl div > div > div[aria-pressed]:focus {
    background: #fff !important; }
  .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] {
    margin-right: 4px;
    background: #e6e6e6 !important;
    height: 20px !important;
    font-size: 14px;
    font-family: "latoregular", sans-serif !important;
    border-bottom: 1px solid transparent; }
    .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] span {
      font-size: 14px; }

.formSelectBoxImp.formControl label + div:after {
  border-bottom: 1px solid #0093d0; }

.formSelectBoxRdonly.formControl div > div > div[aria-pressed] {
  padding-left: 0px; }
  .formSelectBoxRdonly.formControl div > div > div[aria-pressed]:focus {
    background: #fff !important; }
  .formSelectBoxRdonly.formControl div > div > div[aria-pressed] > div > div[role="button"] {
    margin-right: 4px;
    height: auto !important;
    font-size: 14px;
    font-family: "latoregular", sans-serif !important; }
    .formSelectBoxRdonly.formControl div > div > div[aria-pressed] > div > div[role="button"] span {
      font-size: 14px; }

.formSelectBoxRdonly.formControl label + div:before {
  border-bottom: 0px solid #0093d0; }

.formSelectBoxRdonly.formControl label + div:after {
  border-bottom: 0px solid #0093d0; }

.form-inputFieldError {
  width: 100%;
  margin-bottom: 5px !important; }
  .form-inputFieldError label {
    color: #ff6868; }
  .form-inputFieldError [class*="MuiFormLabel-focused-"] {
    color: #ff6868 !important; }
    .form-inputFieldError [class*="MuiFormLabel-focused-"]:before {
      border-bottom: 1px solid #ff6868; }
  .form-inputFieldError div:before {
    border-bottom: 1px solid #ff6868 !important; }
  .form-inputFieldError div:after {
    border-bottom: 1px solid #ff6868 !important; }
  .form-inputFieldError div:hover::before {
    border-bottom: 1px solid #ff6868 !important; }

.form-inputFieldImp label {
  padding-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.form-inputFieldImp div:before {
  content: "";
  border-left: 2px solid #ff6868;
  height: 30px; }

.form-inputFieldImp input {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.form-inputFieldImp [class*="MuiInputLabel-shrink-"] {
  padding-left: 0px; }

.btnInactive span {
  color: white !important; }

.form-inputFieldrdonly div:before {
  border-bottom: 0px !important; }

.formSelectBoxImp label {
  padding-left: 12px; }

.formSelectBoxImp div:nth-child(2):before {
  content: "";
  height: 30px; }

.formSelectBoxImp div:nth-child(2) div:before {
  border-left: 0px; }

.formSelectBoxImp [class*="MuiSelect-select-"] {
  padding-left: 12px !important; }

.formSelectBoxImp select {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.formSelectBoxImp [class*="MuiInputLabel-shrink-"] {
  padding-left: 0px; }

.form-inputField label[data-shrink="true"], .form-inputFieldrdonly label[data-shrink="true"], .form-inputPlaceholder label[data-shrink="true"], .form-inputFieldError label[data-shrink="true"], .form-inputFieldImp label[data-shrink="true"] {
  color: #4a4a4a !important;
  padding-left: 0; }

.formSelectBox div, .formSelectBoxRdonly div, .formSelectBoxImp div,
.formSelectBoxImp div,
.formSelectBoxRdonly div {
  margin-bottom: 0;
  vertical-align: bottom; }

.formSelectBoxImp label[data-shrink=true] {
  color: #4a4a4a !important;
  padding-left: 0; }

.formSelectBox div[role=button], .formSelectBoxRdonly div[role=button], .formSelectBoxImp div[role=button] {
  margin-left: 10px; }

.form-inputField textarea, .form-inputFieldrdonly textarea, .form-inputPlaceholder textarea, .form-inputFieldError textarea, .form-inputFieldImp textarea {
  border: 0 !important; }
  .form-inputField textarea:after, .form-inputFieldrdonly textarea:after, .form-inputPlaceholder textarea:after, .form-inputFieldError textarea:after, .form-inputFieldImp textarea:after {
    border-bottom: 0; }

.formSelectBox div[role=button], .formSelectBoxRdonly div[role=button], .formSelectBoxImp div[role=button] {
  margin-left: 0px; }

.datePicker {
  position: relative; }
  .datePicker .calendar-icon {
    position: absolute;
    top: 19px;
    right: 32px;
    pointer-events: none; }
    .datePicker .calendar-icon.dateTime {
      right: 3px; }
    .datePicker .calendar-icon.shipmentDateTime {
      right: 9px; }
    .datePicker .calendar-icon button {
      border: 0;
      background: #fff; }
      .datePicker .calendar-icon button i:before {
        color: #4a4a4a; }
    .datePicker .calendar-icon.right0 {
      right: 0px; }
    .datePicker .calendar-icon.top0 {
      top: 0px; }
    .datePicker .calendar-icon.right40 {
      right: 40px; }
    .datePicker .calendar-icon.right10 {
      right: 10px; }

.col-grid3 .datePicker .calendar-icon {
  right: 52px; }
  .col-grid3 .datePicker .calendar-icon.col3Full {
    right: 40px; }
  .col-grid3 .datePicker .calendar-icon.right12 {
    right: 12px !important; }
  .col-grid3 .datePicker .calendar-icon.right1 {
    right: 1px; }
  .col-grid3 .datePicker .calendar-icon.right40 {
    right: 40px; }

.col-grid3 .col:last-child .calendar-icon {
  right: 0; }

.form-inputField input[disabled], .form-inputFieldrdonly input[disabled], .form-inputPlaceholder input[disabled], .form-inputFieldError input[disabled], .form-inputFieldImp input[disabled],
.form-inputFieldrdonly input[disabled],
.form-inputPlaceholder input[disabled],
.form-inputFieldError input[disabled],
.form-inputFieldImp input {
  font-size: 14px !important;
  padding-top: 7px !important; }

.form-inputField label[data-shrink=true] + div:after, .form-inputFieldrdonly label[data-shrink=true] + div:after, .form-inputPlaceholder label[data-shrink=true] + div:after, .form-inputFieldError label[data-shrink=true] + div:after, .form-inputFieldImp label[data-shrink=true] + div:after,
.form-inputFieldrdonly label[data-shrink=true] + div:after,
.form-inputPlaceholder label[data-shrink=true] + div:after,
.form-inputFieldError label[data-shrink=true] + div:after,
.form-inputFieldImp label[data-shrink=true] + div:after {
  border-bottom: 1px solid #0093d0; }

.form-inputField, .form-inputFieldrdonly, .form-inputPlaceholder, .form-inputFieldError, .form-inputFieldImp,
.form-inputFieldError,
.form-inputFieldImp,
.form-inputFieldrdonly,
.form-inputPlaceholder {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

label.invalidTxt {
  color: #ff6868; }

.select-chip {
  min-width: 240px !important;
  width: 100%; }
  .select-chip label {
    color: #4a4a4a !important;
    font-size: 16px;
    line-height: 16px;
    font-family: "latoregular", sans-serif !important; }
    .select-chip label[data-shrink="false"] {
      padding-left: 10px; }
    .select-chip label + div {
      border: 0px solid #d8d8d8;
      border-bottom: 0px;
      margin-top: 22px; }
      .select-chip label + div:before {
        border-color: #d8d8d8 !important;
        border-width: 1px !important; }
      .select-chip label + div:after {
        background: transparent;
        border-width: 1px;
        border-color: #d8d8d8; }
      .select-chip label + div div:focus {
        background: transparent; }
      .select-chip label + div .chips {
        border: 0px solid #ddd;
        padding: 0 10px;
        background: #fff; }
        .select-chip label + div .chips .chip {
          height: 30px !important;
          margin-top: 4px;
          margin-bottom: 4px;
          margin-right: 8px; }
          .select-chip label + div .chips .chip span {
            font-family: 'latoregular';
            font-weight: normal;
            font-size: 14px; }

.select-chip label[data-shrink="true"] + div {
  border: 1px solid #d8d8d8;
  border-bottom: 0px; }

.CustomInputFieldTopAlign .formSelectBoxImp {
  margin-top: 0px; }

.CustomInputFieldTopAlign .form-inputField input, .CustomInputFieldTopAlign .form-inputFieldrdonly input, .CustomInputFieldTopAlign .form-inputPlaceholder input, .CustomInputFieldTopAlign .form-inputFieldError input, .CustomInputFieldTopAlign .form-inputFieldImp input {
  padding-top: 6px !important; }

.col.textarea .form-inputFieldImp div textarea {
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 75px; }

.col.textarea .form-inputFieldImp div:before {
  height: 90px; }

.selectField {
  width: 100%; }
  .selectField label {
    font-size: 14px;
    line-height: 16px;
    font-family: "latoregular", sans-serif !important;
    color: #4a4a4a; }
    .selectField label + div:before {
      border-bottom: 1px solid #9b9b9b; }
    .selectField label + div:after {
      border-bottom: 1px solid #0093d0; }
    .selectField label + div:hover:before {
      border-bottom: 1px solid #9b9b9b !important; }
    .selectField label + div div[aria-haspopup="true"]:focus {
      background: transparent !important; }
  .selectField label[data-shrink="true"] {
    color: #4a4a4a !important;
    font-size: 16px; }

.dragableData > div {
  border: 1px solid #d8d8d8 !important;
  width: 49% !important;
  min-height: 172px;
  margin-bottom: 20px;
  background: #fff !important;
  float: left; }
  .dragableData > div:first-child {
    height: 418px;
    margin-bottom: 0;
    overflow: auto; }
  .dragableData > div > div {
    padding: 4px 13px !important;
    color: #000;
    font-size: 14px;
    border-radius: 20px;
    width: auto;
    display: inline-block;
    margin: 0 10px 10px 0 !important;
    max-width: 100%;
    word-wrap: break-word; }

.dragableData div + label + div {
  margin-left: 2%; }

.dragableData div + label + div + label + div {
  margin-left: 2%; }

.requiredClass .formSelectBoxRdonly div:nth-child(2):before {
  border-left: 0px; }

.action-icon img {
  cursor: pointer; }

ul[role="listbox"] li:focus {
  background: #e6e6e6 !important; }

.formSelectBoxRdonly label,
.form-inputFieldrdonly label {
  transform: translate(0, 1.5px) scale(0.75);
  -webkit-transform: translate(0, 1.5px) scale(0.75); }

.formSelectBoxRdonly.formControl {
  margin-top: 0 !important; }

.formSelectBoxRdonly div[role="button"] {
  background: transparent !important; }
  .formSelectBoxRdonly div[role="button"] span {
    padding: 0 4px 0 0; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] {
  display: block; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

.formSelectBoxRdonly.formControl div > div > div[aria-pressed] > div > div[role="button"] span {
  white-space: normal;
  word-break: break-word; }

.multiChips {
  display: inline-block;
  background: #e6e6e6 !important;
  border-radius: 21px;
  line-height: 13px;
  padding-left: 10px;
  vertical-align: top;
  height: auto !important; }
  .multiChips span#filterChip {
    margin: 0px;
    padding: 0px;
    display: inline-block;
    line-height: 25px; }
  .multiChips span {
    padding-left: 3px;
    padding-right: 10px; }
  .multiChips > div[role="button"] {
    height: 30px; }

.form-inputFieldrdonly label,
.formSelectBoxRdonly label {
  color: #4a4a4a !important; }

.fileField.requiredClass .typeField {
  border-left: 2px solid #ff6868; }

.fileField .typeField {
  padding-left: 12px;
  border-bottom: 1px solid #9b9b9b;
  margin-top: 17px;
  position: relative;
  cursor: pointer; }
  .fileField .typeField input {
    padding: 0 !important;
    border: 0;
    opacity: 0;
    height: 30px;
    cursor: pointer; }
  .fileField .typeField i {
    position: absolute;
    right: 7px; }
  .fileField .typeField span {
    position: absolute;
    left: 10px;
    color: #4a4a4a;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%; }

.fileField .note {
  font-size: 13px;
  color: #9b9b9b; }

.customInputField .label-text {
  color: #4a4a4a !important;
  font-size: 13px;
  margin-bottom: 11px; }

ul[role="listbox"] li.OptionHeading {
  color: #0093d0;
  opacity: 1;
  background: #efeeee !important;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
  padding-top: 9px !important;
  border-top: 1px solid #ddd; }

.checkboxCustom.marTop-14 {
  margin-top: -14px; }

.checkboxCustom label .customCheckboxlbl {
  display: inline-block;
  color: #4a4a4a;
  cursor: pointer; }

.customRselect.wd200 {
  width: 200px; }

.customRselect > div {
  width: 100%;
  background: #fff; }
  .customRselect > div:before {
    border: 0 !important; }
  .customRselect > div:hover:before {
    border: 0 !important; }
  .customRselect > div:after {
    border: 0; }
  .customRselect > div > div > div {
    padding-left: 10px;
    font-family: "latoregular",sans-serif !important;
    font-style: normal;
    font-size: 14px; }
    .customRselect > div > div > div:focus {
      background: #fff; }
    .customRselect > div > div > div em {
      font-family: "latoregular",sans-serif !important;
      font-style: normal;
      font-size: 14px; }

.multiselectDropdown > div > div > label + div {
  text-align: left; }
  .multiselectDropdown > div > div > label + div > div > div[aria-pressed] > div > div[role="button"] {
    margin-bottom: 2px; }

input[type="date"] {
  padding-right: 0 !important;
  cursor: pointer; }

.start-Adornmen {
  margin-right: 0 !important;
  padding-left: 0px; }
  .start-Adornmen p {
    padding-left: 10px;
    font-family: "latoregular",sans-serif !important;
    color: #4a4a4a !important; }
  .start-Adornmen + input {
    padding-left: 2px !important; }

.tableFieldset .scrollTbl .form-inputField .start-Adornmen + input[type="number"], .tableFieldset .scrollTbl .form-inputFieldrdonly .start-Adornmen + input[type="number"], .tableFieldset .scrollTbl .form-inputPlaceholder .start-Adornmen + input[type="number"], .tableFieldset .scrollTbl .form-inputFieldError .start-Adornmen + input[type="number"], .tableFieldset .scrollTbl .form-inputFieldImp .start-Adornmen + input[type="number"] {
  padding-left: 2px !important; }

.txtBlackLabelData.adornmenField {
  padding-left: 15px !important; }

.adornmenField .form-inputField .start-Adornmen, .adornmenField .form-inputFieldrdonly .start-Adornmen, .adornmenField .form-inputPlaceholder .start-Adornmen, .adornmenField .form-inputFieldError .start-Adornmen, .adornmenField .form-inputFieldImp .start-Adornmen {
  padding-bottom: 4px; }

.inputField > .label > strong {
  font-weight: normal;
  font-family: 'latobold';
  color: #000; }

.minHeight43 {
  height: 43px; }

.datePicker.leadDate .calendar-icon {
  top: 2px;
  right: 0px; }
  .datePicker.leadDate .calendar-icon button.dateIconButton {
    padding: 0px !important; }

.formControl.autosuggest.errorSelect > label + div {
  border-bottom: 1px solid #ff6868 !important; }

.formControl.autosuggest.selectField {
  margin-top: 19px !important; }
  .formControl.autosuggest.selectField > label {
    line-height: 15px !important; }

.gridsBase, .col-fullwidth, .col-grid2 > .col, .col-grid3 > .col, .col-grid4 > .col, .col-grid5 > .col, .col-grid6 > .col, .col-grid7 .col, .col-grid8 .col, .col-grid9 .col, .col-grid10 .col, .col-grid12 .col, .col-grid13 .col, .col-grid11 .col {
  display: block;
  position: relative;
  min-height: 1px;
  float: left;
  width: 100%;
  box-sizing: border-box; }

.col-fullwidth {
  width: 100%; }

.col-grid2 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row; }
  @media screen and (max-width: 767px) {
    .col-grid2 {
      display: block; } }
  .col-grid2 > .col {
    width: 50%;
    margin-bottom: 15px; }
    .col-grid2 > .col:first-child {
      padding-right: 30px;
      padding-left: 0 !important; }
      @media screen and (max-width: 992px) {
        .col-grid2 > .col:first-child {
          padding-right: 10px; } }
      @media screen and (max-width: 767px) {
        .col-grid2 > .col:first-child {
          padding-right: 0; } }
    .col-grid2 > .col:last-child {
      padding-left: 30px; }
      @media screen and (max-width: 992px) {
        .col-grid2 > .col:last-child {
          padding-left: 10px; } }
      @media screen and (max-width: 767px) {
        .col-grid2 > .col:last-child {
          padding-left: 0; } }
    @media screen and (max-width: 767px) {
      .col-grid2 > .col {
        width: 100%;
        float: none; } }
  .col-grid2.single-col .col {
    padding-left: 0px; }
  .col-grid2:last-child {
    margin-bottom: 0px; }

.col-grid3 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row; }
  .col-grid3 > .col {
    margin-bottom: 15px;
    width: 33.33% !important; }
    @media screen and (max-width: 620px) {
      .col-grid3 > .col {
        width: 100%;
        float: none; } }
  .col-grid3.one-TwoFull > .col + .col {
    width: 66.66%; }

.col-grid4 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row; }
  .col-grid4 > .col {
    width: 25%;
    padding-right: 20px;
    margin-bottom: 20px; }
    > .col-grid4 > .col:first-child {
      padding: 0px; }
    > .col-grid4 > .col:last-child {
      padding: 0px; }
    @media screen and (max-width: 1000px) {
      .col-grid4 > .col {
        width: 33.33%; } }
    @media screen and (max-width: 768px) {
      .col-grid4 > .col {
        width: 50%; } }
    @media screen and (max-width: 480px) {
      .col-grid4 > .col {
        width: 100%;
        float: none; } }

.col-grid5 {
  width: 100%;
  float: left; }
  .col-grid5 > .col {
    width: 20%; }
    @media screen and (max-width: 768px) {
      .col-grid5 > .col {
        width: 50%; } }
    @media screen and (max-width: 480px) {
      .col-grid5 > .col {
        width: 100%;
        float: none; } }

.col-grid6 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row; }
  .col-grid6 > .col {
    width: 16.6%;
    margin-bottom: 15px; }
    @media screen and (max-width: 768px) {
      .col-grid6 > .col {
        width: 50%; } }
    @media screen and (max-width: 480px) {
      .col-grid6 > .col {
        width: 100%;
        float: none; } }

.col-grid7 .col {
  width: 14.28%; }

.col-grid8 .col {
  width: 12.5%; }

.col-grid9 .col {
  width: 11.11%; }

.col-grid10 .col {
  width: 10%; }

.col-grid9 .col {
  width: 11.11%; }

.col-grid12 .col {
  width: 8.33%; }

.col-grid13 .col {
  width: 7.69%; }

.col-grid11 .col {
  width: 9%; }

@media (min-width: 800px) and (max-width: 1000px) {
  .floatRight .selection {
    min-width: 10em !important; } }

.PagingOption {
  min-width: 10em !important; }

.col-innerleft {
  width: 50%;
  float: left; }

.col-innerright {
  width: 50%;
  float: left;
  padding-left: 14px; }

.col20 {
  width: 20%;
  float: left; }

.col30 {
  width: 30%;
  float: left; }

.col40 {
  width: 40%;
  float: left; }

.col50 {
  width: 50%;
  float: left; }

.col60 {
  width: 60% !important;
  float: left; }

.col70 {
  width: 70%;
  float: left; }

.col20 {
  width: 20%;
  float: left; }

.col10 {
  width: 10% !important;
  float: left; }

.col5 {
  width: 5%;
  float: left; }

.col90 {
  width: 90%;
  float: left; }

.col95 {
  width: 95%;
  float: left; }

.col66 {
  width: 66.66% !important;
  float: left; }

.col75 {
  width: 75% !important;
  float: left; }

.col80 {
  width: 80% !important;
  float: left; }

.col32 {
  width: 32% !important; }

.col27 {
  width: 27% !important; }

.col25 {
  width: 25% !important; }

.col-40 {
  width: 40% !important;
  float: left; }

.col-60 {
  width: 60% !important;
  float: left; }

.col-30 {
  width: 30% !important;
  float: left; }

.col-70 {
  width: 70% !important;
  float: left; }

.chargeTxt {
  float: left;
  padding-top: 20px;
  text-align: center;
  font-size: 15px; }

.col-paddingR-10 .colls {
  padding-right: 10px; }
  .col-paddingR-10 .colls:last-child {
    padding-right: 0; }

.col100 {
  width: 100%;
  float: left; }

.checkbox-lbl {
  margin-top: 22px;
  margin-bottom: 0; }
  .checkbox-lbl .checkBoxWrap span {
    font-size: 14px;
    font-family: "latoregular",sans-serif !important; }
    .checkbox-lbl .checkBoxWrap span + span {
      color: #4a4a4a; }

.col-grid3 .col {
  padding-right: 40px;
  padding-left: 0 !important; }
  .col-grid3 .col.col-last:last-child {
    padding-right: 40px; }

.col-grid3 > .col + .col + .col {
  padding-right: 0px; }

.col-grid1 > .col {
  padding-right: 0px !important;
  padding-left: 0 !important;
  width: 100%;
  margin-bottom: 15px; }

.subcol2 .col50:first-child {
  padding-right: 10px; }

.col-grid2 .dimension .col {
  margin-bottom: 0;
  margin-top: 0px;
  width: 13%;
  float: left;
  padding-right: 0px !important; }
  .col-grid2 .dimension .col.division {
    width: 6%;
    line-height: 34px;
    font-size: 14px;
    text-align: center;
    color: #000; }
  .col-grid2 .dimension .col.or {
    width: 6%;
    line-height: 34px;
    font-size: 14px;
    text-align: center;
    color: #000; }
  .col-grid2 .dimension .col:last-child {
    padding-left: 10px;
    width: 17%; }
  .col-grid2 .dimension .col.col30 {
    width: 34%; }
  .col-grid2 .dimension .col.last-col {
    width: 20% !important; }
  .col-grid2 .dimension .col.custom-select {
    margin-left: 4%;
    width: 21%; }
    .col-grid2 .dimension .col.custom-select .formControl {
      margin: 0; }
      .col-grid2 .dimension .col.custom-select .formControl label + div {
        margin-top: 2px; }
  .col-grid2 .dimension .col a {
    text-decoration: underline; }
    .col-grid2 .dimension .col a:hover {
      text-decoration: none; }

.lbl-text {
  font-family: "latoregular",sans-serif !important;
  font-size: 12px;
  color: #4a4a4a;
  margin: 0;
  line-height: 12px;
  margin-bottom: 4px; }

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .col-grid3.mdl-screen {
    display: block; }
    .col-grid3.mdl-screen .col1 {
      width: 66.66%;
      padding-right: 0px; }
      .col-grid3.mdl-screen .col1 .col50 {
        padding-right: 40px; }
      .col-grid3.mdl-screen .col1 + .col {
        width: 33.33% !important;
        padding-right: 0px; }
        .col-grid3.mdl-screen .col1 + .col + .col {
          clear: both;
          padding-right: 40px; }
    .col-grid3.mdl-screen .col-mdl-75 {
      width: 66.66% !important;
      padding-right: 0px;
      clear: both; }
      .col-grid3.mdl-screen .col-mdl-75 .col50 {
        padding-right: 40px; }
    .col-grid3.mdl-screen .col-mdl-25 {
      width: 33.33%;
      padding-right: 0px; }
    .col-grid3.mdl-screen .col-mdl-25-both {
      clear: both;
      padding-right: 40px; }
    .col-grid3.mdl-screen .col-mdl-25-pR40 {
      width: 33.33%;
      padding-right: 40px; }
  .col-grid3.editCustomerwebService .col:first-child {
    padding-right: 5px; }
  .col-grid3.editCustomerwebService .col .radioGroupWrap .radioGroup .radio-col {
    margin-right: 10px; }
  .col-grid3.editCustomerwebService .col:last-child {
    width: 60%; } }

.colBlock > div > .col {
  display: block !important; }

.discountMargin {
  margin-top: 6px; }

.topbar {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ffffff;
  height: 54px;
  width: 100%;
  float: left;
  position: fixed;
  top: 0px;
  z-index: 10;
  -webkit-box-shadow: 0 4px 20px 0 rgba(37, 38, 94, 0.1);
  -moz-box-shadow: 0 4px 20px 0 rgba(37, 38, 94, 0.1);
  -ms-box-shadow: 0 4px 20px 0 rgba(37, 38, 94, 0.1);
  -o-box-shadow: 0 4px 20px 0 rgba(37, 38, 94, 0.1);
  box-shadow: 0 4px 20px 0 rgba(37, 38, 94, 0.1); }

.logoSection {
  float: left;
  padding: 0px 20px;
  line-height: 54px;
  border-right: 1px solid rgba(204, 204, 204, 0.5); }
  .logoSection .navbarLogo {
    float: left;
    margin-right: 12px;
    padding: 13px 0; }
  .logoSection .menuIconWrap {
    float: left; }
    .logoSection .menuIconWrap .menuIcon {
      cursor: pointer;
      color: #4a4a4a;
      background: none !important;
      padding: 15px 0px !important;
      min-width: 32px;
      vertical-align: top; }
      .logoSection .menuIconWrap .menuIcon svg {
        color: #4a4a4a;
        transition: all 0.3s; }
    .logoSection .menuIconWrap .closeIcon {
      opacity: 0;
      width: 0px;
      transition: all 0.3s;
      position: absolute; }
      .logoSection .menuIconWrap .closeIcon:before {
        font-size: 20px;
        color: #4a4a4a; }
    .logoSection .menuIconWrap.active .closeIcon {
      width: auto;
      opacity: 1; }
    .logoSection .menuIconWrap.active svg {
      width: 0px;
      opacity: 0; }

.userRole {
  width: 175px;
  float: left;
  position: relative; }
  .userRole .dropDownr {
    width: 100% !important;
    margin-top: -12px; }
  .userRole .userIcon {
    width: 26px;
    height: 26px;
    background: #9b9b9b;
    line-height: 29px;
    color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-box-sizing: 50%;
    border-radius: 50%; }
    .userRole .userIcon i:before {
      font-size: 18px; }
  .userRole .userText {
    font-size: 15px;
    line-height: 16px;
    color: #000;
    letter-spacing: 0.3px;
    margin-left: 15px;
    padding-right: 10px;
    min-width: 90px;
    text-align: left;
    text-transform: none; }
  .userRole .dropdownIcon {
    display: inline-block;
    vertical-align: middle;
    line-height: 7px;
    position: relative;
    top: -2px; }
  .userRole button {
    padding: 14px 15px;
    border-radius: 0px !important;
    border-right: 0px solid #ddd;
    min-width: 175px;
    justify-content: left; }
    .userRole button:hover {
      background: #C0C8D0; }

.arrow-down {
  border: solid #9b9b9b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 8px;
  height: 8px; }

.notificationMob {
  display: none; }

.notification {
  float: left;
  padding: 0 20px;
  line-height: 54px;
  vertical-align: middle;
  border-left: 1px solid rgba(204, 204, 204, 0.5);
  width: calc(50vw - 190px);
  height: 54px;
  display: flex;
  flex-direction: row; }
  .notification .notificationIcon {
    float: none;
    margin-right: 15px;
    margin-top: 14px;
    display: inline-block;
    vertical-align: middle; }
  .notification .notificationMarquee {
    height: 54px;
    width: 100%;
    white-space: nowrap;
    font-size: 18px; }
    .notification .notificationMarquee div {
      white-space: nowrap;
      margin: 0 40px 0 5px; }
      .notification .notificationMarquee div span {
        white-space: nowrap; }

.topBar-userProfile {
  float: left;
  padding: 0px 5px 0 18px;
  border-left: 1px solid rgba(204, 204, 204, 0.5);
  line-height: 54px;
  width: 100%; }
  .topBar-userProfile .userProfile {
    position: relative;
    float: right;
    margin-left: 15px; }
    .topBar-userProfile .userProfile .userIcon {
      display: inline-block;
      vertical-align: middle;
      width: 26px;
      height: 26px;
      margin-right: 10px; }
    .topBar-userProfile .userProfile .dropdownIcon {
      height: 100%;
      vertical-align: middle;
      display: inline-block;
      line-height: 7px;
      position: relative;
      top: -2px; }
    .topBar-userProfile .userProfile button {
      padding: 0px 10px !important;
      vertical-align: top !important;
      min-height: 54px !important;
      border-radius: 0px !important;
      min-width: 50px !important; }
      .topBar-userProfile .userProfile button:hover {
        background: #C0C8D0; }
    .topBar-userProfile .userProfile .popup {
      left: inherit;
      right: -10px; }
      .topBar-userProfile .userProfile .popup i::before {
        position: relative;
        top: 2px; }
  .topBar-userProfile .dropDownr {
    margin-left: -28px;
    margin-top: -12px; }
  .topBar-userProfile .loggedinUser {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    max-width: 60px; }
    .topBar-userProfile .loggedinUser span {
      font-size: 11px;
      display: block;
      line-height: 15px; }
      .topBar-userProfile .loggedinUser span.userName {
        color: #0093d0;
        font-weight: normal;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: 20px;
        font-family: 'latobold';
        font-size: 12px; }

.topSearch {
  padding: 13px 0 9px;
  width: calc(50vw - 407px);
  float: left;
  box-sizing: border-box;
  position: relative; }
  .topSearch div {
    margin: 0 !important; }
  .topSearch input {
    padding-left: 10px !important;
    padding-right: 40px !important;
    border-left: 1px solid #ddd !important; }
  .topSearch .srxTxt {
    width: calc(100% - 57px);
    float: left;
    border-left: 1px solid rgba(204, 204, 204, 0.5);
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box; }
    .topSearch .srxTxt .form-inputPlaceholder {
      margin: 0px !important; }
  .topSearch .btnsrc {
    width: 68px;
    float: left;
    text-align: center;
    position: absolute;
    right: 0;
    top: 8px; }
    .topSearch .btnsrc button {
      padding: 7px 10px;
      min-width: 24px;
      border-radius: 0px; }
      .topSearch .btnsrc button i:before {
        color: #4a4a4a;
        font-size: 24px; }
  .topSearch .dropDownr {
    width: 100%; }

.topBar-navLeft {
  float: left; }

.topBar-navCenter {
  float: left;
  width: calc(100% - 638px); }

.topBar-navRight .notification {
  position: relative; }
  .topBar-navRight .notification .notificationIcon i {
    color: #9b9b9b; }
    .topBar-navRight .notification .notificationIcon i.zmdi-circle {
      display: none; }
  .topBar-navRight .notification .notificationIcon.active i {
    color: #ff6868; }
    .topBar-navRight .notification .notificationIcon.active i.zmdi-circle {
      position: absolute;
      top: 6px;
      left: 34px;
      display: block;
      color: #8bc34a; }
      .topBar-navRight .notification .notificationIcon.active i.zmdi-circle:before {
        font-size: 7px; }
  .topBar-navRight .notification:hover .flashMessage {
    display: block !important; }

.popup.flashMessage {
  position: absolute;
  display: none;
  top: 54px;
  right: 0px;
  padding: 15px 20px;
  width: 300px;
  left: inherit;
  line-height: 17px;
  font-size: 14px;
  color: #ff6868; }

#heightAutoId {
  float: left; }

.footer {
  width: 100%;
  float: left;
  position: absolute;
  bottom: 0px;
  background: #f0f0f0;
  padding: 13px 0; }
  .footer ul {
    text-align: center;
    line-height: 12px; }
    .footer ul li {
      display: inline-block;
      padding: 0 5px;
      font-size: 12px;
      line-height: 12px; }
      .footer ul li a {
        font-size: 12px;
        line-height: 12px;
        color: #9b9b9b;
        letter-spacing: 0.3px; }
        .footer ul li a:hover {
          color: #4a4a4a; }
        @media screen and (max-width: 640px) {
          .footer ul li a {
            font-size: 10px; } }
      @media screen and (max-width: 640px) {
        .footer ul li {
          padding: 0 3px; } }

.footer-section {
  background: #e4e4e4;
  padding: 15px 30px;
  float: left;
  width: 100%;
  height: 62px;
  border-top: 1px solid #ddd; }
  .footer-section.fixedfooter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 70px; }
    .footer-section.fixedfooter .formAction {
      padding: 0; }

.col-left.userList .footer-section {
  position: absolute;
  padding-left: 15px;
  padding-right: 1px;
  background: transparent;
  border: 0; }
  .col-left.userList .footer-section .floatRight {
    display: none; }
  .col-left.userList .footer-section .text-perpage {
    display: none; }

.main-panel {
  width: calc(100% - 54px);
  float: left;
  margin: 54px 0 0 54px;
  padding: 5px 0 0 0; }

.container-fluid {
  padding: 0 30px;
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .container-fluid {
      padding: 0 15px; } }

.main-content {
  width: 100%;
  float: left; }
  .main-content .col-left {
    width: 27%;
    float: left;
    padding: 0px 0px 0px 15px;
    box-sizing: border-box;
    border-right: 1px solid #d8d8d8;
    height: calc(100vh - 102px); }
    .main-content .col-left .content-wrapper {
      margin-right: 0px !important; }
      @media screen and (max-width: 992px) {
        .main-content .col-left .content-wrapper {
          margin-right: 10px !important; } }
      @media screen and (max-width: 767px) {
        .main-content .col-left .content-wrapper {
          margin-right: 0px !important; } }
    @media screen and (max-width: 992px) {
      .main-content .col-left {
        padding: 10px 0px 10px 10px; } }
    @media screen and (max-width: 767px) {
      .main-content .col-left {
        width: 100%;
        max-height: 100%;
        padding: 10px 10px 10px 10px; } }
  .main-content .col-right {
    width: 75%;
    float: left;
    padding: 0px 30px 0 15px;
    box-sizing: border-box;
    overflow: auto; }
    .main-content .col-right form {
      width: 100%; }
    .main-content .col-right .fixed-head {
      width: 100%;
      padding: 6px 1px 7px; }
      .main-content .col-right .fixed-head h1 {
        margin: 0;
        line-height: 24px; }
      .main-content .col-right .fixed-head .col {
        margin-bottom: 0; }
    .main-content .col-right .contentInner {
      background: #fff;
      padding: 10px 30px;
      width: 100%;
      float: left;
      position: relative;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-box-sizing: 3px;
      border-radius: 3px; }
      @media screen and (max-width: 992px) {
        .main-content .col-right .contentInner {
          padding-left: 20px;
          padding-right: 20px; } }
      @media screen and (max-width: 767px) {
        .main-content .col-right .contentInner {
          padding-left: 15px;
          padding-right: 15px; } }
    .main-content .col-right .content-wrapper {
      margin-right: 0px !important; }
      @media screen and (max-width: 767px) {
        .main-content .col-right .content-wrapper {
          margin-right: 0px !important; } }
    @media screen and (max-width: 767px) {
      .main-content .col-right {
        width: 100%;
        max-height: 100%;
        padding: 5px 10px 0 10px; } }
    .main-content .col-right .right-section-scroll {
      height: calc(100vh - 199px);
      width: 100%;
      float: left; }
    .main-content .col-right .right-section-scroll-view {
      height: calc(100vh - 140px);
      width: 100%;
      float: left; }
  .main-content.col-Leftlayout {
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 767px) {
      .main-content.col-Leftlayout {
        display: block; } }

#root {
  width: 100%;
  height: 100%;
  float: left;
  min-height: 100vh;
  position: relative; }

.App {
  width: 100%;
  height: 100%;
  float: left;
  background: #C0C8D0; }

.layout-outer-one-col .breadcrumbBar {
  padding: 0 20px 5px; }

.layout-outer-one-col .main-content {
  padding: 8px 20px 0; }
  .layout-outer-one-col .main-content .content-outer {
    width: 100%;
    float: left; }
  .layout-outer-one-col .main-content .contentInner {
    background: #fff;
    width: 100%;
    float: left;
    padding: 15px 40px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .layout-outer-one-col .main-content .contentInner.pad-left-right-0 {
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 0; }
    .layout-outer-one-col .main-content .contentInner .custom-scroll {
      padding: 0 5px 0 0px; }
  .layout-outer-one-col .main-content .fixed-head {
    box-shadow: 0px 0px 0px #ddd; }
    .layout-outer-one-col .main-content .fixed-head h1 {
      font-weight: normal;
      color: #000;
      font-family: 'latobold';
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 0 0px; }
    .layout-outer-one-col .main-content .fixed-head .border-btm-1 {
      width: 100%;
      padding-right: 35px; }
    .layout-outer-one-col .main-content .fixed-head .border-btm-0 {
      width: 100%;
      box-shadow: 0px 2px 2px #ddd;
      padding-right: 35px; }

.custom-scroll .inner-container {
  margin-right: -17px !important; }

.custom-scroll .outer-container {
  float: left;
  width: 100%; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.clearfix {
  display: inline-block;
  clear: both; }

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

/* close commented backslash hack */
.popup {
  background: #fff;
  padding: 0px;
  float: left;
  width: 220px;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: -8px;
  border: 0px solid #ddd;
  box-shadow: 2px 2px 14px #ccc; }
  .popup.header {
    top: 46px;
    left: -1px; }
    .popup.header ul {
      padding-top: 28px;
      padding-bottom: 18px; }
      .popup.header ul li {
        font-size: 14px;
        color: #9b9b9b;
        font-family: 'latobold';
        padding: 0px 30px 10px; }
        .popup.header ul li i {
          margin-right: 8px;
          width: 16px;
          text-align: center; }
          .popup.header ul li i::before {
            font-size: 22px; }
        .popup.header ul li:hover {
          background: #fff;
          color: #0093d0; }
        .popup.header ul li a {
          font-size: 14px;
          font-family: 'latobold';
          color: #9b9b9b; }
          .popup.header ul li a:hover {
            color: #0093d0; }
  .popup.status {
    width: 270px; }
    .popup.status .radioGroupWrap {
      padding: 20px 30px; }
    .popup.status .dropDownbtn {
      padding-bottom: 15px; }

.userRole .popup.header {
  width: 190px; }

@media (min-width: 768px) {
  .managementButtonsMargin {
    margin-right: 300px; } }

@media (min-width: 768px) and (max-width: 1495px) {
  .managementButtonsMargin {
    margin-right: 200px; } }

.main-wraper {
  width: 100%;
  float: left; }
  .main-wraper .breadcrumbBar {
    border: 0;
    padding: 5px 20px; }
    .main-wraper .breadcrumbBar .breadcrumb {
      margin: 5px 0 4px 0; }
      .main-wraper .breadcrumbBar .breadcrumb li:last-child {
        text-overflow: ellipsis; }
    .main-wraper .breadcrumbBar .right-button {
      float: right; }
      .main-wraper .breadcrumbBar .right-button button {
        padding: 0 10px !important;
        min-height: 31px;
        min-width: inherit;
        -webkit-border-radius: 35px !important;
        -moz-border-radius: 35px !important;
        -ms-border-radius: 35px !important;
        -o-box-sizing: 35px !important;
        border-radius: 35px !important;
        margin-left: 6px;
        cursor: pointer !important; }
        .main-wraper .breadcrumbBar .right-button button span {
          font-family: 'latoregular';
          font-weight: normal !important;
          font-size: 10px;
          letter-spacing: .5px; }
        .main-wraper .breadcrumbBar .right-button button.btnInactive {
          background: #fff !important; }
          .main-wraper .breadcrumbBar .right-button button.btnInactive span {
            color: #4b4b4b !important;
            font-family: 'latobold'; }
        .main-wraper .breadcrumbBar .right-button button em {
          font-style: normal;
          color: #ff6868;
          font-family: 'latoregular';
          font-weight: normal !important;
          font-size: 11px;
          letter-spacing: 0px;
          position: relative;
          top: -1px;
          line-height: 20px; }
  .main-wraper .main-content {
    padding: 0 20px; }
    .main-wraper .main-content .page-title {
      width: 100%;
      float: left;
      padding-bottom: 0px;
      min-height: 32px; }
      .main-wraper .main-content .page-title h2 {
        float: left;
        padding-right: 15px; }
      .main-wraper .main-content .page-title .alert {
        float: left;
        font-family: 'latobold';
        font-size: 14px; }
        .main-wraper .main-content .page-title .alert i {
          vertical-align: top;
          padding-right: 6px; }
          .main-wraper .main-content .page-title .alert i:before {
            font-size: 21px; }
        .main-wraper .main-content .page-title .alert.alert-error {
          color: #ff6868; }
        .main-wraper .main-content .page-title .alert.alert-error {
          color: #ff6868;
          margin-right: 30px; }
          .main-wraper .main-content .page-title .alert.alert-error a {
            line-height: 20px;
            font-family: 'latobold';
            margin-left: 6px;
            font-weight: normal;
            font-size: 14px; }
      .main-wraper .main-content .page-title .right-link {
        float: right; }
      .main-wraper .main-content .page-title button {
        padding: 0 12px !important;
        min-height: 24px;
        min-width: inherit;
        cursor: pointer; }
        .main-wraper .main-content .page-title button span {
          font-family: 'latoregular';
          font-weight: normal !important;
          font-size: 11px;
          letter-spacing: .5px; }
        .main-wraper .main-content .page-title button i {
          margin-right: 5px; }
          .main-wraper .main-content .page-title button i:before {
            font-size: 14px; }
    .main-wraper .main-content .left-section {
      width: 330px;
      float: left;
      margin: 0 10px 0 0; }
      .main-wraper .main-content .left-section .block-section {
        width: 100%;
        background: #fff;
        float: left;
        padding: 15px 20px 0 20px;
        margin: 0 0 10px 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-box-sizing: 3px;
        border-radius: 3px; }
        .main-wraper .main-content .left-section .block-section .block-title {
          float: left;
          width: 100%;
          margin-bottom: 10px; }
          .main-wraper .main-content .left-section .block-section .block-title h2 {
            font-family: 'latobold';
            font-weight: normal; }
            .main-wraper .main-content .left-section .block-section .block-title h2 em {
              font-style: normal;
              font-family: 'latobold';
              color: #9b9b9b;
              width: 144px;
              overflow: hidden;
              display: inline-block;
              white-space: nowrap;
              font-size: 11px;
              line-height: 14px;
              vertical-align: middle;
              text-overflow: ellipsis; }
            .main-wraper .main-content .left-section .block-section .block-title h2 .tooltiptext {
              left: 52%;
              top: 21px;
              bottom: inherit;
              width: 200px; }
          .main-wraper .main-content .left-section .block-section .block-title button {
            padding: 0 12px !important;
            min-height: 24px;
            min-width: inherit;
            cursor: pointer;
            margin: 0 0 0 10px; }
            .main-wraper .main-content .left-section .block-section .block-title button span {
              font-family: 'latoregular';
              font-weight: normal !important;
              font-size: 11px;
              letter-spacing: .5px; }
              .main-wraper .main-content .left-section .block-section .block-title button span img {
                margin-right: 5px; }
            .main-wraper .main-content .left-section .block-section .block-title button i {
              margin-right: 5px; }
              .main-wraper .main-content .left-section .block-section .block-title button i:before {
                font-size: 14px; }
        .main-wraper .main-content .left-section .block-section .block-content {
          width: 100%;
          min-height: 96px;
          padding-bottom: 0px;
          float: left; }
          .main-wraper .main-content .left-section .block-section .block-content .section-grid {
            border-top: 1px solid #ddd;
            float: left;
            width: 100%;
            padding-top: 8px;
            padding-bottom: 6px; }
            .main-wraper .main-content .left-section .block-section .block-content .section-grid .amountduePopup {
              z-index: 3; }
            .main-wraper .main-content .left-section .block-section .block-content .section-grid .col-grid2 .col {
              margin-bottom: 0; }
            .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-value,
            .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text {
              word-wrap: break-word;
              text-overflow: ellipsis; }
              .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-value .ellipsisTxt,
              .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text .ellipsisTxt {
                text-overflow: ellipsis;
                width: 86%;
                display: inline-block;
                font-size: 12px;
                overflow: hidden; }
                .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-value .ellipsisTxt a,
                .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text .ellipsisTxt a {
                  font-size: 12px;
                  display: inline-block;
                  line-height: 16px;
                  vertical-align: top; }
              .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-value .tooltiptext,
              .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text .tooltiptext {
                color: #fff;
                bottom: inherit;
                top: 22px;
                left: 0;
                margin-left: 0;
                display: none; }
                .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-value .tooltiptext:after,
                .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text .tooltiptext:after {
                  left: 12%; }
              .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-value i,
              .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text i {
                vertical-align: top;
                margin-right: 5px;
                line-height: 17px; }
                .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-value i:before,
                .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text i:before {
                  font-size: 14px;
                  color: #000; }
            .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text-grey {
              color: #4a4a4a !important; }
              .main-wraper .main-content .left-section .block-section .block-content .section-grid .label-text-grey:hover .tooltiptext.email {
                display: block;
                width: 135px;
                text-align: left;
                padding: 6px 10px; }
            .main-wraper .main-content .left-section .block-section .block-content .section-grid.basic-info .col:first-child {
              border-right: 1px solid #ddd; }
          .main-wraper .main-content .left-section .block-section .block-content .col-grid2 {
            margin: 0 0 6px 0; }
            .main-wraper .main-content .left-section .block-section .block-content .col-grid2 .col {
              padding-right: 0px; }
              .main-wraper .main-content .left-section .block-section .block-content .col-grid2 .col:last-child {
                padding-left: 10px; }
          .main-wraper .main-content .left-section .block-section .block-content .label-text {
            font-family: "latoregular";
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 0px;
            color: #9b9b9b; }
          .main-wraper .main-content .left-section .block-section .block-content .label-value {
            font-family: "latobold";
            font-size: 12px;
            line-height: 16px;
            color: #4a4a4a; }
            .main-wraper .main-content .left-section .block-section .block-content .label-value span {
              font-family: "latobold";
              font-size: 12px;
              line-height: 16px;
              color: #4a4a4a; }
          .main-wraper .main-content .left-section .block-section .block-content ul {
            margin: 0;
            padding: 0;
            list-style: none; }
            .main-wraper .main-content .left-section .block-section .block-content ul li {
              margin: 0 0 10px 0;
              padding: 0 0 10px 0;
              font-family: "latoregular";
              font-size: 13px;
              line-height: 18px;
              color: #4a4a4a;
              border-bottom: 1px solid #C0C8D0; }
              .main-wraper .main-content .left-section .block-section .block-content ul li:last-child {
                border: 0; }
            .main-wraper .main-content .left-section .block-section .block-content ul.pin {
              padding-right: 10px;
              clear: both; }
              .main-wraper .main-content .left-section .block-section .block-content ul.pin li {
                padding-left: 1px; }
                .main-wraper .main-content .left-section .block-section .block-content ul.pin li:empty {
                  display: none; }
                .main-wraper .main-content .left-section .block-section .block-content ul.pin li.pined {
                  padding-left: 24px;
                  background: url("./../../assets/images/pin.svg") 0 4px no-repeat; }
          .main-wraper .main-content .left-section .block-section .block-content .note-scroll {
            width: 100%;
            float: left;
            max-height: calc(100vh - 545px); }
          .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm-outer {
            position: absolute;
            top: -1px;
            left: 0;
            background: #fff;
            width: 100%;
            height: 100%;
            z-index: 1; }
          .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm .col-grid1 .col {
            margin-bottom: 5px; }
            .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm .col-grid1 .col.textarea .form-inputFieldImp div:before {
              height: 60px; }
            .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm .col-grid1 .col.notes-textarea .form-inputFieldImp div:before {
              height: 147px; }
            .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm .col-grid1 .col.notes-textarea textarea {
              padding-left: 10px !important;
              padding-right: 10px !important;
              height: auto;
              max-height: none; }
            .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm .col-grid1 .col textarea {
              height: 46px; }
          .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm .formAction {
            text-align: left;
            padding: 0 0 0px 0; }
            .main-wraper .main-content .left-section .block-section .block-content .AddNoteForm .formAction button {
              margin: 0 10px 0 0; }
        .main-wraper .main-content .left-section .block-section .block-footer {
          margin: 0 -20px;
          border-top: 0px solid #e9e9e9;
          text-align: right;
          padding: 10px 20px;
          clear: both; }
          .main-wraper .main-content .left-section .block-section .block-footer a {
            font-size: 14px;
            font-family: 'latobold';
            vertical-align: top; }
            .main-wraper .main-content .left-section .block-section .block-footer a img {
              display: inline;
              vertical-align: middle;
              margin-right: 5px; }
            .main-wraper .main-content .left-section .block-section .block-footer a i {
              vertical-align: top;
              margin-right: 5px; }
              .main-wraper .main-content .left-section .block-section .block-footer a i:before {
                font-size: 20px; }
            .main-wraper .main-content .left-section .block-section .block-footer a:hover {
              text-decoration: underline; }
        .main-wraper .main-content .left-section .block-section.pinned-note {
          margin-bottom: 0; }
          .main-wraper .main-content .left-section .block-section.pinned-note .block-title {
            position: relative; }
            .main-wraper .main-content .left-section .block-section.pinned-note .block-title button {
              position: absolute;
              top: -10px;
              right: -10px; }
          .main-wraper .main-content .left-section .block-section.pinned-note .block-content {
            position: relative; }
        .main-wraper .main-content .left-section .block-section.customer-summary .section-grid:last-child .label-text,
        .main-wraper .main-content .left-section .block-section.customer-summary .section-grid:last-child .label-value {
          line-height: 16px; }
        .main-wraper .main-content .left-section .block-section.customer-summary.cs .section-grid.last .col-grid2 > .col:first-child {
          width: 40%; }
        .main-wraper .main-content .left-section .block-section.customer-summary.cs .section-grid.last .col-grid2 > .col:last-child {
          width: 60%; }
    .main-wraper .main-content .right-section {
      margin: 0 0 0 340px; }
      .main-wraper .main-content .right-section .wraper {
        float: left;
        width: 100%;
        background: #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-box-sizing: 3px;
        border-radius: 3px; }
        .main-wraper .main-content .right-section .wraper .fixed-content-nav {
          width: 40px;
          background: #f9f9f9;
          border-right: 1px solid #d8d8d8;
          position: absolute;
          height: calc(100% - 138px);
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px; }
          .main-wraper .main-content .right-section .wraper .fixed-content-nav.indicatorOff i {
            display: none !important; }
            .main-wraper .main-content .right-section .wraper .fixed-content-nav.indicatorOff i.main {
              display: block !important; }
          .main-wraper .main-content .right-section .wraper .fixed-content-nav.screenContract {
            height: calc(100% - 32px); }
          .main-wraper .main-content .right-section .wraper .fixed-content-nav ul {
            margin: 0;
            padding: 0;
            list-style: none; }
            .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li {
              margin: 0;
              padding: 0;
              float: left;
              width: 100%;
              z-index: 2; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li a {
                width: 100%;
                height: 40px;
                display: block;
                border-bottom: 1px solid #fff;
                text-align: center;
                padding-top: 8px; }
                .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li a i:before {
                  font-size: 21px;
                  color: #9a9a9a; }
                .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li a i.icons {
                  display: none;
                  position: absolute;
                  top: -3px;
                  right: 2px;
                  z-index: 1; }
                  .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li a i.icons:before {
                    color: #4a4a4a;
                    font-size: 14px; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li.act {
                background: #0093d0; }
                .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li.act i:before {
                  color: #fff; }
                .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li.act svg path {
                  fill: #fff; }
                .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li.act:hover svg path {
                  fill: #fff; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li:hover svg path {
                fill: #4a4a4a; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li .tooltiptext {
                margin: 0;
                left: 45px;
                top: 5px;
                bottom: inherit;
                text-align: left;
                padding-left: 10px;
                padding-right: 10px;
                width: auto;
                white-space: nowrap; }
                .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li .tooltiptext:after {
                  left: -5px;
                  bottom: inherit;
                  top: 29%;
                  border-color: transparent #000 transparent transparent; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li.alert i.icons {
                display: block; }
                .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li.alert i.icons:before {
                  content: '\F1f8';
                  color: #ff6868; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li.allow i.icons {
                display: block; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li:first-child {
                border-top-left-radius: 3px; }
              .main-wraper .main-content .right-section .wraper .fixed-content-nav ul li:nth-child(2) {
                border-top-left-radius: 3px; }
        .main-wraper .main-content .right-section .wraper .content-scroll-body {
          padding: 0px 5px 0px 71px;
          float: left;
          width: 100%; }
          .main-wraper .main-content .right-section .wraper .content-scroll-body.edit-mode {
            padding: 20px 5px 0px 30px; }
          .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form .button-set {
            position: absolute;
            width: 100%;
            top: 0; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form .button-set .formAction {
              position: absolute;
              top: -54px;
              right: 0;
              width: auto;
              padding: 0; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form .button-set .formAction button {
                padding: 0 12px !important;
                min-height: 24px;
                min-width: inherit;
                cursor: pointer;
                margin: 0 0 0 10px; }
                .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form .button-set .formAction button span {
                  font-family: 'latoregular';
                  font-weight: normal !important;
                  font-size: 11px;
                  letter-spacing: .5px; }
                .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form .button-set .formAction button i {
                  margin-right: 5px; }
                  .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form .button-set .formAction button i:before {
                    font-size: 14px;
                    color: #4a4a4a; }
                .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form .button-set .formAction button img {
                  vertical-align: middle;
                  margin-right: 5px; }
          .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container {
            height: calc(100vh - 162px);
            width: 100%;
            float: left;
            padding-right: 25px; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head {
              margin-top: 10px; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head.std2 {
                margin-bottom: 20px; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head.std3 {
                margin-bottom: 30px; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head.std4 {
                margin-bottom: 40px; }
                .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head.std4 h1 {
                  padding-top: 5px; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset.row-margin-b20 .col-grid2 {
              margin-bottom: 20px; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset.row-checkbox {
              padding-top: 10px; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset.row-checkbox label {
                margin-bottom: 0; }
                .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset.row-checkbox label > span + span {
                  height: auto;
                  font-size: 14px;
                  color: #4a4a4a; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset#Preferences .col {
              margin-bottom: 0px; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset#Preferences .tableFieldset {
              clear: both;
              padding-top: 10px; }
          .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .footer-section.fixedfooter {
            position: static;
            margin: 0 -5px 0 -31px;
            width: auto;
            float: none;
            clear: both; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .footer-section.fixedfooter.addForm {
              margin: 0 -24px 0 -50px; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .footer-section.fixedfooter.addForm button i {
                display: none; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .footer-section.fixedfooter.addForm button img {
                display: none; }
    .main-wraper .main-content .full-section {
      padding: 0px;
      float: left;
      width: 100%; }
  .main-wraper.wide .breadcrumbBar {
    padding: 0 0 5px 0;
    border-bottom: 0px solid #d8d8d8; }
    .main-wraper.wide .breadcrumbBar .breadcrumb {
      padding: 0px;
      margin: 5px 0 0 0; }
    .main-wraper.wide .breadcrumbBar .container-fluid {
      padding: 0 20px; }

.flyway {
  position: absolute;
  width: 40px;
  height: 39px;
  background: #0093d0;
  z-index: 1;
  opacity: 1;
  top: 0px;
  transition: all .2s;
  display: none; }

.right-section .pagination-wraper {
  width: 100%;
  padding: 20px 0; }

.breadcrumbBar .tooltipWrap .tooltiptext {
  bottom: inherit;
  top: 42px; }

.productWarning {
  font-size: 14px;
  line-height: 20px;
  color: #ff6868;
  text-transform: none;
  letter-spacing: 0.3px;
  font-family: "latobold";
  margin-left: 20px;
  vertical-align: top; }
  .productWarning i {
    margin-right: 10px;
    top: -2px;
    position: relative; }

.AddNoteForm .col-grid1 .col {
  margin-bottom: 5px; }
  .AddNoteForm .col-grid1 .col.textarea .form-inputFieldImp div:before {
    height: 60px; }
  .AddNoteForm .col-grid1 .col.notes-textarea .form-inputFieldImp div:before {
    height: 147px; }
  .AddNoteForm .col-grid1 .col.notes-textarea textarea {
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: auto;
    max-height: none; }
  .AddNoteForm .col-grid1 .col textarea {
    height: 46px; }

.globalDiscountForm {
  border-bottom: 1px solid #d8d8d8; }
  .globalDiscountForm fieldset {
    border: none; }

.carrierMarginAdjustmentForm fieldset {
  border: none; }
  .carrierMarginAdjustmentForm fieldset .col .col-grid3 .col {
    margin-bottom: 0;
    padding-right: 20px;
    height: 20px; }
  .carrierMarginAdjustmentForm fieldset .zmdi-help-outline:before {
    font-size: 16px; }
  .carrierMarginAdjustmentForm fieldset .top {
    width: 408px;
    bottom: 24px;
    left: -148px; }
    .carrierMarginAdjustmentForm fieldset .top:after {
      top: 40px;
      border-color: #000 transparent transparent transparent; }

.setting-section-header {
  margin: 10px 0 !important; }

.setting-section-text {
  padding-bottom: 30px; }

.left-sidebar-nav {
  width: 54px;
  float: left;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #262626;
  height: 100%;
  padding: 54px 0 0 0;
  transition: 0.5s;
  z-index: 9;
  overflow-y: auto;
  overflow-x: hidden; }
  .left-sidebar-nav .listWrap {
    padding: 0px 0;
    width: 100%;
    overflow: hidden; }
    .left-sidebar-nav .listWrap .listItem {
      font-size: 16px;
      line-height: normal;
      color: #9b9b9b;
      position: relative;
      cursor: pointer;
      padding: 0px 0px !important;
      margin-bottom: 0px !important;
      transition: all 0.5s; }
      .left-sidebar-nav .listWrap .listItem .linkTxt {
        padding: 0px 0 0 4px !important;
        opacity: 0; }
        .left-sidebar-nav .listWrap .listItem .linkTxt span {
          vertical-align: middle;
          font-size: 14px;
          line-height: 16px;
          color: #9b9b9b;
          font-weight: bold;
          font-family: "latoregular", sans-serif;
          white-space: nowrap; }
      .left-sidebar-nav .listWrap .listItem i {
        display: inline-block;
        vertical-align: middle;
        width: 54px;
        height: 54px;
        padding: 15px 25px 15px 15px; }
        .left-sidebar-nav .listWrap .listItem i:before {
          color: #9b9b9b;
          font-size: 24px; }
        .left-sidebar-nav .listWrap .listItem i.zmdi-money:before {
          margin-left: 4px; }
      .left-sidebar-nav .listWrap .listItem svg {
        fill: #9b9b9b;
        width: 20px;
        height: 21px;
        margin-right: 10px; }
      .left-sidebar-nav .listWrap .listItem:hover {
        background: #4a4a4a; }
        .left-sidebar-nav .listWrap .listItem:hover > i:before {
          color: #ff6c2f; }
        .left-sidebar-nav .listWrap .listItem:hover svg {
          fill: #ff6c2f; }
        .left-sidebar-nav .listWrap .listItem:hover:after {
          width: 3px;
          height: 100%;
          background: #ff6c2f;
          content: "";
          position: absolute;
          right: 0px;
          top: 0px; }
        .left-sidebar-nav .listWrap .listItem:hover .linkTxt span {
          color: #fff; }
      .left-sidebar-nav .listWrap .listItem.active {
        background: #4a4a4a; }
        .left-sidebar-nav .listWrap .listItem.active > i:before {
          color: #ff6c2f; }
        .left-sidebar-nav .listWrap .listItem.active:after {
          width: 3px;
          height: 100%;
          background: #ff6c2f;
          content: "";
          position: absolute;
          right: 0px; }
        .left-sidebar-nav .listWrap .listItem.active .linkTxt span {
          color: #fff; }
      .left-sidebar-nav .listWrap .listItem.hascollapseContent {
        margin-bottom: 0px !important; }
  .left-sidebar-nav.sideNav-active {
    width: 232px; }
    .left-sidebar-nav.sideNav-active .collapseContent {
      display: block; }
    .left-sidebar-nav.sideNav-active .listWrap {
      width: 100%;
      overflow: hidden; }
      .left-sidebar-nav.sideNav-active .listWrap .linkTxt {
        opacity: 1;
        padding-top: 17px !important;
        padding-bottom: 17px !important; }
  .left-sidebar-nav .custom-scroll .custom-scrollbar {
    width: 0px; }

.collapseContent {
  display: none;
  background: #4a4a4a;
  position: relative;
  padding-bottom: 20px; }
  .collapseContent:after {
    width: 3px;
    height: 100%;
    background: #ff6c2f;
    content: "";
    position: absolute;
    right: 0px;
    top: 0px; }
  .collapseContent .scdlistWrap {
    margin: 0px !important;
    padding: 0px 0 !important; }
    .collapseContent .scdlistWrap .scdlistItem {
      margin-bottom: 1px;
      padding: 4px 21px;
      cursor: pointer; }
      .collapseContent .scdlistWrap .scdlistItem .scdlistTxt {
        padding-left: 15px;
        display: inline-block; }
        .collapseContent .scdlistWrap .scdlistItem .scdlistTxt span {
          font-size: 12px;
          line-height: 14px;
          color: #9b9b9b;
          font-family: "latobold", sans-serif;
          white-space: nowrap;
          display: block;
          line-height: 16px; }
      .collapseContent .scdlistWrap .scdlistItem i {
        display: inline-block;
        vertical-align: top;
        line-height: 14px; }
        .collapseContent .scdlistWrap .scdlistItem i:before {
          color: #9b9b9b;
          font-size: 7px; }
      .collapseContent .scdlistWrap .scdlistItem.subHead {
        padding-bottom: 0; }
        .collapseContent .scdlistWrap .scdlistItem.subHead .scdlistTxt span {
          line-height: 16px; }
        .collapseContent .scdlistWrap .scdlistItem.subHead i:before {
          color: #fff; }

.left-sidebar-nav1:hover {
  width: 232px; }

.left-sidebar-nav1:hover .listWrap .listItem .linkTxt {
  opacity: 1; }

.left-sidebar-nav1:hover .collapseContent {
  display: block !important; }

.left-sub-nav {
  position: absolute;
  top: 378px;
  left: 54px;
  background: #4a4a4a;
  height: auto;
  padding: 10px 0px 20px 0px;
  border-right: 3px solid #ff6c2f;
  width: 250px; }
  .left-sub-nav p {
    vertical-align: middle;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-weight: bold;
    font-family: "latoregular", sans-serif;
    padding: 10px 10px 18px 15px; }
  .left-sub-nav ul li {
    font-size: 12px;
    line-height: 14px;
    color: #9b9b9b;
    font-family: "latobold", sans-serif;
    white-space: nowrap;
    display: block;
    line-height: 34px; }
    .left-sub-nav ul li a {
      font-size: 12px;
      line-height: 14px;
      color: #9b9b9b;
      font-family: "latobold", sans-serif;
      white-space: nowrap;
      display: inline-block;
      line-height: 34px; }
    .left-sub-nav ul li i {
      margin-right: 10px; }
      .left-sub-nav ul li i:before {
        color: #9b9b9b;
        font-size: 7px; }
  .left-sub-nav ul > li {
    padding-left: 0px; }
    .left-sub-nav ul > li > i {
      margin-left: 15px; }
    .left-sub-nav ul > li a.active {
      color: #ff6c2f; }
    .left-sub-nav ul > li > div {
      background: #373733; }
      .left-sub-nav ul > li > div > i {
        margin-left: 15px; }
    .left-sub-nav ul > li > ul {
      margin: 0 !important;
      background: #4a4a4a; }
      .left-sub-nav ul > li > ul > li {
        background: #4a4a4a;
        padding-left: 25px; }
        .left-sub-nav ul > li > ul > li > i {
          margin-left: 0px; }
        .left-sub-nav ul > li > ul > li a.active {
          color: #ff6c2f; }
  .left-sub-nav:after {
    content: '';
    border-left: 3px solid #4a4a4a;
    height: 54px;
    position: absolute;
    top: 0;
    left: -3px; }
  .left-sub-nav.customer {
    top: 162px; }
  .left-sub-nav.shipment {
    top: 108px; }
  .left-sub-nav.report {
    top: 216px; }
  .left-sub-nav.lead {
    top: 324px; }
  .left-sub-nav.customerAR {
    top: 270px; }
  .left-sub-nav.tlshipment {
    top: 54px; }

.left-sidebar-nav {
  overflow: visible !important; }

.left-sub-nav ul li.level-2 svg {
  vertical-align: middle; }

.left-sub-nav ul li ul {
  clear: both;
  margin: 0 0 0 15px; }
  .left-sub-nav ul li ul a:hover {
    color: #fff; }

.collapseContent .scdlistWrap .scdlistItem.lavel-2 {
  display: block; }
  .collapseContent .scdlistWrap .scdlistItem.lavel-2 > i {
    margin-top: 5px; }
  .collapseContent .scdlistWrap .scdlistItem.lavel-2 .scdlistItem {
    padding-left: 10px;
    padding-bottom: 0px; }
    .collapseContent .scdlistWrap .scdlistItem.lavel-2 .scdlistItem .scdlistTxt {
      padding-left: 10px; }
    .collapseContent .scdlistWrap .scdlistItem.lavel-2 .scdlistItem a.active {
      color: #ff6c2f; }
      .collapseContent .scdlistWrap .scdlistItem.lavel-2 .scdlistItem a.active .scdlistTxt span {
        color: #ff6c2f; }

.collapseContent .scdlistWrap .scdlistItem a.active .scdlistTxt span {
  color: #ff6c2f; }

.collapseContent .scdlistWrap .scdlistItem.lavel-2 .scdlistItem:hover .scdlistTxt span {
  color: #fff; }

.left-sidebar-nav.sideNav-active .collapseContent .scdlistItem > a:hover span {
  color: #fff; }

.left-sub-nav.shipment ul li a:hover {
  color: #fff; }

.left-sub-nav.customer ul li a:hover {
  color: #fff; }

.left-sub-nav.report ul li a:hover {
  color: #fff; }

.left-sub-nav.tlshipment ul li a:hover {
  color: #fff; }

.left-sidebar-nav .listWrap .listItem.hascollapseContent.settings + div {
  padding-right: 6px !important;
  padding-top: 0px !important; }

.custom-scroll .inner-container {
  overflow-x: hidden;
  overflow-y: auto; }

.custom-scroll .inner-container > .content-wrapper {
  margin-right: 8px !important; }

.settingsList .custom-scroll .inner-container > .content-wrapper {
  margin-right: 0px !important; }

.userList.myCustomer.heightAuto.settingsListBdrL0 .listItem li {
  border-left: 0px; }

.left-sidebar-nav .custom-scroll .inner-container > .content-wrapper {
  margin-right: 17px !important; }

.left-sub-nav ul li a[href="javascript:void(0)"] {
  cursor: default;
  color: #ddd; }

.collapseContent .scdlistWrap .scdlistItem.subHead {
  background: #373737;
  padding-bottom: 10px;
  padding-top: 10px; }

.collapseContent .scdlistWrap .scdlistItem.subHead .scdlistTxt span {
  cursor: default;
  color: #ddd; }

.left-sidebar-nav .collapseContent {
  padding-bottom: 5px; }

.breadcrumbBar {
  width: 100%;
  float: left;
  padding-bottom: 5px;
  border-bottom: 1px solid #d8d8d8; }
  .breadcrumbBar .breadcrumb {
    float: left;
    background: none !important;
    padding: 0px;
    margin: 5px 0 0 0; }
    .breadcrumbBar .breadcrumb .breadcrumb-item {
      display: inline-block;
      margin-right: 3px;
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      color: #0093d0;
      letter-spacing: 0.1px;
      text-transform: uppercase; }
      .breadcrumbBar .breadcrumb .breadcrumb-item .error {
        font-size: 12px;
        line-height: 14px; }
      .breadcrumbBar .breadcrumb .breadcrumb-item a {
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
        color: #000;
        letter-spacing: 0.1px;
        text-transform: uppercase; }
      .breadcrumbBar .breadcrumb .breadcrumb-item.active:before {
        content: "";
        border: solid #4a4a4a;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        -ms-transform: rotate(313deg);
        transform: rotate(313deg);
        -webkit-transform: rotate(313deg);
        width: 8px;
        height: 8px;
        margin-right: 6px; }
  .breadcrumbBar .currentPageIcon {
    float: right; }
    .breadcrumbBar .currentPageIcon button {
      width: 35px;
      height: 35px;
      background: #0093d0;
      min-height: inherit;
      -webkit-box-shadow: 2px 5px 10px 0 rgba(0, 16, 34, 0.14);
      -moz-box-shadow: 2px 5px 10px 0 rgba(0, 16, 34, 0.14);
      -ms-box-shadow: 2px 5px 10px 0 rgba(0, 16, 34, 0.14);
      -o-box-shadow: 2px 5px 10px 0 rgba(0, 16, 34, 0.14);
      box-shadow: 2px 5px 10px 0 rgba(0, 16, 34, 0.14); }
      .breadcrumbBar .currentPageIcon button i::before {
        font-size: 21px;
        line-height: 25px; }
      .breadcrumbBar .currentPageIcon button.truck-add i.zmdi-truck {
        position: relative;
        left: 4px; }
      .breadcrumbBar .currentPageIcon button.truck-add i.zmdi-plus {
        position: absolute;
        left: 3px;
        top: 8px; }
        .breadcrumbBar .currentPageIcon button.truck-add i.zmdi-plus:before {
          font-size: 12px;
          color: #fff; }
    .breadcrumbBar .currentPageIcon.tooltipWrap .tooltiptext {
      width: 80px;
      margin-left: -48px;
      z-index: 2; }
    .breadcrumbBar .currentPageIcon.contract button img {
      transform: scale(0.8); }

.breadcrumbBar .breadcrumb .breadcrumb-item a[href="javascript:void(0)"],
.breadcrumbBar .breadcrumb .breadcrumb-item a[href='#'] {
  cursor: default; }

.breadcrumbBar .breadcrumb .breadcrumb-item.pointer a {
  cursor: pointer; }

.loginwrapper {
  width: 100%;
  max-width: 480px;
  min-height: 370px;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  @media screen and (max-width: 640px) {
    .loginwrapper {
      padding: 0 10px;
      min-height: 316px; } }
  .loginwrapper .innWrapper {
    width: 100%;
    height: 100%;
    float: left;
    padding: 30px 70px 30px;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 1px 10px 0px #e6e8e8;
    -moz-box-shadow: 0px 1px 10px 0px #e6e8e8;
    -ms-box-shadow: 0px 1px 10px 0px #e6e8e8;
    -o-box-shadow: 0px 1px 10px 0px #e6e8e8;
    box-shadow: 0px 1px 10px 0px #e6e8e8;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-box-sizing: 4px;
    border-radius: 4px; }
    .loginwrapper .innWrapper .form-group {
      margin-bottom: 10px; }
    .loginwrapper .innWrapper .loginLogo {
      text-align: center; }
      .loginwrapper .innWrapper .loginLogo a {
        display: inline-block; }
        .loginwrapper .innWrapper .loginLogo a img {
          display: inline-block; }
    @media screen and (max-width: 640px) {
      .loginwrapper .innWrapper {
        padding: 10px 20px 10px; } }
  .loginwrapper .forgot-psw {
    color: #ff6868;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3px;
    float: right;
    margin: 17px 0 0 0; }
  .loginwrapper .lastrow .form-inputField, .loginwrapper .lastrow .form-inputFieldrdonly, .loginwrapper .lastrow .form-inputPlaceholder, .loginwrapper .lastrow .form-inputFieldError, .loginwrapper .lastrow .form-inputFieldImp {
    margin-bottom: 0px !important; }
  .loginwrapper .lastrow .invalidTxt {
    margin-top: 5px; }

.recaptchaWrapper > * > * > * {
  margin-bottom: 30px; }

.forgotPasswordPage .fogotHeader {
  margin-top: 20px;
  margin-bottom: 41px; }
  @media screen and (max-width: 640px) {
    .forgotPasswordPage .fogotHeader {
      margin-top: 10px;
      margin-bottom: 0px; } }
  .forgotPasswordPage .fogotHeader p {
    color: #4a4a4a;
    font-size: 14px;
    line-height: 15px; }

.forgotPasswordPage .btnWrap {
  margin-top: 33px;
  margin-bottom: 10px; }
  @media screen and (max-width: 640px) {
    .forgotPasswordPage .btnWrap {
      margin-top: 10px; } }

.resetHeader {
  position: relative;
  padding-right: 80px; }
  .resetHeader h1 {
    font-family: 'latobold'; }
  .resetHeader p {
    font-size: 14px;
    color: #4a4a4a; }
  .resetHeader img {
    position: absolute;
    right: 0;
    top: 17px; }

.addUserForm fieldset {
  margin-bottom: 17px;
  padding-bottom: 26px;
  border: 0px;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  float: left; }
  .addUserForm fieldset:last-child {
    border-bottom: 0px;
    margin-bottom: 0px; }
  .addUserForm fieldset:first-child {
    padding-top: 0px !important; }
  .addUserForm fieldset.scdFieldset .col-grid2 {
    margin-bottom: 13px; }
    .addUserForm fieldset.scdFieldset .col-grid2:last-child {
      margin-bottom: 0px; }
  .addUserForm fieldset.addFormLastlist {
    margin-top: 15px; }
    .addUserForm fieldset.addFormLastlist .checkBoxWrap [class*="MuiTypography-body1-"] {
      font-size: 14px; }
    .addUserForm fieldset.addFormLastlist .colLast {
      float: left;
      margin-right: 56px; }

.formAction {
  padding: 30px;
  margin: 0px;
  width: 100%;
  float: left; }
  .formAction button {
    margin: 0 18px;
    min-width: 157px; }
    @media screen and (max-width: 767px) {
      .formAction button {
        min-width: auto; } }

.listItem {
  margin: 0px !important;
  padding: 0px !important; }
  .listItem > li {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background: #ffffff;
    margin-bottom: 9px;
    height: 64px;
    border-radius: 3px;
    border: 1px solid #fff;
    border-left: 5px solid #fff;
    padding-top: 0px;
    padding-left: 9px;
    cursor: pointer;
    transition: all 0.2s; }
    @media screen and (max-width: 992px) {
      .listItem > li {
        padding-left: 0px; } }
    .listItem > li .radioGroup {
      width: 30px; }
    .listItem > li [class*=" MuiListItem-gutters-"] {
      padding-left: 0px !important;
      padding-top: 5px;
      padding-bottom: 5px;
      height: 100%; }
    .listItem > li .userName {
      padding-left: 14px; }
      .listItem > li .userName h5 {
        color: #000;
        font-weight: bold; }
      .listItem > li .userName span {
        font-size: 12px;
        line-height: 12px;
        color: #4a4a4a;
        vertical-align: top;
        display: inline-block;
        margin-top: 5px; }
      @media screen and (max-width: 992px) {
        .listItem > li .userName {
          padding-left: 10px; } }
    .listItem > li.activeListItem {
      border: 1px solid #ff6c2f;
      border-left: 5px solid #ff6c2f; }
    .listItem > li:hover {
      /* @include box-shadow(4px 18px 27px 0 rgba(0, 0, 0, 0.13));*/
      -webkit-box-shadow: 4px 4px 16px #999;
      -moz-box-shadow: 4px 4px 16px #999;
      -ms-box-shadow: 4px 4px 16px #999;
      -o-box-shadow: 4px 4px 16px #999;
      box-shadow: 4px 4px 16px #999; }
  .listItem .commonDropdown button {
    width: auto;
    height: auto; }
    .listItem .commonDropdown button svg {
      font-size: 19px;
      color: #9b9b9b; }
    .listItem .commonDropdown button.active svg {
      color: #0093d0; }
  .listItem .commonDropdown .dropDownr {
    margin-left: -90px; }

.commonDropdown {
  display: inline-block;
  position: relative; }

fieldset#LiabilityCoverage {
  min-height: 700px; }

.padlessBtn {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.tbl-data tbody tr td .alink {
  cursor: pointer;
  font-size: 12px; }
  .tbl-data tbody tr td .alink:hover {
    color: #0093d0; }

.ExpansionPanel {
  box-shadow: none !important; }
  .ExpansionPanel .ExpansionPanelHead {
    padding: 0 40px; }
    .ExpansionPanel .ExpansionPanelHead [class*="MuiExpansionPanelSummary-content-"] {
      margin: 30px 0; }
    .ExpansionPanel .ExpansionPanelHead .ExpansionPanelTitle {
      font-size: 15px;
      line-height: 17px;
      color: #000;
      text-transform: none;
      letter-spacing: 0.3px;
      font-family: "latoregular",sans-serif;
      font-weight: bold; }
  .ExpansionPanel .ExpansionPanelContent {
    display: block;
    padding: 8px 40px 24px;
    width: 100%;
    float: left; }
  .ExpansionPanel h3 {
    color: #000; }

.loggedAction h3 {
  color: #000; }

.loggedAction ul li {
  margin-bottom: 22px; }
  .loggedAction ul li:last-child {
    margin-bottom: 0px; }
  .loggedAction ul li .shortdes {
    margin-bottom: 6px; }
    .loggedAction ul li .shortdes p {
      color: #9b9b9b;
      letter-spacing: 0.4px; }
  .loggedAction ul li h4 {
    color: #000;
    letter-spacing: 0.3px;
    font-size: 14px;
    font-weight: normal; }

.publishedInfo p {
  color: #9b9b9b;
  font-size: 12px;
  line-height: 14px; }
  .publishedInfo p span {
    font-size: 12px;
    color: #4a4a4a; }

.publishedInfo .viewCreatedDate {
  float: left; }

.publishedInfo .viewModifiedDate {
  float: right; }

.viewModeform .extension .form-inputFieldrdonly {
  margin-top: 0px !important; }

.userList {
  width: 100%;
  padding: 0 15px 0 15px;
  box-sizing: border-box; }
  .userList .filterBar {
    margin-top: 5px; }
  .userList .listItem li {
    padding: 0 !important;
    display: block;
    float: left;
    width: 100%; }
    .userList .listItem li .flBox {
      display: block;
      width: 100%;
      align-items: center;
      height: 42px; }
    .userList .listItem li .radioGroup {
      height: 30px;
      margin-left: 10px; }
      .userList .listItem li .radioGroup svg {
        font-size: 20px; }
    .userList .listItem li .icon-Morevert {
      display: none;
      margin-right: 5px; }
    .userList .listItem li:hover {
      cursor: default; }
      .userList .listItem li:hover h5 span:hover {
        color: #0093d0;
        cursor: pointer; }
    .userList .listItem li.active {
      border-color: #ff6c2f; }
  .userList .listItem li {
    height: auto; }
    .userList .listItem li .radio-col {
      width: 64px;
      float: left;
      padding-top: 6px; }
    .userList .listItem li .col-fullwidth {
      width: calc(100% - 64px); }
    .userList .listItem li .col {
      line-height: 14px; }
      .userList .listItem li .col h5 {
        display: inline-block;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        width: 100%; }
        .userList .listItem li .col h5 span {
          margin: 0;
          font-family: 'latobold';
          color: #000;
          font-size: 12px;
          width: 64%;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
      .userList .listItem li .col .userEmail,
      .userList .listItem li .col .roleName {
        display: inline-block;
        font-family: 'latoregular';
        font-size: 12px;
        color: #4a4a4a; }
      .userList .listItem li .col .usercomments {
        font-family: 'latoregular';
        font-size: 12px; }
      .userList .listItem li .col .userStatus {
        display: inline-block;
        width: 28%;
        box-sizing: border-box;
        font-family: 'latoregular';
        font-weight: normal;
        text-transform: capitalize;
        font-size: 12px;
        line-height: 12px;
        color: #4a4a4a;
        vertical-align: top;
        margin-top: 5px;
        position: relative; }
        .userList .listItem li .col .userStatus.active:before {
          width: 8px;
          height: 8px;
          background: #0093d0;
          border-radius: 100%;
          content: '';
          position: absolute;
          left: 0;
          top: 2px; }
        .userList .listItem li .col .userStatus.inactive:before {
          width: 8px;
          height: 8px;
          background: #ff6c2f;
          border-radius: 100%;
          content: '';
          position: absolute;
          left: 0;
          top: 2px; }
      .userList .listItem li .col .userModifyed {
        font-family: 'latoregular';
        font-size: 12px;
        color: #000; }
      .userList .listItem li .col .collapsible-icon i {
        width: 30px;
        height: 30px;
        text-align: center;
        cursor: pointer; }
        .userList .listItem li .col .collapsible-icon i:before {
          font-size: 28px;
          line-height: 30px; }
      .userList .listItem li .col.userFullname {
        width: 30%; }
        .userList .listItem li .col.userFullname h5 {
          line-height: 37px; }
      .userList .listItem li .col.userRole {
        width: 30%; }
        .userList .listItem li .col.userRole .roleName {
          line-height: 37px; }
      .userList .listItem li .col.userEmail {
        width: 30%;
        line-height: 37px; }
      .userList .listItem li .col.userStatus {
        width: 10%; }
        .userList .listItem li .col.userStatus .userStatus {
          margin-top: 14px; }
    .userList .listItem li .collapse-section {
      border-top: 1px solid #ddd;
      padding: 5px 60px;
      clear: both; }
      .userList .listItem li .collapse-section h3 {
        color: #000;
        font-family: 'latoregular';
        font-weight: normal;
        margin-bottom: 0;
        margin-top: 6px; }

.col-grid4.userlist-head .col.userFullname {
  width: 30%; }

.col-grid4.userlist-head .col.userRole {
  width: 30%; }

.col-grid4.userlist-head .col.userEmail {
  width: 30%; }

.col-grid4.userlist-head .col.userStatus {
  width: 10%; }

.col-grid4.userlist-head .col i {
  cursor: pointer; }
  .col-grid4.userlist-head .col i:hover:before {
    color: #0093d0; }

.col-left.userList {
  position: relative; }
  .col-left.userList .listItem {
    max-height: calc(100vh - 202px); }
    .col-left.userList .listItem li .radio-col {
      width: 46px; }
      .col-left.userList .listItem li .radio-col .radioGroup {
        margin-left: 5px; }
    .col-left.userList .listItem li .col-fullwidth {
      width: calc(100% - 60px); }
      .col-left.userList .listItem li .col-fullwidth .userRole .roleName {
        width: 93%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        line-height: 18px; }
      .col-left.userList .listItem li .col-fullwidth .userFullname h5 {
        width: 100%;
        line-height: 18px; }
        .col-left.userList .listItem li .col-fullwidth .userFullname h5 span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 20px; }
    .col-left.userList .listItem li .col {
      width: 33%; }
      .col-left.userList .listItem li .col.userEmail {
        display: none; }
      .col-left.userList .listItem li .col.userFullname {
        width: 100%;
        height: 15px;
        margin-top: 5px; }
      .col-left.userList .listItem li .col.userRole {
        width: 100%; }
      .col-left.userList .listItem li .col.userStatus {
        position: absolute;
        right: 0;
        width: auto;
        top: 3px; }
        .col-left.userList .listItem li .col.userStatus .userStatus {
          overflow: hidden;
          padding-right: 0;
          width: 12px;
          margin-top: 10px; }
    .col-left.userList .listItem li .icon-Morevert {
      display: inline-block; }
      .col-left.userList .listItem li .icon-Morevert button {
        width: 30px;
        height: 30px; }
  .col-left.userList .gridFilterHead {
    display: none; }
  .col-left.userList.carrier .userModifyed,
  .col-left.userList.carrier .usercomments {
    display: none; }
  .col-left.userList.carrier .collapsible {
    display: block;
    position: absolute;
    right: 0;
    width: auto !important;
    top: 7px; }
    .col-left.userList.carrier .collapsible .collapsible-icon {
      display: none; }
  .col-left.userList.carrier .userStatus {
    right: 20px; }

.col-right .addUserForm .formAction {
  padding: 12px;
  margin: 0px -30px 0 -15px;
  width: auto;
  clear: both;
  background: #e4e4e4;
  position: absolute;
  bottom: -60px;
  height: 61px;
  border-top: 1px solid #ddd;
  left: 0;
  right: 0; }

.pagination-section {
  margin: 0px 0 0 0; }
  .pagination-section .pagination-wraper .pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    display: inline-block;
    height: 30px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px;
    vertical-align: top;
    cursor: pointer; }
    .pagination-section .pagination-wraper .pagination li {
      margin: 1px 1px 0 0px;
      padding: 0;
      display: inline-block;
      vertical-align: top; }
      .pagination-section .pagination-wraper .pagination li a {
        margin: 0;
        padding: 4px 4px 2px;
        min-width: 20px;
        font-family: 'latobold';
        display: inline-block;
        text-align: center;
        font-size: 14px;
        color: #4a4a4a;
        line-height: 17px; }
        .pagination-section .pagination-wraper .pagination li a:hover {
          color: #0093d0; }
      .pagination-section .pagination-wraper .pagination li button {
        width: 22px;
        height: 22px; }
      .pagination-section .pagination-wraper .pagination li.skip-previous button {
        margin-left: 2px; }
      .pagination-section .pagination-wraper .pagination li.skip-previous i {
        position: relative;
        top: -2px; }
      .pagination-section .pagination-wraper .pagination li.previous button {
        margin-left: 2px; }
      .pagination-section .pagination-wraper .pagination li.skip-next button {
        margin-right: 1px; }
      .pagination-section .pagination-wraper .pagination li.skip-next i {
        position: relative;
        top: -2px; }
      .pagination-section .pagination-wraper .pagination li.next button {
        margin-right: 1px;
        margin-left: 1px; }
      .pagination-section .pagination-wraper .pagination li.current a {
        color: #0093d0;
        border-bottom: 2px solid #0093d0;
        padding-top: 1px;
        padding-bottom: 2px;
        line-height: 23px; }
      .pagination-section .pagination-wraper .pagination li.act i:before {
        color: #0093d0; }
      .pagination-section .pagination-wraper .pagination li i::before {
        font-size: 18px; }
      .pagination-section .pagination-wraper .pagination li .zmdi-caret-left:before {
        font-size: 24px;
        line-height: 27px; }
      .pagination-section .pagination-wraper .pagination li .zmdi-caret-right:before {
        font-size: 24px;
        line-height: 27px; }
  .pagination-section .pagination-wraper .item-per-page {
    display: inline-block;
    margin: 0 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .pagination-section .pagination-wraper .item-per-page .text-perpage {
      padding-left: 12px;
      font-family: 'latobold';
      font-weight: normal;
      font-size: 14px;
      color: #9b9b9b; }

.footer-section .floatRight p {
  font-family: 'latobold';
  font-size: 14px;
  color: #9b9b9b; }
  .footer-section .floatRight p i {
    vertical-align: middle;
    margin-left: 10px; }
    .footer-section .floatRight p i::before {
      font-size: 22px; }

.selectoption div div {
  border: 0; }
  .selectoption div div::before {
    border: 0; }
  .selectoption div div:hover {
    border: 0; }
  .selectoption div div:hover:before {
    border: 0 !important; }

.selectoption select {
  background: #fff;
  padding: 5px 15px 5px 10px !important;
  min-width: 35px;
  font-family: 'latobold' !important;
  font-size: 14px !important;
  border: 1px solid rgba(204, 204, 204, 0.5) !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-box-sizing: 3px;
  border-radius: 3px; }
  .selectoption select:hover {
    background: #fff; }
  .selectoption select option {
    font-size: 14px; }

.filterBar {
  display: flex; }
  .filterBar ul.icon-buttons {
    display: inline-block;
    width: 70px; }
    .filterBar ul.icon-buttons li button {
      margin-left: -9px;
      width: 26px;
      height: 26px; }
      .filterBar ul.icon-buttons li button i::before {
        font-size: 19px; }
      .filterBar ul.icon-buttons li button:hover i::before {
        color: #0093d0; }
      .filterBar ul.icon-buttons li button:hover svg {
        fill: #0093d0; }
      .filterBar ul.icon-buttons li button + button {
        margin-left: 0; }
        .filterBar ul.icon-buttons li button + button span span:first-child {
          width: 22px;
          height: 22px; }
  .filterBar .gridFilterHead {
    display: inline-block;
    width: 100%;
    padding-right: 15px; }
    .filterBar .gridFilterHead div {
      font-family: 'latobold';
      font-size: 12px;
      color: #9b9b9b;
      line-height: 26px; }
      .filterBar .gridFilterHead div i:before {
        font-size: 16px;
        position: relative;
        top: 3px; }
      .filterBar .gridFilterHead div samp {
        font-family: 'latobold';
        font-style: normal;
        font-size: 12px;
        max-width: 70%;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
        line-height: 16px;
        margin-top: 5px;
        padding-right: 10px; }

.colgrid2 {
  width: 50%;
  padding: 0px 15px;
  float: left; }

.colgrid70 {
  width: 70%;
  padding: 0px 15px;
  float: left; }

.colgrid30 {
  width: 30%;
  padding: 0px 15px;
  float: left; }

.pad0 {
  padding: 0px !important; }

.pad-left7 {
  padding-left: 7px !important; }

.userStatus {
  position: relative;
  font-family: 'latoregular';
  font-size: 12px;
  line-height: 14px;
  color: #4a4a4a; }
  .userStatus.active:before {
    width: 8px;
    height: 8px;
    background: #0093d0;
    border-radius: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 2px; }
  .userStatus.inactive:before {
    width: 8px;
    height: 8px;
    background: #ff6c2f;
    border-radius: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 2px; }

.userList .listItem {
  height: calc(100vh - 200px);
  padding-right: 15px !important; }

.data-table {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-box-sizing: 3px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid #e6eaee;
  border-bottom: 0; }
  .data-table thead tr {
    background: #C0C8D0; }
    .data-table thead tr td {
      padding: 8px 10px 8px 12px;
      font-family: 'latobold';
      font-size: 14px;
      border-bottom: 1px solid #e6eaee;
      text-transform: lowercase; }
      .data-table thead tr td:first-letter {
        text-transform: uppercase; }
  .data-table tbody tr td {
    padding: 8px 10px 8px 12px;
    font-family: 'latoregular';
    font-size: 12px;
    border-bottom: 1px solid #e6eaee;
    color: #4a4a4a; }
    .data-table tbody tr td i {
      vertical-align: middle;
      margin-right: 5px; }
      .data-table tbody tr td i:before {
        font-size: 18px;
        color: #0093d0; }
    .data-table tbody tr td .zmdi-close-circle:before {
      color: #9b9b9b; }

.userList.carrier .carrierlist-head .col.name {
  width: 19.6%; }

.userList.carrier .carrierlist-head .col.scac {
  width: 19.6%; }

.userList.carrier .carrierlist-head .col.comments {
  width: 22.6%; }

.userList.carrier .carrierlist-head .col.status {
  width: 11.6%; }

.userList.carrier .carrierlist-head .col.modified {
  width: 10.6%; }

.userList.carrier .carrierlist-head .col i {
  cursor: pointer; }
  .userList.carrier .carrierlist-head .col i:hover::before {
    color: #0093d0; }

.userList.carrier .listItem li .carrierData .col.carrierName {
  width: 19.6%; }
  .userList.carrier .listItem li .carrierData .col.carrierName h5 {
    width: 100%;
    height: 40px;
    vertical-align: middle; }
    .userList.carrier .listItem li .carrierData .col.carrierName h5 span {
      vertical-align: middle;
      line-height: 37px; }

.userList.carrier .listItem li .carrierData .col.scac {
  width: 19.6%; }
  .userList.carrier .listItem li .carrierData .col.scac .roleName {
    vertical-align: middle;
    display: block;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 13px; }

.userList.carrier .listItem li .carrierData .col.carrierComments {
  width: 22.6%; }
  .userList.carrier .listItem li .carrierData .col.carrierComments .usercomments {
    display: table-cell;
    width: 100%;
    height: 40px;
    vertical-align: middle;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px; }

.userList.carrier .listItem li .carrierData .col.carrierStatus {
  width: 11.6%; }
  .userList.carrier .listItem li .carrierData .col.carrierStatus .userStatus {
    margin-top: 14px; }

.userList.carrier .listItem li .carrierData .col.carrierModifyed {
  width: 10.6%; }
  .userList.carrier .listItem li .carrierData .col.carrierModifyed span {
    width: 100px;
    display: inline-block;
    margin-top: 6px;
    color: #7e7e7e; }
    .userList.carrier .listItem li .carrierData .col.carrierModifyed span::first-line {
      color: #000; }

.userList.carrier .listItem li .carrierData .col.collapsible {
  width: 4.6%;
  text-align: right; }
  .userList.carrier .listItem li .carrierData .col.collapsible .collapsible-icon {
    margin-right: 10px;
    margin-top: 6px !important;
    display: inline-block; }
  .userList.carrier .listItem li .carrierData .col.collapsible .icon-Morevert {
    display: none; }

.col-left.userList.carrier .listItem li .collapse-section {
  display: none; }

.col-left.userList.carrier .listItem li .col.carrierName {
  width: 60%; }

.col-left.userList.carrier .listItem li .col.scac {
  width: 34%; }

.col-left.userList.carrier .listItem li .col.carrierComments, .col-left.userList.carrier .listItem li .col.carrierModifyed {
  display: none; }

.col-left.userList.carrier .listItem li .col.carrierStatus {
  width: 10px; }
  .col-left.userList.carrier .listItem li .col.carrierStatus .userStatus {
    width: 9px;
    overflow: hidden;
    padding: 11px 0 0 0;
    text-align: right;
    height: 10px;
    left: 0; }

.col-left.userList.carrier .listItem li .col.collapsible {
  display: none; }

.col-left.userList .custom-scroll {
  height: calc(100% - 98px) !important; }

.main-content.col-Leftlayout .userList {
  height: calc(100vh - 154px); }

.main-content.col-Leftlayout .col-left.userList {
  height: calc(100vh - 102px); }

.userList.myCustomer .listItem li .col.carrierModifyed {
  line-height: inherit; }
  .userList.myCustomer .listItem li .col.carrierModifyed span {
    width: 100px;
    display: inline-block;
    margin-top: 2px;
    color: #7e7e7e; }
    .userList.myCustomer .listItem li .col.carrierModifyed span::first-line {
      color: #000; }

.collapseWraper {
  width: 100%;
  float: left;
  border: 1px solid #ddd; }
  .collapseWraper .listItem > li {
    display: block !important;
    height: auto;
    float: left;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important; }
  .collapseWraper .collapseHead {
    width: 100%;
    float: left;
    border-bottom: 1px solid #ddd;
    min-height: 54px; }
    .collapseWraper .collapseHead label {
      margin: 0;
      float: left; }
    .collapseWraper .collapseHead h2 {
      float: left;
      margin: 16px 0 0 10px;
      font-size: 13px; }
    .collapseWraper .collapseHead .collapsible {
      margin: 13px 12px 0 0;
      float: right; }
  .collapseWraper .collapseBlock {
    width: 100%;
    float: left;
    background: #f9f9f9;
    padding: 20px;
    border-bottom: 1px solid #ddd; }
  .collapseWraper .subHeading {
    clear: both;
    border-top: 1px solid #eaeaea;
    padding: 24px 0 0 0;
    margin-top: 15px;
    float: left;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    color: #000; }
  .collapseWraper h5 {
    font-weight: normal;
    margin-bottom: 15px; }
  .collapseWraper .col-grid3 > .col .formControl {
    margin-bottom: 15px; }

.SecurityLevelForm .button-set {
  position: absolute;
  width: 100%;
  top: 0; }
  .SecurityLevelForm .button-set .formAction {
    position: absolute;
    top: -62px;
    right: -15px;
    width: auto;
    padding: 0; }
    .SecurityLevelForm .button-set .formAction button {
      padding: 0 12px !important;
      min-height: 24px;
      min-width: inherit;
      cursor: pointer;
      margin: 0 0 0 10px; }
      .SecurityLevelForm .button-set .formAction button span {
        font-family: 'latoregular';
        font-weight: normal !important;
        font-size: 11px;
        letter-spacing: .5px; }
      .SecurityLevelForm .button-set .formAction button i {
        margin-right: 5px; }
        .SecurityLevelForm .button-set .formAction button i:before {
          font-size: 14px;
          color: #4a4a4a; }
      .SecurityLevelForm .button-set .formAction button img {
        vertical-align: middle;
        margin-right: 5px; }

.col-grid-repeat3 {
  width: 100%;
  float: left; }
  .col-grid-repeat3 > .col {
    width: 33.3%;
    float: left;
    padding-right: 30px;
    margin-bottom: 20px; }
    .col-grid-repeat3 > .col:nth-child(3n+1) {
      clear: both; }
    .col-grid-repeat3 > .col > .column {
      width: 100%;
      margin-bottom: 20px; }

.white-section.securityLbl {
  padding-right: 15px; }

.collapseBlock {
  position: relative; }

.grayOverlay {
  background: #fff;
  opacity: .6;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1; }

.toolTip {
  position: absolute;
  right: 0;
  top: -19px; }

.alertInfo {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  color: #ff6c2f;
  font-family: 'latoregular';
  font-weight: normal; }
  .alertInfo i {
    vertical-align: middle; }
    .alertInfo i:before {
      font-size: 15px; }

.tooltipWrap .tooltiptext.security_roleEditmodule {
  margin: 0;
  padding: 10px;
  width: 260px;
  text-align: left;
  left: 0;
  bottom: inherit;
  top: -44px; }
  .tooltipWrap .tooltiptext.security_roleEditmodule:after {
    bottom: -10px;
    left: 47%;
    margin-left: -5px;
    border-width: 5px;
    border-color: #000 transparent transparent transparent; }

.listItem.role > li:hover {
  box-shadow: none; }

.infoIcon label {
  padding-right: 4px; }
  .infoIcon label:after {
    content: "!";
    background: #ff6c2f;
    padding: 0;
    color: #fff;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    font-size: 10px;
    text-align: center;
    display: inline-block;
    line-height: 14px;
    position: relative;
    left: 3px; }

.infoIcon .checkBoxWrap:after {
  content: "!";
  transform: scale(0.85);
  left: 2px; }

.infoIcon .alertInfo {
  display: none; }

.securityRoles h5 {
  margin-bottom: 4px; }

.securityRoles .checkBoxWrap {
  margin-bottom: 0; }

.securityRoles .col .column {
  margin-bottom: 10px; }

.blockSection-sq {
  width: 100%;
  float: left;
  background: #f9f9f9;
  padding-top: 20px;
  border-top: 1px solid #ddd; }
  .blockSection-sq > h5 {
    color: #000; }
  .blockSection-sq .col-grid-repeat3 .col > h5 {
    margin-bottom: 0px;
    color: #4a4a4a; }
  .blockSection-sq .col-grid-repeat3 .col .column {
    margin-bottom: 0; }
    .blockSection-sq .col-grid-repeat3 .col .column .checkBoxWrap {
      margin-bottom: 0; }
  .blockSection-sq.lv .col-grid-repeat3 .col > h5 {
    margin-bottom: 10px; }
  .blockSection-sq.lv .col-grid-repeat3 .col .column {
    margin-bottom: 20px; }

.userList .listItem li .col {
  margin-bottom: 0px; }

.onHoverBlueLink {
  cursor: pointer; }
  .onHoverBlueLink:hover {
    color: #0093d0; }

.white-section {
  background: #fff;
  width: 100%;
  float: left;
  padding: 30px 40px;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-box-sizing: 5px;
  border-radius: 5px; }

.edit-blue-btn i {
  background: #0093d0;
  width: 24px;
  height: 24px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-box-sizing: 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer; }

.main-wraper .main-content.col-rightlayout .left-section {
  width: calc(100% - 345px);
  float: left;
  margin: 0; }
  .main-wraper .main-content.col-rightlayout .left-section > .col-grid2 > .col + .col {
    padding: 0; }
  .main-wraper .main-content.col-rightlayout .left-section > .col-grid2 > .col:first-child {
    padding-right: 15px; }
  .main-wraper .main-content.col-rightlayout .left-section > .col-grid1 {
    width: 100%;
    float: left;
    display: block; }
    .main-wraper .main-content.col-rightlayout .left-section > .col-grid1 > .col {
      margin-bottom: 15px;
      float: left;
      width: 100%; }

.main-wraper .main-content.col-rightlayout .right-section {
  max-width: 330px;
  width: 100%;
  margin: 0;
  float: right; }
  .main-wraper .main-content.col-rightlayout .right-section > .col-grid1 > .col {
    float: left; }

.card {
  width: 100%;
  float: left;
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative; }
  .card .card-head {
    width: 100%;
    float: left;
    margin: 0px 0 5px 0;
    min-height: 24px; }
    .card .card-head h2 {
      margin-top: 4px; }
      .card .card-head h2 em {
        font-style: normal;
        background: #fe6a2c;
        color: #fff;
        font-family: "latoregular", sans-serif;
        font-size: 14px;
        padding: 2px 7px;
        margin: 0 10px;
        line-height: 20px;
        border-radius: 20px;
        font-weight: normal; }
      .card .card-head h2 a {
        font-size: 15px;
        line-height: 17px;
        color: #000;
        text-transform: none;
        letter-spacing: 0.3px;
        font-family: "latoregular",sans-serif;
        font-weight: bold; }
        .card .card-head h2 a:hover {
          color: #0093d0; }
    .card .card-head i {
      float: left; }
      .card .card-head i:before {
        font-size: 19px;
        color: #0093d0;
        cursor: pointer; }
    .card .card-head button {
      float: left;
      margin-right: 20px; }
      .card .card-head button i:before {
        color: #fff;
        font-size: 12px; }
    .card .card-head .fsize12 {
      font-size: 12px;
      font-weight: normal;
      font-family: 'latobold'; }
    .card .card-head .price {
      color: #ff6c2f;
      font-family: 'latobold';
      font-size: 20px;
      margin: 0 0 0 10px; }
  .card .card-body {
    width: 100%;
    float: left;
    margin: 0px 0 10px 0;
    min-height: 340px; }
    .card .card-body p {
      font-size: 12px;
      color: #4a4a4a; }

.tabsTable {
  width: 100%;
  float: left;
  border: 1px solid #edeef0;
  border-radius: 4px; }
  .tabsTable .tabs {
    width: 100%;
    float: left;
    background: #f5f5f5;
    padding: 0 8px;
    border-bottom: 1px solid #edeef0; }
    .tabsTable .tabs ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex; }
      .tabsTable .tabs ul li {
        margin: 0;
        padding: 10px 0;
        flex: 1;
        text-align: center;
        font-size: 12px;
        color: #000;
        position: relative;
        cursor: pointer;
        transition: all .2s ease-in;
        max-width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        .tabsTable .tabs ul li em {
          display: block;
          font-style: normal;
          font-size: 18px;
          color: #000; }
        .tabsTable .tabs ul li.active {
          background: #0093d0;
          color: #fff; }
          .tabsTable .tabs ul li.active em {
            color: #fff; }
          .tabsTable .tabs ul li.active:before {
            content: '';
            position: absolute;
            bottom: -12px;
            left: 50%;
            margin-left: -4px;
            border: 6px solid transparent;
            border-top: 6px solid #0093d0; }
          .tabsTable .tabs ul li.active:hover {
            background: #0093d0;
            transition: all .2s ease-out; }
        .tabsTable .tabs ul li:hover {
          background: #50c4ed;
          color: #fff; }
          .tabsTable .tabs ul li:hover em {
            color: #fff; }
  .tabsTable .tabContentOuter {
    padding: 0 1px 0 0px; }
    .tabsTable .tabContentOuter .tabContent {
      width: 100%;
      float: left;
      padding-right: 15px; }
      .tabsTable .tabContentOuter .tabContent .row {
        width: 100%;
        padding: 12px 0 5px 10px;
        float: left;
        border-bottom: 1px solid #f5f5f7;
        display: block; }
        .tabsTable .tabContentOuter .tabContent .row .l1 {
          float: left;
          width: 100px;
          font-size: 12px;
          color: #7e7e7e;
          font-family: 'latobold';
          display: table-cell;
          line-height: 14px; }
          .tabsTable .tabContentOuter .tabContent .row .l1.wd75 {
            width: 75px; }
          .tabsTable .tabContentOuter .tabContent .row .l1 i {
            margin: 0 6px 0 0;
            vertical-align: top; }
            .tabsTable .tabContentOuter .tabContent .row .l1 i:before {
              font-size: 15px;
              vertical-align: middle;
              position: relative;
              top: -2px; }
          .tabsTable .tabContentOuter .tabContent .row .l1.green i:before {
            color: #8bc348; }
          .tabsTable .tabContentOuter .tabContent .row .l1 samp {
            display: inline-block;
            font-size: 12px;
            color: #7e7e7e;
            font-family: 'latobold';
            line-height: 14px; }
        .tabsTable .tabContentOuter .tabContent .row .r1 {
          float: left;
          font-size: 12px;
          display: table-cell;
          vertical-align: top;
          width: calc(100% - 110px);
          position: relative; }
          .tabsTable .tabContentOuter .tabContent .row .r1.rwd75 {
            width: calc(100% - 85px); }
          .tabsTable .tabContentOuter .tabContent .row .r1 strong {
            font-family: 'latobold';
            color: #000;
            font-weight: normal;
            line-height: 14px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          .tabsTable .tabContentOuter .tabContent .row .r1 p {
            font-size: 12px;
            color: #4a4a4a;
            padding-right: 0px !important;
            width: calc(100% - 20px); }
            .tabsTable .tabContentOuter .tabContent .row .r1 p span {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
            .tabsTable .tabContentOuter .tabContent .row .r1 p.taskList:hover {
              cursor: pointer; }
              .tabsTable .tabContentOuter .tabContent .row .r1 p.taskList:hover strong {
                color: #0093d0; }
          .tabsTable .tabContentOuter .tabContent .row .r1 i {
            position: absolute;
            right: 0px;
            top: 0px;
            cursor: pointer; }
            .tabsTable .tabContentOuter .tabContent .row .r1 i:before {
              font-size: 21px;
              color: #0093d0; }
            .tabsTable .tabContentOuter .tabContent .row .r1 i .tooltiptext {
              width: 54px;
              margin-left: -26px; }
        .tabsTable .tabContentOuter .tabContent .row:last-child {
          border-bottom: 0px; }
      .tabsTable .tabContentOuter .tabContent .errorMessage {
        padding: 10px;
        text-align: center; }
  .tabsTable.std .tabs {
    padding: 0; }
    .tabsTable.std .tabs ul li {
      border-right: 1px solid #edeef0;
      padding: 10px 0;
      max-width: inherit; }
      .tabsTable.std .tabs ul li:last-child {
        border-right: 0px; }
      .tabsTable.std .tabs ul li:hover {
        background: transparent;
        color: #000; }
        .tabsTable.std .tabs ul li:hover em {
          color: #000; }
  .tabsTable.std .tabContentOuter .tabContent {
    padding-right: 0; }
    .tabsTable.std .tabContentOuter .tabContent .row {
      padding: 0;
      display: flex;
      border-bottom: 1px solid #edeef0; }
      .tabsTable.std .tabContentOuter .tabContent .row .column {
        padding: 10px 0;
        flex: 1;
        text-align: center;
        border-right: 1px solid #edeef0;
        font-size: 12px; }
        .tabsTable.std .tabContentOuter .tabContent .row .column em {
          font-style: normal;
          font-size: 18px;
          display: block;
          color: #4a4a4a; }

.dataTable {
  width: 100%;
  float: left;
  margin: 15px 0 0;
  display: block;
  width: 100%; }
  .dataTable table {
    display: block;
    width: 100%; }
    .dataTable table thead {
      display: block;
      width: 100%; }
      .dataTable table thead tr {
        display: block;
        width: 100%;
        padding-right: 15px;
        float: left; }
        .dataTable table thead tr th {
          text-align: left;
          color: #9b9b9b;
          font-size: 12px;
          font-family: 'latobold';
          padding: 6px 8px;
          float: left; }
    .dataTable table tbody {
      display: block;
      width: 100%;
      padding-right: 15px; }
      .dataTable table tbody tr {
        display: block;
        width: 100%;
        float: left;
        background: #f5f5f5;
        border-bottom: 6px solid #fff;
        border-radius: 6px; }
        .dataTable table tbody tr td {
          text-align: left;
          color: #4a4a4a;
          font-size: 12px;
          font-family: "latoregular", sans-serif;
          padding: 6px 8px;
          float: left; }
          .dataTable table tbody tr td i:before {
            font-size: 16px; }
          .dataTable table tbody tr td .caret {
            font-size: 12px;
            font-family: 'latobold';
            color: #000; }
            .dataTable table tbody tr td .caret i {
              vertical-align: middle;
              margin-right: 4px;
              position: relative;
              top: -1px; }
            .dataTable table tbody tr td .caret.green i {
              color: #8bc348; }
          .dataTable table tbody tr td:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px; }

.tcell10 {
  width: 10%; }

.tcell20 {
  width: 20%; }

.tcell30 {
  width: 30%; }

.tcell40 {
  width: 40%; }

.tcell50 {
  width: 50%; }

.tcell60 {
  width: 60%; }

.tcell70 {
  width: 70%; }

.tcell80 {
  width: 80%; }

.tcell90 {
  width: 90%; }

.tcell100 {
  width: 100%; }

.listPerformance {
  margin: 0;
  padding: 0;
  list-style: none; }
  .listPerformance .tooltipWrap .tooltiptext {
    width: 100%;
    left: -100%;
    margin-left: -15px;
    top: 45%;
    transform: translateY(-40%);
    bottom: unset;
    z-index: 2; }
    .listPerformance .tooltipWrap .tooltiptext:after {
      content: "";
      position: absolute;
      bottom: 50%;
      left: 100%;
      margin-left: 0px;
      border-left: 5px solid black;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent; }
  .listPerformance li {
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #d8d8d8;
    float: left;
    width: 100%; }
    .listPerformance li .row {
      width: 100%;
      float: left; }
      .listPerformance li .row .left {
        width: 70%;
        float: left;
        font-size: 12px;
        font-family: "latoregular", sans-serif;
        color: #7e7e7e; }
        .listPerformance li .row .left strong {
          font-size: 14px;
          color: #000;
          font-weight: normal; }
          .listPerformance li .row .left strong.bold {
            font-family: 'latobold'; }
      .listPerformance li .row .right {
        width: 30%;
        float: left;
        text-align: right;
        color: #7e7e7e;
        font-family: 'latobold'; }
        .listPerformance li .row .right strong {
          font-family: 'latobold';
          font-size: 14px;
          font-weight: normal; }
          .listPerformance li .row .right strong i {
            margin-left: 5px; }
            .listPerformance li .row .right strong i:before {
              font-size: 16px; }
          .listPerformance li .row .right strong.green {
            color: #8bc348; }

.blockSection {
  width: 100%;
  float: left;
  padding: 5px 0px 0; }
  .blockSection .head {
    width: 100%;
    float: left;
    padding: 0px 0 12px; }
    .blockSection .head h2 {
      margin-top: 4px; }
      .blockSection .head h2 em {
        font-style: normal;
        background: #fe6a2c;
        color: #fff;
        font-family: "latoregular", sans-serif;
        font-size: 14px;
        padding: 2px 7px;
        margin: 0 10px;
        line-height: 20px;
        border-radius: 20px;
        font-weight: normal; }
    .blockSection .head .fsize12 {
      color: #4a4a4a;
      font-size: 12px; }
      .blockSection .head .fsize12 .text {
        margin-top: 12px; }
    .blockSection .head.switchSection {
      padding-top: 0; }
      .blockSection .head.switchSection h2 {
        margin-top: 12px; }
  .blockSection .body {
    width: 100%;
    float: left; }

.notificationList {
  margin: 0;
  padding: 0 15px 0 0;
  list-style: none;
  float: left;
  width: 100%; }
  .notificationList li {
    margin: 0 0 10px 0;
    padding: 8px 15px;
    background: #fff;
    width: 100%;
    float: left;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #fff;
    border-left: 5px solid #fff;
    height: 76px; }
    .notificationList li .listWrap {
      width: 100%;
      padding-right: 60px;
      position: relative; }
      .notificationList li .listWrap .tooltipWrap:hover .tooltiptext {
        display: block; }
      .notificationList li .listWrap .tooltiptext {
        bottom: inherit;
        width: 100%;
        left: 0;
        margin: 0px 0 0 0px;
        text-align: left;
        padding: 10px;
        display: none; }
        .notificationList li .listWrap .tooltiptext a {
          font-size: 10px; }
    .notificationList li .allow {
      width: 40px;
      height: 24px;
      border-radius: 30px;
      background: #ddd;
      display: inline-block;
      text-align: center;
      position: absolute;
      right: 2px;
      top: 17px;
      cursor: pointer; }
      .notificationList li .allow i:before {
        color: #9a9a9a;
        font-size: 20px;
        vertical-align: middle; }
      .notificationList li .allow:hover {
        background: #9a9a9a; }
        .notificationList li .allow:hover i:before {
          color: #ddd; }
    .notificationList li .time {
      color: #0093d0;
      display: block; }
    .notificationList li .note {
      display: block;
      font-size: 14px;
      color: #4a4a4a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%; }
    .notificationList li .grayTxt {
      color: #9b9b9b;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      width: 100%; }
      .notificationList li .grayTxt a {
        font-size: 10px;
        white-space: nowrap; }
    .notificationList li.yelllow {
      border: 1px solid #fff;
      border-left: 5px solid #f8e71c; }
      .notificationList li.yelllow:hover {
        border: 1px solid #f8e71c;
        border-left: 5px solid #f8e71c; }
    .notificationList li.green {
      border: 1px solid #fff;
      border-left: 5px solid #8bc34a; }
      .notificationList li.green:hover {
        border: 1px solid #8bc34a;
        border-left: 5px solid #8bc34a; }
    .notificationList li.red {
      border: 1px solid #FFF;
      border-left: 5px solid #ff0000; }
      .notificationList li.red:hover {
        border: 1px solid #ff0000;
        border-left: 5px solid #ff0000; }
    .notificationList li.blue {
      border: 1px solid #FFF;
      border-left: 5px solid #0093d0; }
      .notificationList li.blue:hover {
        border: 1px solid #0093d0;
        border-left: 5px solid #0093d0; }
    .notificationList li.neutral {
      border: 1px solid #ddd;
      border-left: 5px solid #ddd; }
      .notificationList li.neutral:hover {
        border: 1px solid #ddd;
        border-left: 5px solid #ddd; }
    .notificationList li.error {
      border: 1px solid #fe6a2c;
      border-left: 5px solid #fe6a2c; }
      .notificationList li.error:hover {
        border: 1px solid #fe6a2c;
        border-left: 5px solid #fe6a2c; }
    .notificationList li#slideRight {
      transition: all .5s;
      transform: translatex(105%); }
    .notificationList li:last-child {
      margin-bottom: 0; }
      .notificationList li:last-child .tooltiptext {
        bottom: 20px; }
        .notificationList li:last-child .tooltiptext:after {
          border-color: #000 transparent transparent transparent;
          bottom: -10px; }
  .notificationList.SFM li {
    margin-bottom: 8px; }
    .notificationList.SFM li:last-child {
      margin-bottom: 0; }
  .notificationList .notificationBy {
    float: left;
    width: 100%; }

.notificationBy {
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.dashboardBreadcrumb {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  min-height: 54px; }
  .dashboardBreadcrumb .left-section > .col-grid2 > .col {
    margin: 0; }
    .dashboardBreadcrumb .left-section > .col-grid2 > .col:first-child {
      width: 30%; }
    .dashboardBreadcrumb .left-section > .col-grid2 > .col:last-child {
      width: 70%; }
  .dashboardBreadcrumb .left-section .calendarData p {
    font-size: 12px;
    margin-right: 10px;
    color: #4a4a4a; }
    .dashboardBreadcrumb .left-section .calendarData p strong {
      font-family: 'latobold';
      font-weight: normal;
      color: #000; }
  .dashboardBreadcrumb .left-section .calendarData .zmdi-calendar {
    color: #0093d0;
    margin-top: 8px;
    float: left; }
  .dashboardBreadcrumb .left-section .calendarData .selectTeams {
    float: left;
    padding: 3px 0 0 10px; }
    .dashboardBreadcrumb .left-section .calendarData .selectTeams > div > div {
      border: 1px solid #9b9b9b; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams > div > div:before {
        border: 0; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams > div > div:after {
        border: 0; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams > div > div:hover:before {
        border: 0; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams > div > div:hover:after {
        border: 0; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams > div > div > div > div {
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 5px; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams > div > div em {
        font-size: 12px;
        font-style: normal;
        font-family: 'latobold'; }
    .dashboardBreadcrumb .left-section .calendarData .selectTeams .borderBtn span {
      font-size: 12px;
      font-style: normal;
      font-family: 'latobold'; }
    .dashboardBreadcrumb .left-section .calendarData .selectTeams .dateRangePopup,
    .dashboardBreadcrumb .left-section .calendarData .selectTeams .popup {
      top: 40px;
      width: 240px;
      z-index: 3; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams .dateRangePopup .buttonSet button,
      .dashboardBreadcrumb .left-section .calendarData .selectTeams .popup .buttonSet button {
        margin: 0 5px; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams .dateRangePopup .checkBoxWrap,
      .dashboardBreadcrumb .left-section .calendarData .selectTeams .popup .checkBoxWrap {
        width: 100%;
        float: left;
        margin: 0; }
      .dashboardBreadcrumb .left-section .calendarData .selectTeams .dateRangePopup fieldset,
      .dashboardBreadcrumb .left-section .calendarData .selectTeams .popup fieldset {
        padding: 5px 10px; }
  .dashboardBreadcrumb .right-section .col-grid2 > .col {
    padding: 0;
    margin: 0; }
    .dashboardBreadcrumb .right-section .col-grid2 > .col:first-child {
      border-right: 1px solid #ddd;
      padding-right: 6px; }
    .dashboardBreadcrumb .right-section .col-grid2 > .col:last-child {
      padding-left: 6px; }
    .dashboardBreadcrumb .right-section .col-grid2 > .col strong {
      font-family: 'latobold';
      font-weight: normal;
      color: #000;
      font-size: 11px;
      line-height: 16px !important;
      display: inline-block;
      width: 63%;
      float: left; }
    .dashboardBreadcrumb .right-section .col-grid2 > .col .f20 {
      font-size: 14px;
      font-family: 'latobold';
      font-weight: normal;
      float: left;
      margin-top: 1px; }

.popupFilterBtnOpen:before {
  font-size: 19px;
  color: #0093d0;
  cursor: pointer;
  border: 1px solid #eaeaea;
  padding: 0 3px;
  background: #fff;
  position: relative;
  z-index: 2;
  border-bottom: 0;
  border-radius: 3px; }

.popupFilterBtn:before {
  font-size: 19px;
  color: #0093d0;
  cursor: pointer;
  border: 1px solid #fff;
  padding: 0 3px;
  background: #fff;
  position: relative;
  z-index: 2;
  border-bottom: 0;
  border-radius: 3px; }

.popupFilter {
  width: 220px;
  min-height: 10px;
  position: absolute;
  right: -15px;
  top: 18px;
  background: #fff;
  box-shadow: 0px 1px 12px 3px #dddd;
  border-radius: 5px;
  z-index: 1;
  display: none;
  padding: 10px 0;
  color: #9b9b9b; }
  .popupFilter.open {
    display: block; }
  .popupFilter ul li {
    font-size: 12px;
    line-height: 16px;
    padding: 2px 10px; }
    .popupFilter ul li:hover {
      color: #ff6c2f; }

.sortBlock {
  width: 100%;
  float: left;
  padding: 10px 0 0 0; }
  .sortBlock .sort {
    display: inline-block;
    border: 1px solid #ddd;
    padding: 2px 6px;
    font-size: 12px;
    margin-right: 15px;
    border-radius: 4px;
    vertical-align: top;
    line-height: 20px;
    color: #4a4a4a;
    cursor: pointer;
    transition: all .2s; }
    .sortBlock .sort i {
      margin-right: 6px;
      position: relative;
      top: -1px;
      vertical-align: middle; }
      .sortBlock .sort i:before {
        font-size: 16px; }
  .sortBlock .sortUp {
    color: #96b56f;
    border-color: #96b56f; }
    .sortBlock .sortUp:hover {
      background: #96b56f;
      color: #fff;
      box-shadow: 1px 1px 5px #9a9a9a; }
    .sortBlock .sortUp:active {
      opacity: .8; }
    .sortBlock .sortUp.active {
      background: #96b56f;
      color: #fff;
      box-shadow: 1px 1px 5px #9a9a9a; }
  .sortBlock .sortDown {
    color: #ff6b6b;
    border-color: #ff6b6b; }
    .sortBlock .sortDown:hover {
      background: #ff6b6b;
      color: #fff;
      box-shadow: 1px 1px 5px #9a9a9a; }
    .sortBlock .sortDown:active {
      opacity: .8; }
    .sortBlock .sortDown.active {
      background: #ff6b6b;
      color: #fff;
      box-shadow: 1px 1px 5px #9a9a9a; }

.dashboardPopup.leadPopup {
  background: transparent; }
  .dashboardPopup.leadPopup .closeMain {
    left: inherit;
    right: 540px; }
    .dashboardPopup.leadPopup .closeMain:before {
      font-weight: bold; }
  .dashboardPopup.leadPopup .leadPopup-inn {
    left: calc(100% - 520px); }
    .dashboardPopup.leadPopup .leadPopup-inn .headSection {
      padding: 15px 15px 1px 15px; }
  .dashboardPopup.leadPopup.open + .overlay {
    display: block; }

.overlay {
  background: rgba(74, 78, 85, 0.95);
  box-shadow: inset 83px 1px 119px #504e4e;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 19;
  left: 0;
  top: 0;
  display: none; }

.taskListDetailGraySection .head {
  border-bottom: 2px solid #ddd;
  float: left;
  margin-bottom: 18px; }
  .taskListDetailGraySection .head > .col {
    font-size: 14px;
    font-family: 'latobold';
    color: #000;
    margin-bottom: 5px;
    padding: 0; }
  .taskListDetailGraySection .head > .col-t {
    font-size: 14px;
    font-family: 'latobold';
    color: #000;
    margin-bottom: 5px;
    padding: 0; }
    .taskListDetailGraySection .head > .col-t .grayTxt {
      color: #7e7e7e;
      font-size: 14px;
      line-height: 14px;
      display: block; }
  .taskListDetailGraySection .head .orange {
    color: #fe6a2c !important; }
  .taskListDetailGraySection .head .green {
    color: #8bc348 !important; }
  .taskListDetailGraySection .head .red {
    color: #ff0000 !important; }

.taskListDetailGraySection .content p {
  font-size: 14px;
  margin-bottom: 5px; }
  .taskListDetailGraySection .content p strong {
    font-family: 'latobold';
    font-weight: normal;
    color: #000;
    line-height: 22px; }
    .taskListDetailGraySection .content p strong.grayTxt {
      color: #7e7e7e; }
  .taskListDetailGraySection .content p.email {
    color: #0093d0;
    font-family: 'latobold';
    font-size: 14px; }
    .taskListDetailGraySection .content p.email a {
      color: #0093d0;
      font-family: 'latobold';
      font-size: 14px; }
  .taskListDetailGraySection .content p.notetxt {
    color: #7e7e7e;
    font-size: 14px;
    margin-top: 6px; }
  .taskListDetailGraySection .content p i {
    margin-right: 5px; }
    .taskListDetailGraySection .content p i:before {
      font-size: 16px;
      color: #0093d0;
      vertical-align: middle; }

.createTaskForm fieldset {
  border: 0;
  border-bottom: 2px solid #ddd;
  max-width: 100%;
  margin: 0;
  padding: 15px 0 0 0; }
  .createTaskForm fieldset .checkBoxWrap {
    margin-bottom: 0px; }
  .createTaskForm fieldset .col-grid2 > .col:last-child {
    padding-left: 15px; }
  .createTaskForm fieldset .col-grid2 > .col:first-child {
    padding-right: 15px; }

.createTaskForm .buttonSet button span {
  text-transform: none;
  font-size: 15px;
  font-family: 'latobold'; }

.formSelectBoxNotImp .formSelectBoxImp label {
  padding-left: 0px; }

.dateRangePopup {
  position: absolute;
  right: -10px;
  top: 38px;
  background: #fff;
  box-shadow: -1px 6px 11px #d4d2d2;
  width: 420px;
  z-index: 3;
  border-radius: 8px;
  text-align: left; }
  .dateRangePopup .zmdi-close {
    position: absolute;
    right: 0;
    top: 10px;
    right: 10px;
    cursor: pointer; }
    .dateRangePopup .zmdi-close:before {
      font-size: 20px; }
  .dateRangePopup.open {
    opacity: 1;
    visibility: visible; }
  .dateRangePopup.close {
    opacity: 0;
    visibility: hidden; }
  .dateRangePopup:before {
    border: 7px solid transparent;
    border-bottom: 7px solid #fff;
    content: '';
    position: absolute;
    top: -14px;
    right: 33%; }
  .dateRangePopup fieldset {
    border: 0;
    padding: 15px 30px; }
    .dateRangePopup fieldset .col-grid1 {
      margin-bottom: 15px;
      float: left;
      width: 100%; }
    .dateRangePopup fieldset .col-grid2 {
      margin-bottom: 15px; }
      .dateRangePopup fieldset .col-grid2 > .col:first-child {
        padding-right: 15px; }
      .dateRangePopup fieldset .col-grid2 > .col:last-child {
        padding-left: 15px; }
      .dateRangePopup fieldset .col-grid2 > .col.datePicker .calendar-icon {
        top: -4px;
        right: 15px; }
      .dateRangePopup fieldset .col-grid2 > .col.datePicker.last .calendar-icon {
        right: 0; }
    .dateRangePopup fieldset.dateRange {
      border-bottom: 1px solid #ddd; }
    .dateRangePopup fieldset .timePeriod > div > div > div {
      margin-top: 8px !important; }
    .dateRangePopup fieldset .checkBoxWrap {
      margin-bottom: 0px; }

.customDropDownField > label {
  float: left;
  color: #4a4a4a;
  border-bottom: 4px solid #4a4a4a;
  margin-top: 18px;
  margin-right: 25px;
  margin-bottom: 0px; }

.customDropDownField > div {
  float: left;
  width: 190px; }
  .customDropDownField > div > div > div {
    border: 1px solid #9b9b9b !important;
    border-bottom: 0px !important;
    border-radius: 3px; }
    .customDropDownField > div > div > div:after {
      border-color: #9b9b9b !important; }
    .customDropDownField > div > div > div div[role="button"] {
      padding-left: 10px; }
    .customDropDownField > div > div > div svg {
      right: 0px !important; }

.labelHighlight {
  background: #c9c9c9;
  border-bottom: 1px solid #9b9b9b;
  color: #4a4a4a;
  padding: 4px 12px;
  float: left;
  width: 100%;
  font-size: 14px; }

.wdAuto {
  width: auto !important; }

.collapse-head {
  width: 100%;
  float: left;
  padding: 10px 0;
  border-bottom: 1px solid #ddd; }
  .collapse-head h2 {
    float: left;
    margin-top: 4px; }
  .collapse-head .collapsible {
    float: right; }

.collapse-body {
  width: 100%;
  float: left; }
  .collapse-body fieldset {
    padding: 0;
    margin: 0; }

.allowCircle {
  width: 40px;
  height: 24px;
  border-radius: 30px;
  background: #ddd;
  display: inline-block;
  text-align: center;
  margin: 10px 0 0 10px;
  cursor: pointer; }
  .allowCircle i {
    margin: 0px !important; }
    .allowCircle i:before {
      color: #9a9a9a;
      font-size: 20px !important;
      vertical-align: middle;
      top: 2px !important; }
  .allowCircle:hover {
    background: #9a9a9a; }
    .allowCircle:hover i:before {
      color: #ddd; }

.orangTxt22size {
  font-size: 22px;
  color: #ff6c2f !important; }

.counterBox {
  font-style: normal;
  background: #fe6a2c;
  color: #fff;
  font-family: "latoregular", sans-serif;
  font-size: 14px;
  padding: 1px 5px;
  line-height: 14px !important;
  margin: 2px 0px;
  line-height: 20px;
  border-radius: 20px;
  font-weight: normal;
  display: inline-block; }

form[name="FilterDashboardTeamsAgentsForm"] fieldset {
  float: left;
  width: 100%; }
  form[name="FilterDashboardTeamsAgentsForm"] fieldset .checkBoxWrap span:first-child {
    height: 40px;
    width: 40px; }
  form[name="FilterDashboardTeamsAgentsForm"] fieldset .checkBoxWrap span + span {
    font-size: 13px; }

form[name="FilterDashboardTeamsAgentsForm"] .buttonSet {
  float: left;
  width: 100%; }

.loaderWraper {
  position: relative; }
  .loaderWraper .section-loader {
    position: absolute;
    height: 100%;
    top: 0px;
    z-index: 1; }

.fsize15 {
  font-size: 15px !important;
  line-height: 15px !important; }

.scroll-container.addCustomer, .scroll-container.addContract {
  height: calc(100vh - 221px);
  width: 100%;
  float: left;
  padding-right: 30px;
  padding-left: 30px; }
  .scroll-container.addCustomer fieldset .title-head, .scroll-container.addContract fieldset .title-head {
    margin-top: 10px; }
    .scroll-container.addCustomer fieldset .title-head.std2, .scroll-container.addContract fieldset .title-head.std2 {
      margin-bottom: 20px; }
    .scroll-container.addCustomer fieldset .title-head.std3, .scroll-container.addContract fieldset .title-head.std3 {
      margin-bottom: 30px; }
    .scroll-container.addCustomer fieldset .title-head.std4, .scroll-container.addContract fieldset .title-head.std4 {
      margin-bottom: 40px; }
      .scroll-container.addCustomer fieldset .title-head.std4 h1, .scroll-container.addContract fieldset .title-head.std4 h1 {
        padding-top: 5px; }
  .scroll-container.addCustomer fieldset.row-margin-b20 .col-grid2, .scroll-container.addContract fieldset.row-margin-b20 .col-grid2 {
    margin-bottom: 20px; }
  .scroll-container.addCustomer fieldset.row-checkbox, .scroll-container.addContract fieldset.row-checkbox {
    padding-top: 10px; }
    .scroll-container.addCustomer fieldset.row-checkbox label, .scroll-container.addContract fieldset.row-checkbox label {
      margin-bottom: 0; }
      .scroll-container.addCustomer fieldset.row-checkbox label > span, .scroll-container.addContract fieldset.row-checkbox label > span {
        height: 34px; }
        .scroll-container.addCustomer fieldset.row-checkbox label > span + span, .scroll-container.addContract fieldset.row-checkbox label > span + span {
          height: auto;
          font-size: 14px; }
  .scroll-container.addCustomer .equelWdRadio .radioGroupWrap .radioGroup label, .scroll-container.addContract .equelWdRadio .radioGroupWrap .radioGroup label {
    min-width: 100px; }

.scroll-container.negotiated-lane {
  height: calc(100vh - 294px) !important; }

.bdr0 {
  border: 0 !important; }

.custom-select .formControl {
  margin-top: 4px;
  margin-bottom: 7px; }

.requiredClass div:nth-child(2):before {
  border-left: 2px solid #ff6868; }

.checkbox .checkbox-lbl {
  margin-top: 0; }
  .checkbox .checkbox-lbl .checkBoxWrap {
    margin-bottom: 0; }

fieldset.disabled {
  pointer-events: none;
  opacity: 0.4; }

.title-head {
  padding: 10px 0 6px 0;
  min-height: 45px; }
  .title-head h2 {
    float: left; }

fieldset.tableFieldset .title-head {
  padding: 10px 0 6px 0;
  min-height: 45px; }
  fieldset.tableFieldset .title-head h2 {
    float: left; }
    fieldset.tableFieldset .title-head h2 i {
      margin-left: 5px; }
      fieldset.tableFieldset .title-head h2 i:before {
        color: #9b9b9b;
        font-size: 15px; }
      fieldset.tableFieldset .title-head h2 i.tooltipWrap .tooltiptext {
        bottom: inherit;
        top: 22px;
        margin-left: -121px;
        width: 240px;
        padding: 10px; }
  fieldset.tableFieldset .title-head button {
    min-width: inherit;
    padding: 0 15px !important;
    min-height: 28px; }
    fieldset.tableFieldset .title-head button span {
      font-size: 14px;
      font-family: 'latoregular';
      font-weight: normal !important;
      text-transform: none; }
    fieldset.tableFieldset .title-head button i:before {
      font-size: 12px;
      margin-right: 5px; }

.tbl-data {
  width: 100%;
  border: 1px solid #e6eaee;
  border-bottom: 0; }
  .tbl-data thead tr {
    background: #f5f5f5; }
    .tbl-data thead tr td {
      padding: 10px 1px 10px 15px;
      font-family: 'latobold';
      font-size: 14px;
      border-bottom: 1px solid #e6eaee;
      color: #9b9b9b; }
      .tbl-data thead tr td i {
        vertical-align: middle;
        margin: 0 5px;
        cursor: pointer; }
        .tbl-data thead tr td i:before {
          font-size: 30px; }
        .tbl-data thead tr td i.zmdi-close {
          background: #9b9b9b;
          padding: 0 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-box-sizing: 4px;
          border-radius: 4px;
          padding: 3px 6px !important; }
          .tbl-data thead tr td i.zmdi-close:before {
            color: #fff;
            font-size: 18px; }
        .tbl-data thead tr td i.zmdi-edit {
          background: #9b9b9b;
          padding: 0 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-box-sizing: 4px;
          border-radius: 4px;
          padding: 3px 6px !important; }
          .tbl-data thead tr td i.zmdi-edit:before {
            color: #fff;
            font-size: 18px; }
        .tbl-data thead tr td i.zmdi-check {
          background: #0093d0;
          padding: 0 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-box-sizing: 4px;
          border-radius: 4px;
          padding: 3px 5px !important; }
          .tbl-data thead tr td i.zmdi-check:before {
            color: #fff;
            font-size: 18px; }
      .tbl-data thead tr td .collapsible i:before {
        font-size: 24px; }
      .tbl-data thead tr td .sort {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        padding-right: 20px;
        display: inline-block; }
        .tbl-data thead tr td .sort i {
          position: absolute;
          right: 0;
          top: 8px; }
          .tbl-data thead tr td .sort i:before {
            font-size: 14px;
            color: #525252; }
        .tbl-data thead tr td .sort img {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
          cursor: pointer; }
      .tbl-data thead tr td div {
        font-family: 'latobold';
        font-size: 14px; }
  .tbl-data tbody tr td {
    padding: 10px 1px 10px 15px;
    font-family: 'latoregular';
    font-size: 12px;
    border-bottom: 1px solid #e6eaee;
    color: #4a4a4a;
    word-wrap: break-word;
    vertical-align: top; }
    .tbl-data tbody tr td i {
      vertical-align: middle;
      margin-right: 5px; }
      .tbl-data tbody tr td i:before {
        font-size: 16px; }
    .tbl-data tbody tr td .sort i:before {
      font-size: 14px;
      color: #525252; }
    .tbl-data tbody tr td .zmdi-close-circle:before {
      color: #9b9b9b; }
    .tbl-data tbody tr td div {
      font-family: 'latobold';
      font-size: 14px; }
    .tbl-data tbody tr td .sortImg {
      display: inline-block;
      vertical-align: middle;
      margin-left: 6px; }
    .tbl-data tbody tr td .date {
      display: block;
      font-size: 12px;
      line-height: 14px; }
    .tbl-data tbody tr td .time {
      display: block;
      font-size: 10px;
      line-height: 14px;
      color: #7e7e7e; }
    .tbl-data tbody tr td.action-icon i {
      margin: 0 5px; }

.round-input > div > div:before {
  border-bottom: 0px solid #9b9b9b; }

.round-input > div > div:after {
  border-bottom: 1px solid #0093d0;
  transform-origin: 0 0; }

.round-input > div > div:hover:before {
  border-bottom: 1px solid #0093d0 !important; }

.round-input > div > div input {
  background: #fff;
  border: 1px solid #f4f4f6 !important;
  padding: 10px 14px !important;
  font-size: 12px !important;
  color: #4a4a4a !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-box-sizing: 3px;
  border-radius: 3px; }
  .round-input > div > div input::placeholder {
    color: #4a4a4a;
    opacity: 1; }
  .round-input > div > div input:-ms-input-placeholder {
    color: #4a4a4a; }
  .round-input > div > div input::-ms-input-placeholder {
    color: #4a4a4a; }

.round-select > div:before {
  border: 0px; }

.round-select > div > div > div {
  border: 1px solid #f4f4f6;
  padding: 10px 30px 10px 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-box-sizing: 3px;
  border-radius: 3px;
  background: #fff; }
  .round-select > div > div > div em {
    font-size: 12px !important;
    color: #4a4a4a !important;
    font-style: normal; }
  .round-select > div > div > div:focus {
    background: #fff; }

.round-select > div:after {
  border-bottom: 1px solid #0093d0 !important;
  transform-origin: 0 0; }

.round-select > div:hover:before {
  border-bottom: 0px solid #0093d0 !important; }

.DialogBox {
  z-index: 10001; }
  .DialogBox fieldset {
    border: 0;
    padding: 0;
    margin: 0; }
  .DialogBox #scroll-dialog-title {
    padding: 24px 30px 20px; }
    .DialogBox #scroll-dialog-title h2 {
      font-size: 18px;
      font-family: 'latobold';
      color: #000;
      font-weight: normal; }
  .DialogBox .DialogBoxcontent {
    padding: 0px 0px 0px; }
    .DialogBox .DialogBoxcontent fieldset {
      padding: 20px 30px; }
      .DialogBox .DialogBoxcontent fieldset.row-margin-b20 .col-grid2 {
        margin-bottom: 0px; }
      .DialogBox .DialogBoxcontent fieldset.graybg {
        background: #f9f9f9; }
  .DialogBox .DialogBoxfooter {
    text-align: center;
    justify-content: center;
    margin: 20px 4px;
    margin-top: 0; }
    .DialogBox .DialogBoxfooter button {
      float: none; }
  .DialogBox.small > div[role="document"] {
    max-width: 450px;
    width: 100%; }
  .DialogBox.small #alert-dialog-title {
    border-bottom: 1px solid #ddd;
    background: #0093d0;
    padding: 15px 24px 15px 24px; }
    .DialogBox.small #alert-dialog-title h2 {
      color: #fff; }
  .DialogBox.small .DialogTitle {
    padding-bottom: 0px;
    padding-left: 30px; }
    .DialogBox.small .DialogTitle h2 {
      font-family: 'latobold';
      font-weight: normal !important;
      font-size: 19px;
      color: #000; }
      .DialogBox.small .DialogTitle h2 i:before {
        font-size: 22px;
        margin-right: 8px; }
  .DialogBox.small .DialogBoxcontent {
    padding-bottom: 10px; }
    .DialogBox.small .DialogBoxcontent .col {
      color: #4a4a4a; }
  .DialogBox.small .DialogBoxfooter {
    background: #fff;
    border-top: 2px solid #ececec;
    margin: 0;
    padding: 16px; }
    .DialogBox.small .DialogBoxfooter button {
      min-width: 133px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      text-align: center; }
  .DialogBox.medium > div[role="document"] {
    max-width: 660px;
    width: 100%; }
  .DialogBox.medium #scroll-dialog-title {
    border-bottom: 1px solid #ddd;
    background: #f8f8f8; }
  .DialogBox.large > div[role="document"] {
    max-width: 960px;
    width: 100%; }
  .DialogBox.large #scroll-dialog-title {
    border-bottom: 1px solid #ddd;
    background: #f8f8f8; }
  .DialogBox.xlarge > div[role="document"] {
    max-width: 1060px;
    width: 100%; }
  .DialogBox.xlarge #scroll-dialog-title {
    border-bottom: 1px solid #ddd;
    background: #f8f8f8; }
  .DialogBox.xlarge2 > div[role="document"] {
    max-width: 1200px;
    width: 100%; }
  .DialogBox.xlarge2 #scroll-dialog-title {
    border-bottom: 1px solid #ddd;
    background: #f8f8f8; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] {
  background: transparent !important; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  padding-left: 0; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] {
  padding-left: 0 !important;
  background: transparent !important; }

.selectSingle.requiredClass .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] span {
  padding-left: 12px; }

.tags-section {
  width: 100%;
  padding: 8px 0; }
  .tags-section ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .tags-section ul li {
      margin: 0 10px 0 0;
      padding: 0;
      display: inline-block;
      vertical-align: top;
      font-family: 'latobold';
      font-size: 12px;
      letter-spacing: 0.1px;
      color: #9b9b9b;
      background: #fff;
      padding: 4px 10px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-box-sizing: 4px;
      border-radius: 4px; }
      .tags-section ul li em {
        font-style: normal;
        font-family: 'latobold';
        font-size: 12px;
        letter-spacing: 0.1px;
        color: #0093d0; }

.content-panel {
  width: 100%;
  background: #fff;
  padding: 0px;
  margin: 12px 0 0 0;
  float: left;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-box-sizing: 4px;
  border-radius: 4px; }

.fixed-head-sep {
  padding: 30px 30px 10px 30px; }

.gray-icon:before {
  color: #949494 !important; }

.NegotiatedLink {
  margin-top: 12px !important;
  display: inline-block;
  line-height: 20px;
  cursor: pointer; }

.userList.negotiated-lane {
  padding: 0; }
  .userList.negotiated-lane .negotiatedlist-head .col.name {
    width: 32%;
    padding-left: 30px; }
  .userList.negotiated-lane .negotiatedlist-head .col.excluded {
    width: 17%; }
  .userList.negotiated-lane .negotiatedlist-head .col.status {
    width: 17%; }
  .userList.negotiated-lane .negotiatedlist-head .col.created {
    width: 17%; }
  .userList.negotiated-lane .negotiatedlist-head .col.modified {
    width: 17%; }
  .userList.negotiated-lane .negotiatedlist-head .col i {
    cursor: pointer; }
    .userList.negotiated-lane .negotiatedlist-head .col i:hover::before {
      color: #0093d0; }
  .userList.negotiated-lane .listItem li .col.name {
    width: 32%; }
    .userList.negotiated-lane .listItem li .col.name h5 {
      margin-top: 14px;
      padding-left: 30px; }
  .userList.negotiated-lane .listItem li .col.excluded {
    width: 17%; }
    .userList.negotiated-lane .listItem li .col.excluded span {
      margin-top: 14px; }
  .userList.negotiated-lane .listItem li .col.status {
    width: 17%; }
    .userList.negotiated-lane .listItem li .col.status .userStatus {
      margin-top: 14px; }
  .userList.negotiated-lane .listItem li .col.created {
    width: 17%; }
    .userList.negotiated-lane .listItem li .col.created .userCreated {
      margin-top: 8px;
      width: 100px;
      color: #7e7e7e; }
      .userList.negotiated-lane .listItem li .col.created .userCreated::first-line {
        color: #4a4a4a; }
  .userList.negotiated-lane .listItem li .col.modified {
    width: 17%; }
    .userList.negotiated-lane .listItem li .col.modified .userModifyed {
      margin-top: 8px;
      width: 100px;
      color: #7e7e7e; }
      .userList.negotiated-lane .listItem li .col.modified .userModifyed::first-line {
        color: #4a4a4a; }
  .userList.negotiated-lane .listItem li .col span {
    font-size: 12px;
    display: inline-block; }

.tags-section {
  width: 100%;
  padding: 0px 0 8px; }
  .tags-section ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .tags-section ul li {
      margin: 0 10px 0 0;
      padding: 0;
      display: inline-block;
      vertical-align: top;
      font-family: 'latobold';
      font-size: 12px;
      letter-spacing: 0.1px;
      color: #9b9b9b;
      background: #fff;
      padding: 4px 10px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-box-sizing: 4px;
      border-radius: 4px; }
      .tags-section ul li em {
        font-style: normal;
        font-family: 'latobold';
        font-size: 12px;
        letter-spacing: 0.1px;
        color: #0093d0; }

form[name="NogotiatedLanesForm"] {
  background: #fff;
  padding: 20px; }

.small-btn {
  min-width: inherit;
  padding: 0 25px !important;
  min-height: 28px !important;
  white-space: nowrap; }
  .small-btn span {
    font-size: 14px;
    font-family: 'latoregular';
    font-weight: normal !important;
    text-transform: none; }
  .small-btn i:before {
    font-size: 15px;
    margin-right: 5px; }

.small-btn1 {
  min-width: inherit !important;
  padding: 0 15px !important;
  min-height: 28px !important; }
  .small-btn1 span {
    font-size: 14px;
    font-family: 'latoregular';
    font-weight: normal !important;
    text-transform: none; }
  .small-btn1 i:before {
    font-size: 15px;
    margin-right: 5px; }

.xsmall-btn {
  min-width: inherit !important;
  padding: 0 12px !important;
  min-height: 24px !important; }
  .xsmall-btn span {
    font-size: 10px;
    font-family: 'latobold';
    font-weight: normal !important;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .xsmall-btn i:before {
    font-size: 15px;
    margin-right: 5px; }

.cursorPointer {
  cursor: pointer; }

.main-wraper .main-content .left-section.contract {
  width: 240px; }

.main-wraper .main-content .right-section.contract {
  margin-left: 250px; }

.form-inputFieldrdonly textarea {
  height: auto;
  min-height: auto;
  max-height: inherit;
  margin: 0;
  padding: 0 !important; }

.viewport-block {
  clear: both;
  height: 1px; }
  .viewport-block h3 {
    margin: 0;
    padding: 0;
    font-size: 2px; }
  .viewport-block div {
    background: transparent !important; }

.main-content .contract-outer {
  transform: translate(0, 0px); }
  .main-content .contract-outer.move-left {
    transform: translate(-104%, 0px);
    transition: all .5s; }

.main-content .NegotiatedLanes-close {
  transform: translate(-104%, 0);
  transition: all .5s; }

.main-content .NegotiatedLanes-open {
  /*transform: translate(0%, 0);*/
  transition: all .5s; }

#addd {
  display: none; }

.userList.myCustomer .listItem li .flBox .col {
  margin-bottom: 0;
  padding-right: 0px; }

.userList.myCustomer .listItem li .flBox .col i:before {
  font-size: 17px;
  color: #9b9b9b; }

.userList.myCustomer .listItem li .flBox .col.pl10 {
  padding-left: 10px !important; }

.filterBar .gridFilterHead div {
  padding-right: 0;
  margin-bottom: 0; }

.filterBar .gridFilterHead div.pl10 {
  padding-left: 10px !important; }

.globalDiscount {
  width: 100% !important;
  overflow: inherit !important; }

.blockTable {
  width: 100%;
  float: left;
  border: 1px solid #e6eaee; }
  .blockTable .row {
    width: 100%;
    float: left; }
    .blockTable .row.head {
      background: #f5f5f5; }
      .blockTable .row.head > div {
        display: block;
        width: 20%;
        float: left;
        height: 56px;
        padding: 10px 10px;
        font-family: 'latobold';
        font-size: 14px;
        border-bottom: 1px solid #e6eaee;
        color: #9b9b9b; }
      .blockTable .row.head.col6 > div {
        width: 16.6%; }
    .blockTable .row.body {
      width: 100%;
      float: left;
      overflow: hidden;
      transition: all 0.5s; }
      .blockTable .row.body .row {
        width: 100% !important;
        float: left;
        border-bottom: 1px solid #e6eaee; }
        .blockTable .row.body .row > div {
          display: block;
          width: 20%;
          float: left;
          height: 58px;
          padding: 10px 10px;
          font-family: 'latoregular';
          font-size: 12px; }
          .blockTable .row.body .row > div i:before {
            font-size: 16px; }
        .blockTable .row.body .row.col6 > div {
          width: 16.6%;
          padding-top: 3px;
          padding-bottom: 0;
          height: auto; }
          .blockTable .row.body .row.col6 > div lavel {
            margin: 0; }
          .blockTable .row.body .row.col6 > div input {
            text-align: center; }

.collapsible i {
  width: 26px;
  height: 26px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: top;
  border-radius: 3px;
  cursor: pointer;
  background: #fff; }
  .collapsible i:before {
    font-size: 24px; }

.infoIconField {
  position: relative; }
  .infoIconField .infoIcon {
    position: absolute;
    top: 19px;
    right: 32px; }
    .infoIconField .infoIcon button {
      border: 0;
      background: #fff; }
      .infoIconField .infoIcon button i:before {
        color: #9b9b9b;
        font-size: 15px; }
      .infoIconField .infoIcon button i .tooltiptext {
        bottom: inherit;
        top: 21px;
        width: 240px;
        margin-left: -120px; }
    .infoIconField .infoIcon.viewmode {
      top: -2px; }

.NegotiatedLanes-open {
  width: 100%;
  float: left; }

.userList.negotiated-lane .listItem {
  height: calc(100vh - 232px);
  width: 100%;
  float: left; }

.keep-all {
  word-break: keep-all !important; }

.marTopUp {
  margin-top: -21px !important;
  background: #ffff !important;
  float: left;
  width: 100%; }

i.white:before {
  color: #fff; }

.listItem-custom {
  position: absolute;
  background: #fff;
  z-index: 10;
  border: 1px solid #ddd;
  width: 100%;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  max-height: 150px;
  overflow: auto; }
  .listItem-custom li {
    padding: 5px 10px;
    color: #4a4a4a;
    font-size: 14px; }

.tbl-data tbody tr td .formSelectBoxImp label + div {
  margin-top: 0; }

.scroll-container.addContract.facilityType {
  height: calc(100vh - 177px); }

/* start Application Settings -- Loading Facility -- Add Facility type */
.multiSelectFieldCustom .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] {
  display: block; }

.multiSelectFieldCustom .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] span {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-align: left; }

.multiSelectFieldCustom .formSelectBoxImp.formControl div > div > div[aria-pressed] {
  padding-right: 15px !important; }

/* end Application Settings -- Loading Facility -- Add Facility type */
.liabilityCoverageClass > div {
  width: auto;
  display: inline-block;
  margin-left: 4px; }

.tableFieldset #accesorial .tbl-data {
  margin-top: 15px; }

.userList.myCustomer.heightAuto .listItem li .flBox .col > span.counterNumber2 {
  max-width: inherit; }

.userList.myCustomer {
  padding: 0; }
  .userList.myCustomer .listItem li .col {
    line-height: 40px; }
    .userList.myCustomer .listItem li .col h5 span {
      margin-top: 15px; }
    .userList.myCustomer .listItem li .col span {
      font-family: 'latoregular';
      font-size: 12px; }
    .userList.myCustomer .listItem li .col.checkbox {
      width: 4%; }
      .userList.myCustomer .listItem li .col.checkbox > span {
        height: 40px;
        width: 40px; }
    .userList.myCustomer .listItem li .col.userDate {
      line-height: 16px; }
      .userList.myCustomer .listItem li .col.userDate .date {
        color: #7e7e7e;
        width: 100px;
        display: block; }
        .userList.myCustomer .listItem li .col.userDate .date::first-line {
          color: #000; }
    .userList.myCustomer .listItem li .col.Email .ellipsis,
    .userList.myCustomer .listItem li .col.Email .email {
      width: 100%;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .userList.myCustomer.heightAuto .listItem li {
    float: left;
    width: 100%; }
    .userList.myCustomer.heightAuto .listItem li .flBox {
      height: auto; }
      .userList.myCustomer.heightAuto .listItem li .flBox .col {
        line-height: 14px;
        padding-right: 0px;
        /*display: table;*/ }
        .userList.myCustomer.heightAuto .listItem li .flBox .col > span {
          padding-top: 7px;
          padding-bottom: 6px;
          padding-right: 10px;
          display: inline-block;
          white-space: normal;
          word-wrap: break-word;
          display: table-cell;
          height: 40px;
          vertical-align: middle;
          word-break: break-word; }
          .userList.myCustomer.heightAuto .listItem li .flBox .col > span.roleName {
            color: #000; }
        .userList.myCustomer.heightAuto .listItem li .flBox .col .userStatus {
          padding: 0 0 0 12px;
          margin-top: 14px;
          display: inline-block;
          height: auto; }
        .userList.myCustomer.heightAuto .listItem li .flBox .col h5 {
          display: table-cell;
          height: 40px;
          margin-top: 0;
          padding-top: 7px;
          padding-bottom: 6px;
          vertical-align: middle; }
          .userList.myCustomer.heightAuto .listItem li .flBox .col h5 span {
            width: 100%;
            white-space: normal;
            margin: 0;
            word-wrap: break-word;
            word-break: break-word;
            padding-right: 10px; }
            .userList.myCustomer.heightAuto .listItem li .flBox .col h5 span.nameNowrap {
              word-break: break-word; }
            .userList.myCustomer.heightAuto .listItem li .flBox .col h5 span.noLink {
              color: #000;
              cursor: default; }
        .userList.myCustomer.heightAuto .listItem li .flBox .col.checkbox > span {
          display: flex; }
        .userList.myCustomer.heightAuto .listItem li .flBox .col.userDate .date {
          width: 82px; }
      .userList.myCustomer.heightAuto .listItem li .flBox .userDateCol {
        width: 10.33%; }
      .userList.myCustomer.heightAuto .listItem li .flBox .contactCell {
        display: block !important;
        padding-top: 5px !important; }
        .userList.myCustomer.heightAuto .listItem li .flBox .contactCell samp {
          display: block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .userList.myCustomer.heightAuto .listItem li .flBox .contactCell samp + samp {
            color: #7e7e7e; }
          .userList.myCustomer.heightAuto .listItem li .flBox .contactCell samp:first-child {
            margin-bottom: 3px; }
        .userList.myCustomer.heightAuto .listItem li .flBox .contactCell .tooltiptext {
          left: 0;
          margin: 0;
          bottom: inherit;
          top: 38px;
          width: 190px;
          text-align: left;
          padding: 6px 10px;
          line-height: 18px; }

fieldset#Marketing .col-grid2 .col .marketting-left-section {
  max-width: 320px;
  text-align: center; }

fieldset#Marketing .col-grid2 .col .imageFrame {
  border: 1px solid #ddd;
  background: #eaeaea;
  min-height: 200px;
  margin-bottom: 7px;
  padding: 5px; }
  fieldset#Marketing .col-grid2 .col .imageFrame .zoom {
    width: 28px;
    height: 28px;
    border: 1px solid #d8d8d8;
    background: #fff;
    display: inline-block;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer; }
    fieldset#Marketing .col-grid2 .col .imageFrame .zoom i {
      margin-top: 3px; }
      fieldset#Marketing .col-grid2 .col .imageFrame .zoom i:before {
        font-size: 22px;
        color: #4a4a4a; }
    fieldset#Marketing .col-grid2 .col .imageFrame .zoom.zoom-in {
      right: 10px; }
    fieldset#Marketing .col-grid2 .col .imageFrame .zoom:hover {
      background: #d8d8d8; }
      fieldset#Marketing .col-grid2 .col .imageFrame .zoom:hover i:before {
        color: #fff; }

fieldset#Marketing .col-grid2 .col .imageFramePaginations .floatRight button {
  padding: 4px 20px !important;
  min-height: 30px;
  min-width: inherit;
  cursor: pointer;
  margin: 0 0 0 10px; }
  fieldset#Marketing .col-grid2 .col .imageFramePaginations .floatRight button span {
    font-family: 'latoregular';
    font-weight: normal !important;
    font-size: 14px;
    letter-spacing: .5px;
    text-transform: none; }
    fieldset#Marketing .col-grid2 .col .imageFramePaginations .floatRight button span i:before {
      font-size: 12px;
      margin-right: 5px; }

.withSearch {
  position: relative; }
  .withSearch .col {
    width: 100%;
    padding-right: 40px; }
  .withSearch i {
    position: absolute;
    right: 4px;
    top: 13px;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px; }
    .withSearch i .tooltiptext {
      width: 84px;
      margin-left: -42px; }

.buttonSet {
  margin: 20px 0 0; }
  .buttonSet button {
    margin-right: 20px; }

.list-section {
  width: calc(100% + 15px);
  float: left;
  margin: 10px 0 0 0; }
  .list-section .button-set {
    padding-right: 15px;
    width: 100%;
    float: left; }
    .list-section .button-set .btnCancel span {
      color: #4a4a4a; }
      .list-section .button-set .btnCancel span .reassign:before {
        position: relative;
        font-size: 9px;
        left: 7px; }
    .list-section .button-set .btnPrimary span .reassign:before, .list-section .button-set .btnCancel span .reassign:before, .list-section .button-set .btnInactive span .reassign:before, .list-section .button-set .btnGray span .reassign:before {
      position: relative;
      font-size: 9px;
      left: 7px; }
  .list-section .userlist-head {
    border: 1px solid transparent;
    border-left-width: 5px;
    min-height: 32px; }
    .list-section .userlist-head .col {
      padding-left: 0;
      padding-right: 0; }
      .list-section .userlist-head .col.checkbox {
        width: 4%; }
        .list-section .userlist-head .col.checkbox > span {
          height: auto;
          width: auto;
          margin-left: 3px; }
    .list-section .userlist-head .userDateCol {
      width: 10.33%; }

.white-section.search-cutomer {
  padding: 10px 0 0 0; }
  .white-section.search-cutomer .inwraper {
    padding: 0 40px;
    width: 100%;
    float: left; }
  .white-section.search-cutomer .filter-title-row {
    padding: 0 20px 4px;
    float: left;
    width: 100%;
    min-height: 36px; }
    .white-section.search-cutomer .filter-title-row button {
      width: 30px;
      height: 30px;
      float: left;
      margin-left: -4px;
      cursor: default; }
      .white-section.search-cutomer .filter-title-row button i::before {
        font-size: 20px; }
      .white-section.search-cutomer .filter-title-row button:hover {
        background: transparent; }
      .white-section.search-cutomer .filter-title-row button > span + span {
        display: none; }
    .white-section.search-cutomer .filter-title-row h2 {
      float: left;
      margin: 5px 0 0 10px; }
    .white-section.search-cutomer .filter-title-row .chips-section {
      float: left;
      margin: 2px 15px;
      width: 85%; }
      .white-section.search-cutomer .filter-title-row .chips-section span {
        font-size: 13px; }
    .white-section.search-cutomer .filter-title-row .collapsible {
      float: right;
      margin-top: 2px; }
      .white-section.search-cutomer .filter-title-row .collapsible i {
        width: 26px;
        height: 26px;
        border: 1px solid #ddd;
        text-align: center;
        vertical-align: top;
        border-radius: 3px;
        cursor: pointer; }
  .white-section.search-cutomer .buttonSet {
    padding: 20px 40px;
    background: #f9f9f9;
    text-align: center;
    float: left;
    width: 100%; }
    .white-section.search-cutomer .buttonSet .wraper {
      width: 100%;
      text-align: center; }
  .white-section.search-cutomer .searchbaar {
    float: left;
    width: 100%;
    transition: all 0.5s;
    overflow: hidden; }
    .white-section.search-cutomer .searchbaar.closed {
      height: 0; }

.overflowH {
  overflow: hidden; }

.label-dragable {
  float: left;
  font-family: "latoregular", sans-serif;
  font-size: 12px;
  padding: 0 0 5px 2%;
  color: #4a4a4a; }

.userList.heightAutoList .listItem li {
  float: left;
  width: 100%; }
  .userList.heightAutoList .listItem li .flBox {
    height: auto; }
    .userList.heightAutoList .listItem li .flBox .col {
      line-height: 14px;
      padding-right: 0px;
      display: table; }
      .userList.heightAutoList .listItem li .flBox .col > span {
        padding-top: 7px;
        padding-bottom: 6px;
        padding-right: 10px;
        display: inline-block;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-word;
        display: table-cell;
        height: 40px;
        vertical-align: middle; }
        .userList.heightAutoList .listItem li .flBox .col > span.roleName {
          line-height: inherit; }
      .userList.heightAutoList .listItem li .flBox .col .userStatus {
        display: block;
        width: auto;
        padding: 0 0 0 12px;
        height: auto; }
      .userList.heightAutoList .listItem li .flBox .col h5 {
        display: table-cell;
        height: 40px;
        margin-top: 0;
        padding-top: 7px;
        padding-right: 10px;
        padding-bottom: 6px;
        vertical-align: middle;
        line-height: inherit; }
        .userList.heightAutoList .listItem li .flBox .col h5 span {
          width: 100%;
          white-space: normal;
          margin: 0;
          word-break: break-word;
          word-wrap: break-word; }

.chips-section {
  float: left;
  margin: 0px 15px 0px;
  width: 82%; }
  .chips-section span {
    font-size: 13px; }
    .chips-section span:first-child {
      padding-left: 8px; }
  .chips-section > div > svg {
    margin-right: 0px; }

.tbl-data thead tr td .error {
  font-size: 14px;
  font-style: normal; }

.tbl-data thead tr td > div > svg {
  margin-right: 0; }

.counterNumber {
  display: block;
  max-width: 74px;
  text-align: center; }
  .counterNumber em {
    text-align: center;
    font-style: normal;
    font-size: 12px;
    border: 0px solid #ddd;
    padding: 2px 5px;
    border-radius: 8px; }
  .counterNumber:hover em {
    color: #0093d0; }

.counterNumber2 {
  display: block;
  max-width: 74px;
  text-align: center; }
  .counterNumber2 em {
    text-align: center;
    font-style: normal;
    font-size: 12px;
    border: 0px solid #ddd;
    padding: 2px 5px;
    border-radius: 8px; }
  .counterNumber2 > span em:hover {
    color: #0093d0; }

.custom-select.formControlTop0 .formControl {
  margin-top: 0; }

.OverlengthThreshold > div {
  float: left; }

.OverlengthThreshold > div + .infoIcon.viewmode {
  position: relative;
  margin-left: 10px;
  left: inherit;
  top: -4px;
  right: 0; }

.userList.myCustomer.heightAuto .listItem li .flBox.facilityList .statusCol {
  width: 10%; }
  .userList.myCustomer.heightAuto .listItem li .flBox.facilityList .statusCol .userStatus {
    width: auto; }

.userList.myCustomer.heightAuto .listItem li .flBox.facilityList .carrierComments {
  width: 40%; }

.userList.myCustomer.heightAuto .listItem li .flBox.facilityList .userDate {
  width: 15%; }

.filterBar .gridFilterHead .carrierlist-head.facilityList .statusCol {
  width: 10%; }
  .filterBar .gridFilterHead .carrierlist-head.facilityList .statusCol .userStatus {
    width: auto; }

.filterBar .gridFilterHead .carrierlist-head.facilityList .carrierComments {
  width: 40%; }

.filterBar .gridFilterHead .carrierlist-head.facilityList .modified {
  width: 15%; }

.white-section.lead-settings {
  padding: 0; }
  .white-section.lead-settings .wrapper {
    width: 100%;
    float: left; }
    .white-section.lead-settings .wrapper .contentInner {
      width: 100%;
      float: left; }
      .white-section.lead-settings .wrapper .contentInner .fixed-head {
        background: #f5f5f5;
        padding: 15px 20px 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
        .white-section.lead-settings .wrapper .contentInner .fixed-head h2 {
          margin-top: 3px; }
      .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data {
        border: 0; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td:first-child {
          padding-left: 22px;
          padding-top: 2px;
          padding-bottom: 0px; }
          .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td:first-child .checkBoxWrap {
            margin-right: 0px; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td:nth-child(2) {
          padding-left: 0px; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td:last-child {
          padding-right: 10px; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .checkBoxWrap {
          margin-bottom: 0; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .inputField .label {
          margin-bottom: 3px; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td button {
          border: 0;
          padding: 0 12px 0 10px !important; }
          .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td button span {
            white-space: nowrap; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .zmdi-plus-square,
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .zmdi-minus-square {
          margin-top: 1px;
          margin-right: 1px; }
          .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .zmdi-plus-square:before,
          .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .zmdi-minus-square:before {
            font-size: 20px; }
        .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .form-inputField > div input, .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .form-inputFieldrdonly > div input, .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .form-inputPlaceholder > div input, .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .form-inputFieldError > div input, .white-section.lead-settings .wrapper .contentInner .card-section-outer .tbl-data tbody tr td .form-inputFieldImp > div input {
          padding-top: 8px !important; }
  .white-section.lead-settings .formAction {
    padding: 20px 30px 20px 30px;
    background: #f9f9f9;
    margin-top: -27px; }

.userList.myCustomer.heightAuto.leadManagement .listItem li .flBox .col.collapsePopup i {
  width: 20px;
  display: table-cell;
  padding-top: 9px;
  cursor: pointer; }
  .userList.myCustomer.heightAuto.leadManagement .listItem li .flBox .col.collapsePopup i:before {
    font-size: 22px;
    color: #4a4a4a; }

.leadPopup {
  position: fixed;
  top: 0px;
  width: 100%;
  right: -100%;
  background: #4a4e55;
  height: calc(100%);
  z-index: 20;
  transition: all .2s; }
  .leadPopup.open {
    right: 0%; }
  .leadPopup .leadPopup-inn {
    position: absolute;
    left: 108px;
    right: 0;
    background: #fff;
    height: calc(100%); }
  .leadPopup .closeMain {
    position: absolute;
    left: 76px;
    top: 8px;
    cursor: pointer; }
    .leadPopup .closeMain:before {
      color: #a2a4a7;
      font-size: 34px; }
  .leadPopup .headSection {
    width: 100%;
    border-bottom: 1px solid #eaeef1;
    padding: 20px; }
    .leadPopup .headSection h2 {
      font-size: 18px; }
      .leadPopup .headSection h2 i {
        vertical-align: middle;
        cursor: pointer;
        margin-top: -4px; }
        .leadPopup .headSection h2 i:before {
          color: #0093d0; }
        .leadPopup .headSection h2 i.zmdi-chevron-right {
          margin-left: 10px; }
        .leadPopup .headSection h2 i.zmdi-chevron-left {
          margin-right: 10px; }
        .leadPopup .headSection h2 i.op {
          opacity: .3; }
  .leadPopup .contentSection {
    width: 100%;
    float: left;
    padding: 0 20px; }
    .leadPopup .contentSection.graybg {
      padding: 20px 20px;
      background: #f8f8f8;
      border-bottom: 1px solid #eaeef1; }
      .leadPopup .contentSection.graybg .col-grid5 .col {
        padding-right: 15px; }
      .leadPopup .contentSection.graybg .head {
        position: relative; }
      .leadPopup .contentSection.graybg .statusHoldar {
        position: absolute;
        top: -3px;
        right: -20px;
        height: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background: #7ed321;
        padding: 0 20px 0 16px; }
        .leadPopup .contentSection.graybg .statusHoldar span {
          color: #fff;
          font-size: 12px;
          padding: 0;
          display: block;
          line-height: 20px; }
          .leadPopup .contentSection.graybg .statusHoldar span i {
            vertical-align: middle;
            margin-left: 10px; }
            .leadPopup .contentSection.graybg .statusHoldar span i:before {
              font-size: 18px; }
      .leadPopup .contentSection.graybg .inputField .labelData {
        color: #000;
        font-size: 14px; }
        .leadPopup .contentSection.graybg .inputField .labelData a {
          font-size: 14px; }
        .leadPopup .contentSection.graybg .inputField .labelData .smallLink {
          font-size: 12px;
          margin-left: 10px; }
          .leadPopup .contentSection.graybg .inputField .labelData .smallLink:hover {
            text-decoration: underline; }
          .leadPopup .contentSection.graybg .inputField .labelData .smallLink i {
            vertical-align: middle;
            line-height: inherit; }
            .leadPopup .contentSection.graybg .inputField .labelData .smallLink i:before {
              font-size: 18px; }
    .leadPopup .contentSection .col-grid5 {
      margin-bottom: 15px;
      float: left;
      width: 100%; }
    .leadPopup .contentSection .leftColumn {
      width: 67%;
      float: left;
      border-right: 1px solid #eaeef1;
      min-height: 100px; }
      .leadPopup .contentSection .leftColumn .block {
        width: 100%;
        float: left;
        padding: 20px 20px 10px 0;
        border-bottom: 1px solid #eaeef1; }
        .leadPopup .contentSection .leftColumn .block .form-action {
          width: 100%;
          padding: 10px 0;
          text-align: center; }
          .leadPopup .contentSection .leftColumn .block .form-action button {
            margin: 0 10px; }
      .leadPopup .contentSection .leftColumn fieldset {
        border: 0; }
    .leadPopup .contentSection .rightColumn {
      width: 33%;
      float: left;
      padding: 20px 20px 10px 20px; }
      .leadPopup .contentSection .rightColumn ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        .leadPopup .contentSection .rightColumn ul li {
          margin: 0 0 10px 0;
          padding: 0 0 10px 0;
          font-size: 13px;
          line-height: 18px;
          color: #4a4a4a;
          border-bottom: 1px solid #C0C8D0;
          font-family: 'latobold'; }
          .leadPopup .contentSection .rightColumn ul li em {
            font-family: "latoregular";
            font-style: normal; }
          .leadPopup .contentSection .rightColumn ul li:last-child {
            border: 0; }
        .leadPopup .contentSection .rightColumn ul.pin {
          padding-right: 10px;
          clear: both; }
          .leadPopup .contentSection .rightColumn ul.pin li.pined {
            padding-left: 24px;
            background: url("./../../assets/images/pin.svg") 0 4px no-repeat; }
          .leadPopup .contentSection .rightColumn ul.pin li:empty {
            display: none; }
      .leadPopup .contentSection .rightColumn .AddNoteForm .col-grid1 .col {
        margin-bottom: 5px; }
        .leadPopup .contentSection .rightColumn .AddNoteForm .col-grid1 .col.textarea .form-inputFieldImp div:before {
          height: 60px; }
        .leadPopup .contentSection .rightColumn .AddNoteForm .col-grid1 .col textarea {
          height: 46px; }
      .leadPopup .contentSection .rightColumn .AddNoteForm .formAction {
        position: static;
        text-align: left;
        padding: 0 0 0px 0;
        background: none;
        border: 0;
        text-align: right; }
        .leadPopup .contentSection .rightColumn .AddNoteForm .formAction button {
          margin: 0 0px 0 0; }
          .leadPopup .contentSection .rightColumn .AddNoteForm .formAction button img {
            float: left;
            margin-right: 5px; }
  .leadPopup .formAction {
    background: #f8f8f8;
    width: 100%;
    padding: 20px;
    text-align: center;
    position: absolute;
    border-top: 1px solid #eaeef1;
    bottom: 0; }

.list-section-filter-row {
  width: 100%;
  float: left;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #9b9b9b; }
  .list-section-filter-row button.borderBtn {
    margin-left: 0;
    margin-right: 20px;
    background: #fff;
    border: 0px;
    padding-right: 10px !important; }
    .list-section-filter-row button.borderBtn .userText {
      text-transform: none;
      font-size: 13px;
      padding: 0 4px 0 5px !important; }
    .list-section-filter-row button.borderBtn .dropdownIcon i {
      top: 0px !important; }
      .list-section-filter-row button.borderBtn .dropdownIcon i:before {
        font-size: 27px; }
  .list-section-filter-row .popup {
    left: 0 !important;
    right: inherit !important;
    width: 212px !important;
    top: 31px;
    padding: 10px !important; }
    .list-section-filter-row .popup .radioGroupWrap .radioGroup {
      max-height: 217px;
      overflow: auto;
      display: block; }
      .list-section-filter-row .popup .radioGroupWrap .radioGroup label {
        margin: 0;
        float: left;
        width: 100%; }
        .list-section-filter-row .popup .radioGroupWrap .radioGroup label span:first-child {
          width: 36px;
          height: 36px; }
    .list-section-filter-row .popup .dropDownbtn {
      padding: 10px 10px 10px; }
      .list-section-filter-row .popup .dropDownbtn button {
        min-width: inherit !important;
        padding: 10px 12px !important;
        margin: 0 5px; }

.white-section.leadSetting .inwraper {
  padding: 0 30px; }
  .white-section.leadSetting .inwraper .checkbox-row .checkBoxWrap {
    margin-bottom: 0; }

.switchBtn {
  width: 60px !important;
  display: block !important;
  padding: 0 !important;
  height: inherit !important; }
  .switchBtn.autoOn.checked > span > span > span {
    color: #417505; }
  .switchBtn.autoOn.checked > span + span {
    background-color: #417505; }
  .switchBtn.autoOff > span > span > span {
    color: #f5a623; }
  .switchBtn.autoOff > span + span {
    background-color: #f5a623; }
  .switchBtn.autoOff.checked > span > span > span {
    color: #f5a623; }
  .switchBtn.autoOff.checked > span + span {
    background-color: #f5a623; }
  .switchBtn.mOn.checked > span > span > span {
    color: #4a90e2; }
  .switchBtn.mOn.checked > span + span {
    background-color: #4a90e2; }
  .switchBtn.mOff > span > span > span {
    color: #d0021b; }
  .switchBtn.mOff > span + span {
    background-color: #d0021b; }
  .switchBtn.mOff.checked > span > span > span {
    color: #d0021b; }
  .switchBtn.mOff.checked > span + span {
    background-color: #d0021b; }
  .switchBtn .tooltiptext {
    bottom: 98%;
    width: 240px;
    text-align: left;
    margin-left: -121px;
    padding: 10px;
    line-height: 16px; }
    .switchBtn .tooltiptext em {
      color: #eda122;
      font-style: normal;
      font-family: 'latobold';
      font-size: 13px; }
    .switchBtn .tooltiptext:after {
      border-color: #000 transparent transparent transparent;
      bottom: -9px; }

.tooltipWrap .tooltiptext {
  color: #fff;
  overflow: visible !important; }
  .tooltipWrap .tooltiptext.wd230 {
    width: 230px !important;
    left: -60px !important; }
    .tooltipWrap .tooltiptext.wd230:after {
      top: 100%;
      left: 46% !important; }
  .tooltipWrap .tooltiptext.wd240 {
    width: 240px !important;
    left: -60px !important; }
    .tooltipWrap .tooltiptext.wd240:after {
      top: 100%;
      left: 46% !important; }
  .tooltipWrap .tooltiptext.simple {
    width: 280px !important;
    left: -120px !important; }
    .tooltipWrap .tooltiptext.simple samp {
      display: block;
      color: #fff;
      line-height: 16px;
      margin: 5px; }
    .tooltipWrap .tooltiptext.simple:after {
      top: 100%;
      left: 50% !important; }
  .tooltipWrap .tooltiptext > span {
    display: block !important;
    width: 50% !important;
    float: left;
    padding: 10px 4px 0; }
    .tooltipWrap .tooltiptext > span em {
      display: block;
      color: #eda122;
      font-style: normal;
      font-family: 'latobold';
      font-size: 13px;
      margin-bottom: 9px; }
    .tooltipWrap .tooltiptext > span samp {
      display: block;
      color: #fff;
      line-height: 16px;
      margin-bottom: 6px; }

.dateFormate {
  line-height: 16px; }
  .dateFormate em {
    color: #9b9b9b;
    font-style: normal;
    display: block;
    font-size: 11px; }

.dateFormate1 {
  margin-top: 8px;
  width: 100px;
  color: #7e7e7e;
  display: block;
  width: 70px;
  line-height: 16px; }
  .dateFormate1::first-line {
    color: #4a4a4a; }

.main-wraper .main-content.fpageScroll {
  padding-right: 15px; }
  .main-wraper .main-content.fpageScroll .full-section {
    width: 100%;
    padding-right: 15px; }

.DialogBox .fieldsetModule {
  min-height: 300px; }
  .DialogBox .fieldsetModule .agentList {
    margin: 0; }
    .DialogBox .fieldsetModule .agentList li {
      float: left;
      width: 33%;
      padding-left: 0 !important; }

.alinkBlue {
  cursor: pointer; }
  .alinkBlue:hover {
    color: #0093d0; }

.alinkBlue12 {
  cursor: pointer;
  color: #000;
  font-size: 12px;
  word-break: break-word;
  line-height: 16px;
  display: table-cell;
  vertical-align: middle; }
  .alinkBlue12:hover {
    color: #0093d0; }

.minHeight43 {
  min-height: 43px !important; }

.minHeight243 {
  min-height: 243px !important; }

.aLinkHoverBlue:hover {
  color: #0093d0; }

.lineHeight12 {
  line-height: 12px; }

.color4a {
  color: #4a4a4a !important; }

.zipViewMode .formControl.autosuggest > label + div {
  border: 0px !important; }
  .zipViewMode .formControl.autosuggest > label + div > div > div {
    background: transparent; }
    .zipViewMode .formControl.autosuggest > label + div > div > div div {
      padding-left: 0 !important; }

.requiredClass .formSelectBoxImp.formControl.autosuggest > div > div > div > div {
  padding-left: 8px;
  padding-right: 8px; }

.zipViewMode .formControl.autosuggest > label + div > div div[aria-hidden="true"] {
  display: none; }

/* view mode */
.zipViewMode.formControl.autosuggest > label + div {
  border: 0px !important; }
  .zipViewMode.formControl.autosuggest > label + div > div > div > div {
    padding: 0px; }
    .zipViewMode.formControl.autosuggest > label + div > div > div > div > div {
      margin: 0; }
    .zipViewMode.formControl.autosuggest > label + div > div > div > div + div {
      display: none; }

.requiredClass .zipViewMode.formControl.autosuggest > label + div {
  border: 0px !important; }

/* end view mode */
.formSelectBoxImp.formControl.autosuggest > div > div > div > div > div {
  border-radius: 20px;
  height: 24px; }
  .formSelectBoxImp.formControl.autosuggest > div > div > div > div > div > div {
    font-size: 14px;
    color: #000;
    line-height: 18px; }
    .formSelectBoxImp.formControl.autosuggest > div > div > div > div > div > div:hover {
      background: transparent;
      color: #000; }

.formSelectBoxImp.formControl.autosuggest > div > div > span {
  display: none; }
  .formSelectBoxImp.formControl.autosuggest > div > div > span + div + div {
    border: 0px !important; }
    .formSelectBoxImp.formControl.autosuggest > div > div > span + div + div > div {
      height: auto !important;
      max-height: 230px;
      border-radius: 2px; }
      .formSelectBoxImp.formControl.autosuggest > div > div > span + div + div > div > div {
        height: auto !important;
        border-radius: 2px; }

.blueAlink {
  cursor: pointer;
  color: #0093d0 !important; }
  .blueAlink:hover {
    color: #0093d0; }

.tooltiptext.xlsProduct {
  width: 300px;
  text-align: left;
  left: 36px;
  bottom: inherit;
  top: 33px;
  padding-left: 10px; }
  .tooltiptext.xlsProduct em {
    font-style: normal;
    line-height: 14px; }
  .tooltiptext.xlsProduct:after {
    left: 22px; }

.leadIconLeftSide {
  transform: rotate(-90deg); }
  .leadIconLeftSide:before {
    color: #9B9B9B; }
  .leadIconLeftSide:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 8px;
    background: #9b9b9b;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 5px;
    left: -4px; }

.main-wraper .main-content .right-section .wraper .fixed-content-nav ul li:hover .leadIconLeftSide:before {
  color: #4a4a4a; }

.main-wraper .main-content .right-section .wraper .fixed-content-nav ul li:hover .leadIconLeftSide:after {
  background: #4a4a4a; }

.userList {
  clear: both; }

.onlineHolder {
  color: #8bc348 !important; }

.onlineHolder-shipment {
  color: #8bc348 !important;
  font-size: 12px;
  margin-bottom: 5px; }

.registredHolder-shipment {
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 5px; }

.addressbook-section {
  width: 100%;
  float: left;
  border: 1px solid #ddd;
  margin: 20px 0; }
  .addressbook-section .address-block {
    width: 100%;
    float: left; }
    .addressbook-section .address-block .block-title {
      background: #f9f9f9;
      text-align: center;
      padding: 8px 0 9px;
      position: relative; }
      .addressbook-section .address-block .block-title h5 {
        color: #000;
        font-weight: normal;
        font-family: "latobold", sans-serif;
        display: inline-block;
        margin-right: 5px; }
      .addressbook-section .address-block .block-title em {
        font-size: 12px;
        font-style: normal;
        display: inline-block;
        color: #4a4a4a;
        line-height: 14px; }
      .addressbook-section .address-block .block-title .swap-address {
        width: 35px;
        height: 35px;
        background: #0093d0;
        border-radius: 100%;
        position: absolute;
        top: 3px;
        right: -16px;
        z-index: 1;
        cursor: pointer; }
        .addressbook-section .address-block .block-title .swap-address img {
          margin-top: 7px; }
    .addressbook-section .address-block .block-content {
      padding: 0px 5px 5px; }
      .addressbook-section .address-block .block-content .col-grid1,
      .addressbook-section .address-block .block-content .col-grid2 {
        clear: both;
        margin-bottom: 20px; }
        .addressbook-section .address-block .block-content .col-grid1 .col,
        .addressbook-section .address-block .block-content .col-grid2 .col {
          padding: 0 10px !important;
          margin-bottom: 0; }
          .addressbook-section .address-block .block-content .col-grid1 .col .col50:first-child,
          .addressbook-section .address-block .block-content .col-grid2 .col .col50:first-child {
            padding-right: 10px; }
          .addressbook-section .address-block .block-content .col-grid1 .col .info-note,
          .addressbook-section .address-block .block-content .col-grid2 .col .info-note {
            color: #9b9b9b;
            font-family: "latobold", sans-serif;
            font-size: 14px; }
            .addressbook-section .address-block .block-content .col-grid1 .col .info-note i,
            .addressbook-section .address-block .block-content .col-grid2 .col .info-note i {
              text-align: left;
              vertical-align: middle;
              display: inline-block;
              margin-right: 5px; }
              .addressbook-section .address-block .block-content .col-grid1 .col .info-note i:before,
              .addressbook-section .address-block .block-content .col-grid2 .col .info-note i:before {
                font-size: 16px;
                color: #9b9b9b; }
      .addressbook-section .address-block .block-content .col-label {
        clear: both; }
        .addressbook-section .address-block .block-content .col-label .col {
          padding: 0 10px;
          font-size: 14px; }
      .addressbook-section .address-block .block-content .section-heading {
        margin-bottom: 10px !important; }
        .addressbook-section .address-block .block-content .section-heading label {
          margin: 0 0 0 -15px; }
        .addressbook-section .address-block .block-content .section-heading .col {
          margin: 0; }
          .addressbook-section .address-block .block-content .section-heading .col h3 {
            margin: 15px 0 0 0;
            padding: 0;
            color: #000;
            font-weight: normal;
            font-family: "latobold", sans-serif; }
            .addressbook-section .address-block .block-content .section-heading .col h3 i {
              vertical-align: middle;
              cursor: pointer; }
              .addressbook-section .address-block .block-content .section-heading .col h3 i:before {
                color: #0093d0;
                font-size: 21px; }
      .addressbook-section .address-block .block-content.view-mode .col-grid2.section-heading {
        margin-bottom: 20px !important; }
  .addressbook-section .left {
    width: 50%;
    float: left;
    border-right: 1px solid #ddd;
    box-shadow: 12px 0 21px -1px #f1f1f1; }
  .addressbook-section .right {
    width: 50%;
    float: left; }

.fieldset {
  width: 100%;
  float: left; }
  .fieldset .title-head {
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
    line-height: 14px; }
    .fieldset .title-head h5 {
      float: left; }
  .fieldset h5 {
    color: #000;
    font-weight: normal;
    font-family: "latobold", sans-serif;
    display: inline-block; }
  .fieldset .labelTxt {
    color: #4a4a4a;
    font-size: 12px; }
  .fieldset .checkbox-row .checkBoxWrap {
    margin: 0; }
    .fieldset .checkbox-row .checkBoxWrap span:first-child {
      width: 38px;
      height: 38px; }
  .fieldset.fieldset-gray {
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px; }
    .fieldset.fieldset-gray .title-head {
      margin: 0 0 15px 0 !important; }
    .fieldset.fieldset-gray div[role="button"] {
      border-bottom: 0 !important; }

.a-link {
  font-size: 14px;
  font-family: 'latobold';
  vertical-align: top;
  line-height: 16px; }
  .a-link i {
    width: 20px;
    height: 16px;
    background: #0093d0;
    border-radius: 3px;
    text-align: center;
    margin-right: 6px;
    vertical-align: top; }
    .a-link i:before {
      font-size: 16px;
      color: #fff; }
  .a-link svg {
    vertical-align: top;
    margin-top: 2px;
    margin-right: 4px; }
  .a-link .reassign {
    background: transparent;
    margin-right: 0;
    width: 10px;
    height: 10px; }
    .a-link .reassign:before {
      font-size: 10px;
      color: #0093d0;
      position: relative;
      left: 7px; }
    .a-link .reassign + .zmdi-account {
      background: transparent; }
      .a-link .reassign + .zmdi-account:before {
        font-size: 18px;
        color: #0093d0; }

.table-content {
  float: left;
  width: 100%; }
  .table-content .tbl-data {
    border: 0 !important;
    border-top: 1px solid #e6eaee !important; }
    .table-content .tbl-data tbody tr td:first-child,
    .table-content .tbl-data thead tr td:first-child {
      border-left: 1px solid #e6eaee; }
    .table-content .tbl-data tbody tr td:last-child,
    .table-content .tbl-data thead tr td:last-child {
      border-right: 1px solid #e6eaee; }
    .table-content .tbl-data tbody tr.table-field-content > td,
    .table-content .tbl-data thead tr.table-field-content > td {
      border: 1px solid #cfcfd2;
      padding-top: 0;
      padding-right: 15px; }
      .table-content .tbl-data tbody tr.table-field-content > td .item-row:first-child,
      .table-content .tbl-data thead tr.table-field-content > td .item-row:first-child {
        position: relative; }
        .table-content .tbl-data tbody tr.table-field-content > td .item-row:first-child:before,
        .table-content .tbl-data thead tr.table-field-content > td .item-row:first-child:before {
          content: '';
          border: 10px solid transparent;
          border-bottom: 10px solid #cfcfd2;
          position: absolute;
          top: -21px;
          left: 33px; }
        .table-content .tbl-data tbody tr.table-field-content > td .item-row:first-child:after,
        .table-content .tbl-data thead tr.table-field-content > td .item-row:first-child:after {
          content: '';
          border: 10px solid transparent;
          border-bottom: 10px solid #f7f7f7;
          position: absolute;
          top: -20px;
          left: 33px; }
  .table-content .table-field.active td {
    border-bottom: 0 !important;
    background: #fff; }
  .table-content .table-field .form-inputField > div input[type="text"], .table-content .table-field .form-inputFieldrdonly > div input[type="text"], .table-content .table-field .form-inputPlaceholder > div input[type="text"], .table-content .table-field .form-inputFieldError > div input[type="text"], .table-content .table-field .form-inputFieldImp > div input[type="text"] {
    padding-left: 10px !important; }
  .table-content .table-field .form-inputField.form-inputFieldError > div:before, .table-content .table-field .form-inputFieldError.form-inputFieldrdonly > div:before, .table-content .table-field .form-inputFieldError.form-inputPlaceholder > div:before, .table-content .table-field .form-inputFieldError > div:before, .table-content .table-field .form-inputFieldError.form-inputFieldImp > div:before {
    border-bottom: 1px solid #eaeaea !important; }
  .table-content .table-field .formSelectBoxImp.formControl > div {
    margin-top: 0; }
    .table-content .table-field .formSelectBoxImp.formControl > div:before {
      background: #fff; }
    .table-content .table-field .formSelectBoxImp.formControl > div > div > svg {
      right: 3px; }
  .table-content .table-field .form-inputFieldrdonly > div:before {
    border: 0px; }
  .table-content .table-field .form-inputField > div, .table-content .table-field .form-inputFieldrdonly > div, .table-content .table-field .form-inputPlaceholder > div, .table-content .table-field .form-inputFieldError > div, .table-content .table-field .form-inputFieldImp > div,
  .table-content .table-field .form-inputFieldImp > div,
  .table-content .table-field .formSelectBoxRdonly > div {
    margin-top: 0; }
    .table-content .table-field .form-inputField > div:before, .table-content .table-field .form-inputFieldrdonly > div:before, .table-content .table-field .form-inputPlaceholder > div:before, .table-content .table-field .form-inputFieldError > div:before, .table-content .table-field .form-inputFieldImp > div:before,
    .table-content .table-field .form-inputFieldImp > div:before,
    .table-content .table-field .formSelectBoxRdonly > div:before {
      height: 31px; }
    .table-content .table-field .form-inputField > div input, .table-content .table-field .form-inputFieldrdonly > div input, .table-content .table-field .form-inputPlaceholder > div input, .table-content .table-field .form-inputFieldError > div input, .table-content .table-field .form-inputFieldImp > div input,
    .table-content .table-field .form-inputFieldImp > div input,
    .table-content .table-field .formSelectBoxRdonly > div input {
      background: #fff; }
  .table-content .table-field .form-inputField input[type=number], .table-content .table-field .form-inputFieldrdonly input[type=number], .table-content .table-field .form-inputPlaceholder input[type=number], .table-content .table-field .form-inputFieldError input[type=number], .table-content .table-field .form-inputFieldImp input[type=number],
  .table-content .table-field .form-inputFieldImp input[type=number],
  .table-content .table-field .formSelectBoxRdonly input[type=number] {
    padding-left: 10px !important; }
  .table-content .table-field .collapsible i {
    width: 24px;
    height: 24px;
    border: 1px solid #f4f4f6; }
    .table-content .table-field .collapsible i:before {
      font-size: 21px;
      line-height: 23px; }
  .table-content .table-field-content {
    background: #f7f7f7;
    padding: 0; }
    .table-content .table-field-content .item-row {
      float: left;
      width: 100%;
      border-bottom: 1px solid #dfdfe4; }
      .table-content .table-field-content .item-row .iten-label {
        float: left;
        width: 30%;
        color: #9b9b9b;
        padding: 13px 10px 0; }
      .table-content .table-field-content .item-row .iten-info {
        float: left;
        width: 70%;
        padding: 9px 10px;
        min-height: 50px; }
        .table-content .table-field-content .item-row .iten-info .Dimensions-row {
          float: left; }
          .table-content .table-field-content .item-row .iten-info .Dimensions-row .column {
            width: 55px;
            float: left; }
            .table-content .table-field-content .item-row .iten-info .Dimensions-row .column .label {
              width: 100%;
              float: left;
              font-size: 11px;
              font-family: "latoregular";
              color: #4a4a4a;
              line-height: 14px;
              margin-bottom: 2px; }
              .table-content .table-field-content .item-row .iten-info .Dimensions-row .column .label.view {
                text-align: center; }
            .table-content .table-field-content .item-row .iten-info .Dimensions-row .column .labelData {
              width: 100%;
              float: left;
              white-space: nowrap;
              text-align: center; }
            .table-content .table-field-content .item-row .iten-info .Dimensions-row .column .field {
              width: 100%;
              float: left; }
              .table-content .table-field-content .item-row .iten-info .Dimensions-row .column .field.view {
                width: 40px; }
            .table-content .table-field-content .item-row .iten-info .Dimensions-row .column.inch {
              width: 100px;
              padding-left: 18px; }
              .table-content .table-field-content .item-row .iten-info .Dimensions-row .column.inch .field {
                margin-top: 13px; }
            .table-content .table-field-content .item-row .iten-info .Dimensions-row .column.switch {
              width: auto; }
              .table-content .table-field-content .item-row .iten-info .Dimensions-row .column.switch .label {
                margin-top: 24px;
                color: #9b9b9b;
                margin-left: 15px; }
                .table-content .table-field-content .item-row .iten-info .Dimensions-row .column.switch .label a {
                  line-height: 14px;
                  font-size: 12px;
                  font-family: "latobold"; }
          .table-content .table-field-content .item-row .iten-info .Dimensions-row .by {
            width: 19px;
            float: left;
            text-align: center;
            font-family: "latoregular";
            color: #4a4a4a;
            padding-top: 19px;
            font-size: 11px; }
        .table-content .table-field-content .item-row .iten-info .inputField {
          max-width: 300px; }
        .table-content .table-field-content .item-row .iten-info .inputFieldSmall {
          max-width: 110px; }
        .table-content .table-field-content .item-row .iten-info .inputRadio .radioGroup {
          display: block; }
          .table-content .table-field-content .item-row .iten-info .inputRadio .radioGroup .radio-col {
            float: left;
            margin: 0 30px 0 0; }
            .table-content .table-field-content .item-row .iten-info .inputRadio .radioGroup .radio-col span:first-child {
              width: 38px;
              height: 38px; }
        .table-content .table-field-content .item-row .iten-info.view .inputField {
          width: 100%;
          max-width: inherit; }

#itemDetails .tbl-data thead tr td:first-child {
  padding-left: 15px; }

#itemDetails .tbl-data tbody tr td:first-child {
  padding-left: 15px; }

#itemDetails .selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] {
  padding-top: 3px; }

#itemDetails .custom-select .formControl {
  margin-top: 0;
  margin-bottom: 0; }

#itemDetails .selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] {
  padding-right: 28px !important; }

#itemDetails .selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] {
  margin-right: 0; }

#itemDetails .selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  font-size: 12px;
  color: #4a4a4a !important;
  padding-left: 10px;
  padding-right: 0;
  line-height: 23px; }

.block-title .ship-status {
  height: 16px;
  position: relative; }
  .block-title .ship-status samp {
    color: #fff;
    font-size: 13px;
    position: absolute;
    top: -6px;
    line-height: 17px;
    display: block;
    padding: 0 8px;
    left: -20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }
    .block-title .ship-status samp.saved {
      background: #ff6c2f; }
    .block-title .ship-status samp.review {
      background: #d0cf00; }
    .block-title .ship-status samp.received {
      background: #00d0a9; }
    .block-title .ship-status samp.pickup {
      background: #00cccc; }
    .block-title .ship-status samp.intransit {
      background: #0093d0; }
    .block-title .ship-status samp.delivered {
      background: #8bc34a; }
    .block-title .ship-status samp.close {
      background: #000; }
    .block-title .ship-status samp.cancel {
      background: #ff6868; }

.breadcrumbsShipStatus {
  display: inline-block;
  height: 16px;
  position: relative; }
  .breadcrumbsShipStatus samp {
    color: #fff;
    font-size: 13px;
    position: inherit;
    line-height: 17px;
    display: block;
    padding: 0 8px 0 30px;
    left: -20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px; }
    .breadcrumbsShipStatus samp.saved {
      background: #ff6c2f; }
    .breadcrumbsShipStatus samp.review {
      background: #d0cf00; }
    .breadcrumbsShipStatus samp.received {
      background: #00d0a9; }
    .breadcrumbsShipStatus samp.pickup {
      background: #00cccc; }
    .breadcrumbsShipStatus samp.intransit {
      background: #0093d0; }
    .breadcrumbsShipStatus samp.delivered {
      background: #8bc34a; }
    .breadcrumbsShipStatus samp.close {
      background: #000; }
    .breadcrumbsShipStatus samp.cancel {
      background: #ff6868; }
    .breadcrumbsShipStatus samp.tlestimatesaved {
      background: #a4372e; }
    .breadcrumbsShipStatus samp.tlopen {
      background: #f16a2f; }
    .breadcrumbsShipStatus samp.tlreview {
      background: #c6d127; }
    .breadcrumbsShipStatus samp.tlcovered {
      background: #63d2a9; }
    .breadcrumbsShipStatus samp.tldispatched {
      background: #9a75a0; }
    .breadcrumbsShipStatus samp.tlarrivedatpickup {
      background: #3491d0; }
    .breadcrumbsShipStatus samp.tlloaded {
      background: #3491d0; }
    .breadcrumbsShipStatus samp.tlintransit {
      background: #3491d0; }
    .breadcrumbsShipStatus samp.tlatdestination {
      background: #3491d0; }
    .breadcrumbsShipStatus samp.tldelivered {
      background: #80c440; }
    .breadcrumbsShipStatus samp.tldeactivated {
      background: #ef6367; }
    .breadcrumbsShipStatus samp.tlcancel {
      background: #ef6367; }

.block-content {
  clear: both; }
  .block-content .Accepted i:before {
    color: #0093d0 !important;
    font-size: 16px; }
  .block-content .notAccepted i:before {
    color: #ff6868 !important;
    font-size: 16px; }

.block-footer.smallTxt {
  border-top: 1px solid #ddd !important;
  font-size: 12px;
  text-align: left !important;
  color: #9b9b9b;
  padding: 5px 20px !important;
  line-height: 14px; }
  .block-footer.smallTxt strong {
    font-weight: normal;
    font-size: 12px;
    font-family: 'latobold';
    color: #9b9b9b; }

.inputField .label {
  font-family: "latoregular", sans-serif !important;
  color: #4a4a4a;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px; }

.inputField .labelData {
  font-size: 14px;
  color: #4a4a4a; }

.col .label-text {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 14px; }

.col .label-value {
  font-size: 14px;
  line-height: 14px;
  font-family: 'latobold';
  color: #4a4a4a;
  word-break: break-word; }

.textareaNote {
  font-weight: normal;
  font-size: 14px;
  color: #9b9b9b; }

.textareaFsize16 {
  font-weight: normal;
  font-size: 16px; }
  .textareaFsize16 .labelData {
    color: #000; }

.main-wraper .main-content .left-section .block-section .block-content ul.pin li em {
  display: block;
  color: #9b9b9b;
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  padding: 6px 0 0; }

.tbl-data.innerTable {
  border: 0 !important; }
  .tbl-data.innerTable td {
    border: 0 !important;
    border-bottom: 2px solid #fff !important;
    word-break: break-word !important;
    line-height: 18px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important; }
    .tbl-data.innerTable td:first-child {
      padding-left: 0 !important; }
  .tbl-data.innerTable thead td {
    font-family: "latobold" !important;
    font-size: 12px !important;
    color: #9b9b9b !important; }

.bdr0 {
  border: 0 !important; }

.searchbaar {
  min-height: 0; }

.searchbaar form {
  transform: translateY(0%);
  display: block;
  float: left;
  width: 100%;
  transition: all .5s; }

.searchbaar.open {
  min-height: 180px; }

.searchbaar.closed form {
  transform: translateY(-100%);
  display: block;
  float: left;
  width: 100%;
  transition: all .5s; }

.userList.shipment .listItem {
  height: auto; }

#trackingInformation .table-content {
  border-radius: 4px;
  overflow: hidden; }
  #trackingInformation .table-content table tbody tr td .label-value {
    margin-bottom: 10px;
    margin-top: 10px; }
    #trackingInformation .table-content table tbody tr td .label-value .times {
      color: #9b9b9b;
      font-style: normal;
      display: inline;
      font-family: 'latobold';
      font-size: 14px;
      padding-left: 4px; }
  #trackingInformation .table-content table tbody tr td .label-text {
    margin-bottom: 10px;
    margin-top: 10px; }

.f14Black {
  color: #4a4a4a;
  font-size: 14px;
  font-family: 'latobold'; }

.shiping-tracker-section {
  width: 100%;
  float: left;
  padding: 10px 0 0 0; }
  .shiping-tracker-section .track-wraper {
    width: 100%;
    float: left;
    padding: 0 0 0 76px;
    position: relative; }
    .shiping-tracker-section .track-wraper .progressBaar-horizontal {
      width: 15px;
      float: left;
      background: #fff;
      height: 40px; }
      .shiping-tracker-section .track-wraper .progressBaar-horizontal .fill-bg {
        position: absolute;
        height: auto;
        background: #f5f5f5;
        width: 15px;
        border-radius: 10px;
        top: 46px;
        bottom: 0px; }
    .shiping-tracker-section .track-wraper .content-section {
      float: none; }
      .shiping-tracker-section .track-wraper .content-section .content-row {
        float: none;
        margin-left: 40px;
        height: auto; }
        .shiping-tracker-section .track-wraper .content-section .content-row .content-right {
          width: auto;
          margin-left: 0px; }
          .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data {
            width: 100%;
            float: left;
            border: 0px solid #ebeef1; }
            .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper {
              width: 100%;
              float: left;
              padding-bottom: 35px;
              position: relative; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator {
                width: 15px;
                position: absolute;
                height: calc(100% + 12px);
                background: #f5f5f5;
                left: -40px;
                border-radius: 10px; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.green {
                  background: #89c34a; }
                  .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.green:RadiusCorner {
                    height: 15px;
                    background: #89c34a; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.blue {
                  background: #0093d0; }
                  .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.blue.RadiusCorner {
                    height: 100%;
                    background: #0093d0; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:last-child {
                margin-bottom: 0;
                padding-top: 0px; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:last-child .indicator {
                  border-radius: 0px;
                  margin-top: -40px; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:nth-last-child(2) .tbl-body .row:last-child .left-column {
                background: transparent; }
            .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head {
              display: table;
              width: 100%;
              float: left;
              position: relative; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .left-column {
                width: 162px;
                display: table-cell;
                margin: 0;
                padding: 0;
                vertical-align: top;
                font-size: 14px;
                color: #4a4a4a;
                line-height: 14px; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .left-column span {
                  display: none;
                  font-size: 12px;
                  color: #9b9b9b; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .col-grid2 {
                border-bottom: 1px solid #ebeef1;
                border-left: 1px solid #ebeef1;
                border-right: 1px solid #ebeef1;
                margin: 0 !important;
                width: 100%;
                display: table-cell; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .col-grid2 .col {
                  margin: 8px 0;
                  padding: 0 16px !important;
                  font-size: 14px;
                  color: #4a4a4a; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.head .col-grid2 {
                border-left: 0;
                border-right: 0; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.head .col-grid2 .col {
                  font-family: 'latobold';
                  font-size: 12px;
                  color: #4a4a4a;
                  font-weight: normal; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .left-column:before {
                content: '';
                position: absolute;
                left: -20px;
                top: 8px;
                border: 6px solid #4a4a4a;
                border-color: transparent #4a4a4a transparent transparent; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .left-column.arrowHide:before {
                border: 0px solid #4a4a4a; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .left-column samp {
                position: absolute;
                left: -110px;
                font-style: normal;
                width: 70px;
                font-size: 12px;
                color: #4a4a4a;
                font-family: 'latobold';
                line-height: 14px; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .col-grid2 {
                border-top: 1px solid #ebeef1; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .col-grid2:before {
                  content: '';
                  position: absolute;
                  left: 143px;
                  top: 10px;
                  border: 10px solid #ebeef1;
                  border-color: transparent #ebeef1 transparent transparent; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .col-grid2:after {
                  content: '';
                  position: absolute;
                  left: 145px;
                  top: 10px;
                  border: 10px solid #ebeef1;
                  border-color: transparent #fff transparent transparent; }
            .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body {
              clear: both; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body.close {
                display: none; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .row {
                width: 100%;
                display: table; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .left-column {
                width: 162px;
                display: table-cell;
                margin: 0;
                padding: 0;
                vertical-align: middle;
                font-size: 14px;
                color: #4a4a4a;
                line-height: 15px;
                background: url("./../../assets/images/border.png") 20px 0 repeat-y; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .left-column span {
                  display: block;
                  font-size: 12px;
                  color: #9b9b9b;
                  background: #fff; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .col-grid2 {
                border-bottom: 1px solid #ebeef1;
                border-left: 1px solid #ebeef1;
                border-right: 1px solid #ebeef1;
                margin: 0 !important;
                background: #f9f9f9;
                display: table-cell; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .col-grid2:before {
                  content: '';
                  border-bottom: 2px solid #000; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .col-grid2 .col {
                  margin: 6px 0;
                  padding: 0 16px !important;
                  font-size: 14px;
                  color: #4a4a4a; }

.shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .row:last-child .left-column span {
  padding-bottom: 27px;
  padding-top: 3px;
  line-height: 15px; }

.shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:last-child .left-column:before {
  top: 4px; }

.tooltipWrap .tooltiptext.quotedDate {
  left: 12px;
  width: 178px;
  bottom: inherit;
  top: -78px;
  text-align: left;
  line-height: 17px;
  padding: 9px 12px 6px; }
  .tooltipWrap .tooltiptext.quotedDate:after {
    border-color: #000 transparent transparent transparent;
    bottom: -10px; }

.main-wraper .breadcrumbBar .right-button .btnPrimary.iconBtn, .main-wraper .breadcrumbBar .right-button .iconBtn.btnCancel, .main-wraper .breadcrumbBar .right-button .iconBtn.btnInactive, .main-wraper .breadcrumbBar .right-button .iconBtn.btnGray {
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: 32px; }
  .main-wraper .breadcrumbBar .right-button .btnPrimary.iconBtn i, .main-wraper .breadcrumbBar .right-button .iconBtn.btnCancel i, .main-wraper .breadcrumbBar .right-button .iconBtn.btnInactive i, .main-wraper .breadcrumbBar .right-button .iconBtn.btnGray i {
    margin: 0; }
    .main-wraper .breadcrumbBar .right-button .btnPrimary.iconBtn i:before, .main-wraper .breadcrumbBar .right-button .iconBtn.btnCancel i:before, .main-wraper .breadcrumbBar .right-button .iconBtn.btnInactive i:before, .main-wraper .breadcrumbBar .right-button .iconBtn.btnGray i:before {
      margin: 0; }

.main-wraper .breadcrumbBar .right-button .btnInactive svg.product-image path {
  fill: #4b4b4b !important; }

.main-wraper .breadcrumbBar .right-button .btnInactive svg path {
  fill: #ddd; }

.collapsible i:hover {
  border-color: transparent;
  background: #0093d0; }
  .collapsible i:hover:before {
    color: #fff; }

.api-request {
  width: 100%;
  float: left;
  background: #f5f5f5;
  border: 1px solid #e9ecef;
  padding: 20px;
  border-radius: 3px; }
  .api-request h4 {
    font-weight: normal;
    font-family: 'latobold';
    margin-bottom: 18px; }
  .api-request .borderBox {
    border: 1px solid #adadad;
    float: left;
    width: 100%;
    padding: 10px 15px;
    border-radius: 3px; }
    .api-request .borderBox label {
      display: block;
      font-family: 'latobold';
      font-size: 14px;
      color: #9b9b9b;
      margin-bottom: 2px; }
    .api-request .borderBox span {
      font-size: 14px;
      font-family: 'latobold';
      color: #4a4a4a; }
      .api-request .borderBox span samp {
        font-size: 14px;
        font-family: 'latobold';
        color: #9b9b9b;
        font-style: normal;
        padding-left: 6px; }
  .api-request .whiteBox {
    background: #fff;
    padding: 20px;
    border: 1px solid #ebeef1;
    border-radius: 3px; }
  .api-request .col-grid2 h4 {
    margin: 10px 0 12px 0; }
  .api-request pre {
    padding-right: 14px;
    font-size: 15px;
    line-height: 18px;
    color: #4a4a4a;
    white-space: pre-wrap; }

.col-grid2.borderBox .col {
  padding-right: 5px; }

.col-grid2.borderBox .col:last-child {
  padding-left: 5px; }

.col-grid4.borderBox .col {
  padding-right: 30px !important; }

.col-grid3 .col.col-last:last-child {
  padding-right: 0; }

.dateField .form-inputField > div:before, .dateField .form-inputFieldrdonly > div:before, .dateField .form-inputPlaceholder > div:before, .dateField .form-inputFieldError > div:before, .dateField .form-inputFieldImp > div:before {
  border: 0 !important; }

.dateField .form-inputField > div input, .dateField .form-inputFieldrdonly > div input, .dateField .form-inputPlaceholder > div input, .dateField .form-inputFieldError > div input, .dateField .form-inputFieldImp > div input {
  background: #fff;
  padding-left: 10px !important; }

.tbl-data tbody tr td .form-inputFieldImp > div,
.tbl-data tbody tr td .formSelectBoxRdonly > div {
  margin-top: 0; }
  .tbl-data tbody tr td .form-inputFieldImp > div:before,
  .tbl-data tbody tr td .formSelectBoxRdonly > div:before {
    height: 31px; }
  .tbl-data tbody tr td .form-inputFieldImp > div input,
  .tbl-data tbody tr td .formSelectBoxRdonly > div input {
    background: #fff; }

.main-wraper .main-content .page-title .right-link.shipment-top-link .relative:hover .popup.header {
  display: block; }
  .main-wraper .main-content .page-title .right-link.shipment-top-link .relative:hover .popup.header ul li:hover {
    color: #9b9b9b; }
    .main-wraper .main-content .page-title .right-link.shipment-top-link .relative:hover .popup.header ul li:hover i:before {
      color: #9b9b9b; }

.main-wraper .main-content .page-title .right-link.shipment-top-link .popup.header {
  top: 25px;
  left: inherit;
  right: 0;
  display: none; }

.main-wraper .main-content .page-title .right-link.shipment-top-link button {
  border: 1px solid #9b9b9b;
  border-radius: 2px;
  float: left;
  margin-top: 1px;
  padding: 0 3px 0 8px !important; }
  .main-wraper .main-content .page-title .right-link.shipment-top-link button span {
    float: left; }
  .main-wraper .main-content .page-title .right-link.shipment-top-link button .userText {
    padding: 0 6px 0 0px !important;
    text-transform: none;
    display: block;
    white-space: inherit; }
    .main-wraper .main-content .page-title .right-link.shipment-top-link button .userText a {
      font-size: 12px;
      color: #4a4a4a; }
  .main-wraper .main-content .page-title .right-link.shipment-top-link button .zmdi-account {
    margin-top: 3px; }
  .main-wraper .main-content .page-title .right-link.shipment-top-link button .dropdownIcon i {
    border-width: 0 1px 1px 0;
    border-color: #333;
    position: relative;
    top: -2px;
    padding: 3px;
    width: 6px;
    height: 6px; }

.tl-shipment-active-users {
  margin-top: 2px; }
  .tl-shipment-active-users .relative:hover .popup.header {
    display: block; }
    .tl-shipment-active-users .relative:hover .popup.header ul li:hover {
      color: #9b9b9b; }
      .tl-shipment-active-users .relative:hover .popup.header ul li:hover i:before {
        color: #9b9b9b; }
  .tl-shipment-active-users .popup.header {
    top: 25px;
    left: inherit;
    right: 0;
    display: none; }
  .tl-shipment-active-users button {
    border: 1px solid #9b9b9b;
    border-radius: 2px;
    float: left;
    margin-top: 1px;
    padding: 0 3px 0 8px !important; }
    .tl-shipment-active-users button span {
      float: left; }
    .tl-shipment-active-users button .userText {
      padding: 0 6px 0 0px !important;
      text-transform: none;
      display: block;
      white-space: inherit; }
      .tl-shipment-active-users button .userText a {
        font-size: 12px;
        color: #4a4a4a; }
    .tl-shipment-active-users button .zmdi-account {
      margin-top: 3px; }
    .tl-shipment-active-users button .dropdownIcon i {
      border-width: 0 1px 1px 0;
      border-color: #333;
      position: relative;
      top: -2px;
      padding: 3px;
      width: 6px;
      height: 6px; }

.filterBar .gridFilterHead.rowClicked {
  display: table; }
  .filterBar .gridFilterHead.rowClicked .col.checkbox {
    float: none;
    min-width: 55px;
    display: table-cell;
    vertical-align: top; }
    .filterBar .gridFilterHead.rowClicked .col.checkbox > span {
      width: 40px;
      height: 40px; }
  .filterBar .gridFilterHead.rowClicked .userlist-head {
    padding-top: 5px;
    float: none;
    display: table-cell;
    border-left: 0px; }

.userList.myCustomer.heightAuto .listItem li .flBox.rowClicked {
  display: table; }
  .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .col.checkbox {
    display: table-cell;
    float: none;
    width: 50px;
    vertical-align: top; }
  .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body {
    float: none;
    display: table-cell; }

.zmdi-sort-asc {
  cursor: pointer; }

.tbl-data tbody tr td .collapsible {
  white-space: nowrap; }

.userList.myCustomer.heightAuto .listItem li:last-child {
  margin-bottom: 0; }

.userList .listItem li .radio-col {
  padding-top: 0; }

.userList .listItem li .radio-col > span {
  width: 40px;
  height: 40px;
  margin-left: 6px; }

.userList.myCustomer.heightAuto .listItem {
  height: auto; }

.DialogBox .DialogBoxcontent fieldset.normalpopup {
  padding: 0px 24px 10px; }

.DialogBox .DialogBoxcontent fieldset.alertpopup {
  padding: 0px 24px 10px; }

.DialogBox .DialogBoxcontent fieldset.carrierBillpopup {
  padding: 20px 0px 0px;
  min-height: 200px; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup h3 {
    color: #000; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .label-text {
    color: #4a4a4a;
    font-size: 12px; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .label-value {
    color: #000;
    font-size: 12px; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .berLeft {
    border-left: 1px solid #ddd; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .padLeftRight24 {
    padding-left: 24px;
    padding-right: 24px; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .grayBox {
    background: #f9f9f9;
    padding-top: 15px; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .col-grid3 > .col {
    padding-right: 30px;
    padding-left: 30px !important; }
    .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .col-grid3 > .col:first-child {
      padding-left: 0 !important; }
  .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .col-grid5 > .col {
    padding-right: 15px;
    padding-left: 15px; }
    .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .col-grid5 > .col .label-value {
      font-family: "latoregular", sans-serif; }
    .DialogBox .DialogBoxcontent fieldset.carrierBillpopup .col-grid5 > .col:first-child {
      padding-left: 0; }

.DialogBox .carrierBillpopupTitle {
  box-shadow: 6px -9px 30px #333 !important; }

.DialogBox.small .DialogTitle h2, .DialogBox.large .DialogTitle h2 {
  position: relative; }
  .DialogBox.small .DialogTitle h2 .zmdi-close, .DialogBox.large .DialogTitle h2 .zmdi-close {
    position: absolute;
    right: 0;
    top: -4px;
    cursor: pointer; }

.DialogBox.small .DialogBoxfooter, .DialogBox.large .DialogBoxfooter {
  border-top: 2px solid #ececec;
  margin: 10px 0 0 0;
  padding: 16px; }

.DialogBox .closePopup {
  position: absolute;
  right: 12px;
  top: 10px;
  color: #7e7e7e;
  font-size: 24px;
  z-index: 1;
  cursor: pointer; }

.DialogBox .linkBdr {
  border: 1px solid #0093d0;
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  font-weight: 600; }
  .DialogBox .linkBdr i {
    margin-right: 6px; }
    .DialogBox .linkBdr i::before {
      font-size: 14px; }

.checkbox-row.checkbox-col2 {
  width: 50%;
  float: left;
  padding-right: 20px; }
  .checkbox-row.checkbox-col2 label {
    margin-bottom: 0; }

.mdlScroll {
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  -webkit-display: flex;
  display: flex;
  max-height: calc(100% - 64px);
  overflow-y: auto;
  flex-direction: column; }

.tbl-data tbody tr td .alink {
  font-size: 12px;
  vertical-align: top;
  line-height: 16px;
  color: #0093d0;
  cursor: pointer; }

.col-grid1 {
  clear: both; }

.InfolabelText {
  font-family: "latobold";
  font-size: 14px; }
  .InfolabelText i:before {
    font-size: 16px;
    vertical-align: top;
    margin-right: 4px; }

.checkBoxStyle .checkBoxWrap {
  margin-bottom: 0;
  margin-left: -10px;
  margin-right: 0; }
  .checkBoxStyle .checkBoxWrap span:first-child {
    width: 40px;
    height: 40px; }

.checkBoxStyle .checkbox {
  margin-left: -10px; }

td.checkBoxStyle {
  padding-top: 4px !important;
  padding-bottom: 0 !important; }
  td.checkBoxStyle .checkBoxWrap span:first-child {
    width: 36px;
    height: 36px; }

.addUserForm fieldset#TransactionDetails {
  min-height: 160px; }

.blackTxtbold {
  color: #4a4a4a !important;
  font-family: 'latobold';
  font-style: normal;
  font-size: 14px; }

.blacksTxtbolds {
  color: #4a4a4a !important;
  font-family: "latobold", sans-serif;
  font-style: normal; }

.timeTxt {
  color: #7e7e7e !important;
  font-size: 11px; }

#heightAutoId {
  float: left;
  position: static;
  width: 100%; }

.amountduePopup {
  background: #fff;
  width: 580px;
  max-width: 580px;
  box-shadow: 0px 1px 5px 1px #ddd;
  position: absolute;
  left: -158px;
  top: 9px;
  z-index: 10;
  display: table; }
  .amountduePopup:before {
    position: absolute;
    content: '';
    border: 5px solid transparent;
    border-bottom: 5px solid #dfdfe4;
    left: 160px;
    top: -11px; }
  .amountduePopup:after {
    position: absolute;
    content: '';
    border: 5px solid transparent;
    border-bottom: 5px solid #fff;
    left: 160px;
    top: -10px; }
  .amountduePopup .popup-left {
    width: 78%;
    float: none;
    display: table-cell;
    border-right: 1px solid #dfdfe4;
    vertical-align: top; }
    .amountduePopup .popup-left .head-section {
      width: 100%;
      float: left;
      border-bottom: 2px solid #ebebeb;
      padding: 15px 0; }
      .amountduePopup .popup-left .head-section:before {
        position: absolute;
        content: '';
        border: 10px solid transparent;
        border-left: 10px solid #dfdfe4;
        left: 77.8%;
        top: 6%; }
      .amountduePopup .popup-left .head-section:after {
        position: absolute;
        content: '';
        border: 10px solid transparent;
        border-left: 10px solid #fff;
        left: 77.4%;
        top: 6%; }
      .amountduePopup .popup-left .head-section .col-grid2 {
        margin: 0 !important; }
        .amountduePopup .popup-left .head-section .col-grid2 .col {
          line-height: 22px;
          text-align: center; }
          .amountduePopup .popup-left .head-section .col-grid2 .col h4 {
            color: #9b9b9b;
            font-weight: normal;
            font-family: "latobold", sans-serif;
            font-size: 12px;
            text-align: left;
            margin-bottom: 6px; }
          .amountduePopup .popup-left .head-section .col-grid2 .col em {
            color: #000;
            font-family: "latobold", sans-serif;
            font-style: normal;
            font-size: 12px; }
          .amountduePopup .popup-left .head-section .col-grid2 .col:first-child {
            width: 50% !important;
            padding-left: 10px !important; }
          .amountduePopup .popup-left .head-section .col-grid2 .col:last-child {
            width: 50% !important; }
    .amountduePopup .popup-left .content-section {
      width: 100%;
      float: left;
      padding: 0 8px 10px; }
    .amountduePopup .popup-left .col {
      margin-bottom: 0;
      padding-bottom: 0; }
  .amountduePopup .popup-right {
    width: 22%;
    float: none;
    display: table-cell;
    vertical-align: top;
    background: #f9f9f9;
    padding: 18px 0; }
    .amountduePopup .popup-right .row {
      width: 100%;
      float: left;
      padding: 0 0 12px 12px;
      text-align: left; }
      .amountduePopup .popup-right .row h4 {
        color: #9b9b9b;
        font-weight: normal;
        font-family: "latobold", sans-serif;
        font-size: 12px;
        text-align: left;
        margin-bottom: 6px; }
      .amountduePopup .popup-right .row em {
        color: #000;
        font-family: "latobold", sans-serif;
        font-style: normal;
        font-size: 12px;
        display: block; }
      .amountduePopup .popup-right .row span {
        color: #9b9b9b;
        font-style: normal;
        font-size: 11px !important; }
    .amountduePopup .popup-right .zmdi-close {
      position: absolute;
      right: 3px;
      top: 3px; }
      .amountduePopup .popup-right .zmdi-close:before {
        font-size: 18px !important; }
  .amountduePopup .col-grid2 {
    margin: 0 !important; }
    .amountduePopup .col-grid2 .col {
      line-height: 22px;
      font-size: 11px; }
      .amountduePopup .col-grid2 .col:first-child {
        width: 50% !important;
        padding-left: 10px !important;
        text-align: right; }
      .amountduePopup .col-grid2 .col:last-child {
        width: 50% !important; }
      .amountduePopup .col-grid2 .col .col50 {
        min-height: 1px;
        text-align: left;
        padding-right: 5px; }
    .amountduePopup .col-grid2:nth-child(even) {
      background: #f9f9f9; }

.tlAmountDuePopup {
  left: -148px !important;
  top: 7px !important; }

.static {
  position: static !important; }

.addCustomer {
  width: 100%;
  min-height: 700px;
  background: #fff;
  border-radius: 3px;
  float: right; }

.hideTranslate {
  width: 0;
  height: 0;
  transform: translateX(105%);
  transition: all .5s;
  overflow: hidden;
  min-height: inherit !important; }

.hideTranslateOpen {
  width: 100;
  height: auto;
  transform: translateX(0%);
  transition: all .5s;
  overflow: hidden;
  float: right;
  padding-top: 20px; }

.formAction.quoteFormCustomer {
  position: fixed;
  bottom: 0;
  width: auto;
  padding-top: 0;
  padding-bottom: 20px;
  left: 80px;
  right: 30px;
  padding-left: 0;
  padding-right: 0; }

.full-section.openCustomerForm > div {
  height: auto !important;
  min-height: inherit; }

.full-section.openCustomerForm .main-wraper .main-content .right-section .wraper .content-scroll-body.edit-mode {
  padding: 0; }

.full-section.openCustomerForm .main-wraper .main-content {
  padding: 0; }

.full-section.openCustomerForm #Preferences {
  padding-bottom: 60px; }

.full-section.openCustomerForm .footer-section.fixedfooter {
  position: fixed !important;
  margin: 0 !important;
  padding: 12px 0 0 0 !important; }

.full-section.openCustomerForm .page-title {
  display: none; }

.DialogBox form[name="ShipmentProductForm"] .col-grid2 > .col:first-child {
  padding-right: 0px; }

.DialogBox form[name="ShipmentProductForm"] .col-grid2 > .col.dimension input {
  padding-right: 0px !important;
  padding-left: 8px !important; }

.DialogBox form[name="ShipmentProductForm"] .col-grid2 > .col.dimension > .col {
  width: 14%; }
  .DialogBox form[name="ShipmentProductForm"] .col-grid2 > .col.dimension > .col.division {
    width: 4%; }
  .DialogBox form[name="ShipmentProductForm"] .col-grid2 > .col.dimension > .col.custom-select {
    width: 21%; }
  .DialogBox form[name="ShipmentProductForm"] .col-grid2 > .col.dimension > .col.or {
    width: 4%; }

.tooltiptextBookshipment {
  z-index: 3 !important;
  text-transform: none !important;
  text-align: left !important;
  color: #fff !important;
  width: 260px !important;
  margin-left: -100px !important;
  padding: 10px !important; }
  .tooltiptextBookshipment em {
    color: #fff !important; }

.white-section.search-cutomer .filter-title-row {
  position: relative;
  padding-right: 40px;
  padding-left: 90px;
  min-height: 40px;
  vertical-align: top; }
  .white-section.search-cutomer .filter-title-row button {
    position: absolute;
    left: 15px;
    top: -2px; }
  .white-section.search-cutomer .filter-title-row h2 {
    position: absolute;
    left: 40px;
    top: 0px; }
  .white-section.search-cutomer .filter-title-row .chips-section {
    display: inline-block;
    float: none;
    width: auto;
    margin-right: 0px;
    margin-left: 10px;
    vertical-align: top;
    margin-top: -4px; }
  .white-section.search-cutomer .filter-title-row .collapsible {
    position: absolute;
    right: 15px;
    top: -2px;
    z-index: 1; }

fieldset#trackingInformation .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:last-child .indicator {
  margin-top: 0px;
  border-radius: 10px; }

@media print {
  @page {
    size: letter;
    margin: 20px 10px 0px 20px; }
  body {
    zoom: 74%;
    width: 100%;
    overflow: visible; }
  .DialogBox > div[role=document] {
    margin: 0;
    padding: 0;
    max-width: 100% !important;
    box-shadow: none !important; }
  .DialogBox.large {
    display: none;
    background: red;
    -webkit-print-color-adjust: exact; }
  .DialogBox.mediumPopup.viewReceiptDialog {
    position: static;
    display: block;
    float: left; }
  #root {
    display: none; }
  #scroll-dialog-title {
    display: none; }
  .DialogBox.mediumPopup.viewReceiptDialog .DialogBoxcontent {
    overflow: hidden;
    padding: 0 !important;
    margin: 0 !important;
    height: 100%; }
  .DialogBox.mediumPopup.viewReceiptDialog .defaultTable th, .DialogBox.mediumPopup.viewReceiptDialog .defaultTable td, .DialogBox.mediumPopup.viewReceiptDialog .emptyCellTable th, .DialogBox.mediumPopup.viewReceiptDialog .emptyCellTable td, .DialogBox.mediumPopup.viewReceiptDialog .clearHeaderTable th, .DialogBox.mediumPopup.viewReceiptDialog .clearHeaderTable td {
    -webkit-print-color-adjust: exact;
    color-adjust: exact; }
  .DialogBox.mediumPopup.viewReceiptDialog .divider {
    height: 1px;
    border-top: 1px dashed black; }
  .DialogBox.mediumPopup.viewReceiptDialog .viewPage {
    box-shadow: none;
    padding: 0;
    height: 1390px;
    overflow: hidden;
    margin-bottom: 0; }
    .DialogBox.mediumPopup.viewReceiptDialog .viewPage:last-child {
      margin-bottom: 0; }
    .DialogBox.mediumPopup.viewReceiptDialog .viewPage .totalAmountBlock {
      padding-top: 55px; }
    .DialogBox.mediumPopup.viewReceiptDialog .viewPage .totalAmountBlockWithoutDescription {
      padding-top: 65px; } }

.DialogBox.mediumPopup.viewReceiptDialog > div[role=document] {
  max-width: 1165px; }

.DialogBox.mediumPopup.viewReceiptDialog .DialogBoxcontent {
  background: gray;
  padding: 24px 24px 20px; }

.viewPage {
  color: black;
  font-size: 10px;
  -webkit-box-shadow: -1px 1px 8px -2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -1px 1px 8px -2px rgba(34, 60, 80, 0.2);
  box-shadow: -1px 1px 8px -2px rgba(34, 60, 80, 0.4);
  padding: 20px;
  margin-bottom: 30px;
  height: 1424px;
  overflow: hidden;
  background: white; }
  .viewPage * {
    font-family: sans-serif; }
  .viewPage:last-child {
    margin-bottom: 0; }
  .viewPage .captionLogo {
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase; }
  .viewPage .logoBlock {
    width: 320px;
    margin-top: 5px; }
    .viewPage .logoBlock .logo {
      width: 100%; }
    .viewPage .logoBlock .subtextLogo {
      line-height: 1.3; }
      .viewPage .logoBlock .subtextLogo .subtextLogoBlock {
        text-align: center;
        margin-left: 40px;
        width: 165px;
        font-size: 12px;
        margin-top: 10px; }
  .viewPage .defaultTable {
    border-collapse: collapse;
    width: 100%; }
    .viewPage .defaultTable th {
      background-color: #000;
      color: white;
      font-weight: bold;
      width: 33.33%;
      text-transform: uppercase; }
    .viewPage .defaultTable td, .viewPage .defaultTable th {
      border: 1px solid #dfe0e2;
      text-align: center;
      line-height: 1.5; }
    .viewPage .defaultTable th {
      border-top: 0;
      padding-top: 10px;
      padding-bottom: 10px; }
    .viewPage .defaultTable td {
      font-size: 14px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .viewPage .defaultTable .balanceDue td {
      font-size: 17px;
      font-weight: bold; }
  .viewPage .defaultTable.totalAmount td {
    text-align: left;
    padding-left: 15px; }
    .viewPage .defaultTable.totalAmount td:nth-child(2) {
      width: 39%; }
  .viewPage .emptyCellTable {
    border-collapse: collapse;
    width: 100%;
    height: 436px;
    border-bottom: 1px solid #dfe0e2; }
    .viewPage .emptyCellTable th {
      background-color: #000;
      color: white;
      font-weight: bold;
      width: 33.33%;
      height: 35px;
      text-transform: uppercase; }
    .viewPage .emptyCellTable td, .viewPage .emptyCellTable th {
      text-align: center;
      line-height: 1.5;
      border-left: 1px solid #dfe0e2;
      border-right: 1px solid #dfe0e2; }
    .viewPage .emptyCellTable th {
      padding-top: 10px;
      padding-bottom: 10px; }
      .viewPage .emptyCellTable th:nth-child(4), .viewPage .emptyCellTable th:nth-child(5) {
        text-align: left;
        padding-left: 15px;
        padding-right: 15px; }
    .viewPage .emptyCellTable td {
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .viewPage .emptyCellTable tr {
      height: 25px; }
      .viewPage .emptyCellTable tr:last-child {
        height: auto; }
      .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(4) {
        text-align: left !important;
        padding-left: 15px;
        padding-right: 15px; }
      .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(1), .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(2), .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(3) {
        text-align: center;
        padding-left: 15px;
        padding-right: 15px; }
      .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(5) {
        text-align: left;
        padding-left: 15px; }
      .viewPage .emptyCellTable tr td {
        vertical-align: top; }
        .viewPage .emptyCellTable tr td:nth-child(3), .viewPage .emptyCellTable tr td:nth-child(2) {
          text-align: left;
          padding-left: 15px; }
  .viewPage .clearHeaderTable {
    border-collapse: collapse;
    width: 100%; }
    .viewPage .clearHeaderTable th {
      background-color: #000;
      color: white;
      width: 33.33%;
      font-weight: bold;
      text-transform: uppercase; }
    .viewPage .clearHeaderTable td, .viewPage .clearHeaderTable th {
      line-height: 1.5; }
    .viewPage .clearHeaderTable td {
      border: 1px solid #dfe0e2; }
    .viewPage .clearHeaderTable th {
      text-align: left;
      padding-left: 15px;
      border-top: 0;
      padding-top: 10px;
      padding-bottom: 10px; }
    .viewPage .clearHeaderTable td {
      vertical-align: top;
      font-size: 12px;
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 5px; }
  .viewPage .blockText {
    font-size: 14px;
    line-height: 1.5; }
    .viewPage .blockText .caption {
      font-weight: bold; }
  .viewPage .divider {
    height: 1px;
    border-top: 1px dashed black; }
  .viewPage .shipmentCaptionBlock {
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 8px; }
    .viewPage .shipmentCaptionBlock .shipmentCaption {
      border-bottom: 2px solid black;
      padding-bottom: 3px; }
  .viewPage .shipmentCaptionDesc {
    line-height: 1.5;
    width: 720px;
    margin: 0 auto;
    font-size: 12px; }
  .viewPage .totalAmountBlock {
    width: 259px;
    padding-top: 45px;
    margin-left: auto; }
  .viewPage .totalAmountBlockWithoutDescription {
    width: 224px;
    padding-top: 60px;
    margin-left: auto; }
  .viewPage .statusBlock {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-right: 10px;
    height: 29px; }
    .viewPage .statusBlock .status {
      font-size: 24px;
      color: red;
      text-transform: uppercase;
      font-weight: bold; }
  .viewPage .textBlock {
    height: 24px;
    text-align: right; }
    .viewPage .textBlock .infoText {
      font-size: 10px; }
    .viewPage .textBlock .noPaymentText {
      text-align: right;
      font-weight: bolder;
      font-size: 12px;
      text-transform: uppercase; }
  .viewPage .returnAlert {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 80px;
    margin-bottom: 10px; }
  .viewPage .footerBlock {
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
    margin-top: 10px; }
  .viewPage .tableBlock {
    height: 645px;
    overflow: hidden; }
  .viewPage .tableBlockWithoutDescription {
    height: 681px;
    overflow: hidden; }
  .viewPage .customerInformation {
    height: 266px;
    overflow: hidden; }
  .viewPage .date {
    font-size: 12px; }
  .viewPage .adjustedInvoiceDescription {
    font-size: 18px;
    line-height: 1.5;
    height: 835px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px; }

@media screen and (min-width: 950px) and (max-width: 1220px) {
  .DialogBox.viewReceiptDialog .scalingBox {
    zoom: 75%; } }

@media screen and (min-width: 725px) and (max-width: 949px) {
  .DialogBox.viewReceiptDialog .scalingBox {
    zoom: 60%; } }

@media screen and (min-width: 570px) and (max-width: 724px) {
  .DialogBox.viewReceiptDialog .scalingBox {
    zoom: 45%; } }

@media screen and (max-width: 570px) {
  .DialogBox.viewReceiptDialog .scalingBox {
    zoom: 22%; } }

.managementButtons {
  position: absolute;
  right: 0; }
  @media (max-width: 769px) {
    .managementButtons {
      top: -50px !important; } }

.btnCancelShipment {
  padding: 0 16px !important;
  border-radius: 35px !important;
  margin-right: 10px !important;
  min-height: 31px !important; }
  .btnCancelShipment span {
    font-size: 10px; }
  .btnCancelShipment i {
    margin-right: 5px; }

.btnSaveShipment {
  padding: 0 16px !important;
  border-radius: 35px !important;
  min-height: 31px !important; }
  .btnSaveShipment span {
    font-size: 10px; }
  .btnSaveShipment img {
    margin-right: 5px; }

.loadBoardPopup .subtitle {
  color: #000;
  font-weight: normal;
  font-size: 17px;
  margin-bottom: 14px; }

.loadBoardPopup .fieldSection {
  border: 1px solid #e6eaee;
  background: #f9f9f9;
  border-radius: 3px;
  padding: 20px 20px 10px 20px; }
  .loadBoardPopup .fieldSection.right {
    height: 608px; }

.loadBoardPopup .fieldName {
  padding: 8px 0;
  height: 40px; }
  .loadBoardPopup .fieldName.top {
    margin-top: -56px; }

.loadBoardPopup .fieldLabel {
  border: 1px solid #e6eaee;
  background: #fff;
  height: 40px;
  font-size: 14px;
  padding: 8px 10px; }
  .loadBoardPopup .fieldLabel.multiline {
    height: 115px; }
    .loadBoardPopup .fieldLabel.multiline div {
      padding-top: 0; }
      .loadBoardPopup .fieldLabel.multiline div:hover::before {
        border-bottom: none !important; }
      .loadBoardPopup .fieldLabel.multiline div:before {
        border-bottom: none !important; }
      .loadBoardPopup .fieldLabel.multiline div:after {
        border-bottom: none !important; }
  .loadBoardPopup .fieldLabel textarea {
    overflow: hidden;
    padding-bottom: 20px !important; }

.loadBoardPopup .fieldText {
  color: #777777;
  font-size: 12px;
  text-align: right;
  margin-top: -10px;
  width: 100%; }

.loadBoardPopup .setting {
  margin-top: -5px; }
  .loadBoardPopup .setting span {
    font-size: 16px; }

.loadBoardPopup .gridItemLeft {
  padding: 0 12px 0 24px; }

.loadBoardPopup .gridItemRight {
  padding: 0 40px 0 12px; }

.loadBoardPopup .gridItem {
  padding-bottom: 14px; }

.transaction-list {
  float: left;
  width: 300px; }
  .transaction-list h3 {
    margin-top: 20px;
    margin-bottom: 10px; }
  .transaction-list .transaction {
    background: #f9f9f9;
    border-top: 1px solid #e6eaee;
    border-left: 1px solid #e6eaee;
    border-right: 1px solid #e6eaee; }
    .transaction-list .transaction:last-child {
      border-bottom: 1px solid #e6eaee; }
    .transaction-list .transaction label {
      margin: 0; }
    .transaction-list .transaction .name span {
      font-family: "latobold" !important;
      font-size: 12px; }
    .transaction-list .transaction .value {
      padding-right: 15px;
      font-size: 14px; }
  .transaction-list .text {
    font-size: 12px;
    padding: 10px 0; }

:root {
  --meridiem-selected-bg-color: #0093d0;
  --meridiem-selected-text-color: white;
  --top-selected-color: #0093d0;
  --clock-wrapper-bg: white;
  --clock-bg: #f4f4f4;
  --hand-circle-outer: #e4e4e4;
  --hand-line-color: #e4e4e4;
  --hand-minute-circle: #8C8C8C;
  --hand-circle-center: #8C8C8C;
  --numbers-text-color: #4a4a4a;
  --top-text-color: #4a4a4a;
  --top-meridiem-color: #4a4a4a;
  --top-colon-color: #4a4a4a;
  --meridiem-text-color: #4a4a4a; }

.react-timekeeper {
  border: 1px solid #e1e1e1 !important;
  box-shadow: 0px 2px 4px 1px #0000000d !important; }

.react-timekeeper__top-bar {
  background: #f4f4f4 !important;
  padding: 6px 16px !important; }
  .react-timekeeper__top-bar span {
    font-size: 40px !important; }

.react-timekeeper__dropdown-number:hover {
  background: #f3f3f3 !important; }

.react-timekeeper__dropdown-number--active {
  background: #0093d0 !important;
  color: white !important; }

.react-timekeeper__dropdown-number--active:hover {
  background: #0093d0 !important;
  color: white !important; }

.react-timekeeper__clock-wrapper {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }

.main-panel .white-section.search-cutomer .searchbaar.open {
  overflow: initial !important; }

.main-panel .main-wraper .main-content .stickySection .filterBar,
.main-panel .main-wraper .main-content .stickySection .gridFilterHead.rowClicked .col-grid11.col-fullwidth.userlist-head {
  position: static !important; }
  .main-panel .main-wraper .main-content .stickySection .filterBar .col,
  .main-panel .main-wraper .main-content .stickySection .filterBar .col i:before,
  .main-panel .main-wraper .main-content .stickySection .gridFilterHead.rowClicked .col-grid11.col-fullwidth.userlist-head .col,
  .main-panel .main-wraper .main-content .stickySection .gridFilterHead.rowClicked .col-grid11.col-fullwidth.userlist-head .col i:before {
    position: static !important; }

.main-panel .main-wraper .main-content #heightAutoId > .userList.myCustomer.heightAuto.shipment {
  position: static !important; }
  .main-panel .main-wraper .main-content #heightAutoId > .userList.myCustomer.heightAuto.shipment ul,
  .main-panel .main-wraper .main-content #heightAutoId > .userList.myCustomer.heightAuto.shipment ul li,
  .main-panel .main-wraper .main-content #heightAutoId > .userList.myCustomer.heightAuto.shipment ul li .col {
    position: static !important; }

.hr {
  border-bottom: 1px solid #ececec; }

.strip-content .col-grid2:first-child,
.strip-content .col-grid3:first-child {
  margin-top: 10px; }

.tl-map-wrapper {
  width: 100%;
  float: left;
  padding-bottom: 20px;
  position: relative; }

.shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .col-grid2 .tl-tracking-header_location {
  padding-right: 0 !important; }

.customer-email-marketing__list td {
  padding-bottom: 0px !important; }

.customer-email-marketing__list label {
  height: 24px; }

.customer-email-marketing__header td {
  padding-top: 5px !important; }

.customer-email-marketing__grid {
  margin-bottom: 0px !important; }
  .customer-email-marketing__grid .pagination-wraper {
    padding: 0px !important; }
  .customer-email-marketing__grid .paginations-set {
    margin-bottom: 0px !important; }

.paymentPopup button {
  padding-left: 25px !important;
  padding-right: 25px !important;
  min-width: 154px !important; }
  .paymentPopup button span {
    font-family: 'latobold' !important;
    font-weight: normal !important; }
  .paymentPopup button i:before {
    font-size: 14px;
    margin-right: 12px; }

.paymentPopup h3 {
  font-family: 'latobold';
  color: #000;
  font-weight: normal; }

.paymentPopup .DialogTitle {
  float: left;
  width: 100%;
  padding-bottom: 8px; }
  .paymentPopup .DialogTitle .h2 {
    font-size: 18px;
    font-family: 'latobold';
    color: #000;
    font-weight: normal;
    line-height: 22px;
    float: left;
    padding-right: 12px; }
  .paymentPopup .DialogTitle .message-success {
    font-size: 14px;
    font-family: 'latobold';
    color: #8bc34a;
    line-height: 22px;
    float: left; }
    .paymentPopup .DialogTitle .message-success i:before {
      color: #8bc34a;
      font-size: 14px;
      vertical-align: top;
      position: relative;
      top: 0px;
      margin-left: 8px;
      margin-right: 4px; }

.paymentPopup .DialogBoxcontent {
  padding: 10px 24px 10px 24px; }

.paymentPopup .popupOneColLeft {
  width: 100%;
  float: left; }
  .paymentPopup .popupOneColLeft .lefeSidebaar {
    width: 300px;
    float: left; }
    .paymentPopup .popupOneColLeft .lefeSidebaar p {
      font-size: 14px;
      color: #4a4a4a;
      margin-bottom: 12px;
      padding-right: 8px; }
    .paymentPopup .popupOneColLeft .lefeSidebaar .visa-img h3 {
      margin-bottom: 10px; }
    .paymentPopup .popupOneColLeft .lefeSidebaar .visa-img img {
      display: inline-block;
      margin-right: 8px; }
  .paymentPopup .popupOneColLeft .mainContent {
    float: none;
    padding-left: 320px; }
    .paymentPopup .popupOneColLeft .mainContent .wraper {
      width: 100%;
      float: left; }
      .paymentPopup .popupOneColLeft .mainContent .wraper .section-block {
        width: 100%;
        float: left; }
        .paymentPopup .popupOneColLeft .mainContent .wraper .section-block fieldset {
          margin: 0;
          padding: 0; }
        .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer {
          float: left;
          width: 100%;
          border-radius: 3px;
          -webkit-radius: 3px; }
          .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps {
            float: left;
            width: 100%; }
            .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head {
              float: left;
              width: 100%;
              border: 1px solid #e6eaee;
              border-bottom: 0;
              padding: 10px 16px; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-name {
                float: left;
                font-size: 14px;
                color: #000;
                padding-right: 40px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-name img {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 6px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-name .date {
                  font-style: normal;
                  font-family: 'latobold';
                  font-size: 14px;
                  padding-left: 10px; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-type {
                float: left; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-type samp {
                  font-style: normal;
                  font-size: 14px;
                  vertical-align: top; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .detail {
                float: right; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .detail a {
                  font-size: 12px;
                  vertical-align: middle;
                  font-family: 'latobold';
                  font-style: normal; }
                  .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .detail a i:before {
                    color: #0093d0;
                    font-size: 18px;
                    vertical-align: top;
                    position: relative;
                    top: -2px;
                    margin-left: 8px; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head:last-child {
                border-bottom: 1px solid #e6eaee;
                margin-top: -1px; }
            .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content {
              float: left;
              width: 100%;
              background: #f9f9f9;
              padding: 14px 0px 0;
              border: 1px solid #dfdfe4; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row {
                float: left;
                width: 100%;
                padding-left: 16px;
                padding-right: 16px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row .labelTxt {
                  font-size: 12px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row.section {
                  border-top: 1px solid #dfdfe4;
                  padding-top: 15px;
                  margin-top: 6px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row .inputField {
                  float: left;
                  width: 100%;
                  margin-bottom: 6px; }
                  .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row .inputField .label {
                    width: 140px;
                    float: left;
                    color: #9b9b9b;
                    font-size: 14px;
                    padding-top: 3px; }
                  .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row .inputField .labelData {
                    width: auto;
                    float: none;
                    padding-left: 140px; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .button-set {
                border-top: 1px solid #e6eaee;
                padding: 15px 0;
                margin-top: 14px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .button-set button {
                  margin: 0 15px; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detail {
                position: relative; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detail:before {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #dfdfe4;
                  position: absolute;
                  top: -20px;
                  right: 38px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detail:after {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #f9f9f9;
                  position: absolute;
                  top: -19px;
                  right: 38px; }
              .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit {
                position: relative; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit:before {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #dfdfe4;
                  position: absolute;
                  top: -20px;
                  left: 104px; }
                .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit:after {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #f9f9f9;
                  position: absolute;
                  top: -19px;
                  left: 104px; }
            .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted .cart-name, .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted .label, .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted .labelData, .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted .cart-type, .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted samp, .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted span {
              color: #9b9b9b !important; }
            .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted img, .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted i.zmdi-check-circle, .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted input[type="text"], .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted a {
              opacity: 0.3; }
            .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps.deleted .deletedInfo {
              margin: -6px 0 0 48px;
              font-size: 14px;
              white-space: nowrap; }

.paymentPopup .col-grid1 .col label.checkBoxWrap {
  margin-bottom: 0;
  margin-left: -8px; }
  .paymentPopup .col-grid1 .col label.checkBoxWrap > span:first-child {
    width: 40px;
    height: 40px; }

.paymentPopup .col-grid2 .col {
  padding-right: 10px; }
  .paymentPopup .col-grid2 .col:last-child {
    padding-left: 10px; }

.primaryStatus i:before {
  color: #0093d0;
  font-size: 14px;
  vertical-align: top;
  position: relative;
  top: -2px;
  margin-right: 4px; }

.scrollDiv {
  border-top: 1px solid #e6eaee;
  max-height: 1280px;
  overflow: auto; }

.scrollDivDetail {
  border: 1px solid #e6eaee;
  overflow: auto;
  padding: 15px; }

.button-set.grayBox {
  border: 1px solid #ddd;
  border-top: 0px solid #ddd;
  background: #f9f9f9;
  padding: 10px;
  margin: 0; }
  .button-set.grayBox button {
    margin: 0 15px; }

.order-summary-section {
  width: 100%;
  float: left;
  border: 1px solid #e6eaee;
  background: #f9f9f9;
  border-radius: 3px;
  margin-bottom: 10px; }
  .order-summary-section .content {
    width: 100%;
    float: left; }
    .order-summary-section .content .row {
      width: 100%;
      float: left;
      margin-bottom: 8px; }
      .order-summary-section .content .row .label-text {
        float: left;
        width: 65%;
        font-size: 12px;
        color: #4a4a4a;
        word-break: break-word;
        line-height: 1.5; }
      .order-summary-section .content .row .label-value {
        float: left;
        width: 35%;
        text-align: right;
        font-size: 12px;
        color: #000;
        word-break: break-word; }
      .order-summary-section .content .row:last-child {
        margin-bottom: 0; }
      .order-summary-section .content .row.subtotal .label-text {
        color: #000;
        font-family: 'latobold'; }
      .order-summary-section .content .row.subtotal .label-value {
        color: #000;
        font-family: 'latobold'; }
    .order-summary-section .content.coupan {
      border-top: 2px solid #d6d6d6;
      margin-top: 6px;
      padding-top: 6px; }
      .order-summary-section .content.coupan .row .label-text {
        color: #000; }
      .order-summary-section .content.coupan .row .label-value {
        color: #ff6868; }
    .order-summary-section .content.discount .row .label-text {
      width: 60%;
      float: left; }
    .order-summary-section .content.discount .row .label-value {
      width: 40%;
      float: left; }
      .order-summary-section .content.discount .row .label-value button {
        min-width: inherit !important;
        margin-top: 12px; }
    .order-summary-section .content.total {
      border-top: 2px solid #d6d6d6;
      margin-top: 6px;
      padding-top: 6px; }
      .order-summary-section .content.total .row .label-text {
        color: #000;
        font-family: 'latobold';
        width: 62%; }
        .order-summary-section .content.total .row .label-text.cc-fee {
          font-family: 'latoregular', sans-serif;
          color: #4a4a4a; }
      .order-summary-section .content.total .row .label-value {
        color: #000;
        font-size: 14px;
        font-family: 'latobold';
        width: 38%; }
        .order-summary-section .content.total .row .label-value.cc-fee {
          font-family: 'latoregular', sans-serif;
          color: #4a4a4a;
          font-size: 12px; }
  .order-summary-section .order-summary-head {
    width: 100%;
    float: left;
    padding: 8px 15px;
    border-bottom: 1px solid #e6eaee; }
    .order-summary-section .order-summary-head .row .label-text {
      font-family: 'latobold';
      color: #000;
      width: 62%;
      font-size: 12px;
      float: left; }
    .order-summary-section .order-summary-head .row .label-value {
      font-family: 'latobold';
      color: #000;
      font-size: 14px;
      width: 38%;
      text-align: right;
      float: left; }
  .order-summary-section .order-summary-content {
    width: 100%;
    float: left;
    padding: 10px 15px; }
    .order-summary-section .order-summary-content i:before {
      font-size: 16px;
      vertical-align: middle;
      padding-right: 5px;
      color: #b5b5b5; }
  .order-summary-section .order-cancellation {
    width: 100%;
    float: left;
    padding: 8px 15px;
    border-bottom: 1px solid #e6eaee; }
    .order-summary-section .order-cancellation .row .label-text {
      color: #000;
      width: 62%;
      font-size: 12px;
      float: left;
      padding-top: 3px; }
    .order-summary-section .order-cancellation .row .label-value {
      padding-left: 15px;
      color: #000;
      font-size: 14px;
      width: 38%;
      text-align: right;
      float: left; }
      .order-summary-section .order-cancellation .row .label-value input {
        text-align: right;
        padding-right: 10px !important; }

.message-success {
  font-size: 14px;
  font-family: 'latobold';
  color: #8bc34a;
  line-height: 22px;
  float: left; }
  .message-success i:before {
    color: #8bc34a;
    font-size: 14px;
    vertical-align: top;
    position: relative;
    top: 0px;
    margin-right: 4px; }

.card-section-outer.shipment .card-steps .strip-head {
  height: 46px; }
  .card-section-outer.shipment .card-steps .strip-head .cart-checkbox {
    float: left; }
    .card-section-outer.shipment .card-steps .strip-head .cart-checkbox .checkBoxWrap {
      margin: -8px 0 0 -10px; }
      .card-section-outer.shipment .card-steps .strip-head .cart-checkbox .checkBoxWrap span:first-child {
        width: 40px;
        height: 40px; }

.card-section-outer.shipment .card-steps .strip-content {
  padding: 0 !important;
  margin: 0; }
  .card-section-outer.shipment .card-steps .strip-content .row.section {
    border: 0 !important;
    border-bottom: 1px solid #dfdfe4 !important;
    padding: 0 !important;
    margin: 0 !important; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox {
      float: left;
      margin-top: 6px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox .checkBoxWrap {
        margin: 0 0 0 7px; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox .checkBoxWrap span:first-child {
          width: 40px;
          height: 40px; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox__store-credit {
      float: left;
      padding-top: 15px !important; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox__store-credit .checkBoxWrap {
        margin: 0 0 0 7px; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox__store-credit .checkBoxWrap span:first-child {
          width: 40px;
          height: 40px; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail {
      float: left;
      margin-top: 9px;
      width: 66%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail.cancellation {
        width: 50%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail img {
        float: left;
        margin-right: 8px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail samp {
        color: #000;
        font-style: normal;
        float: left;
        line-height: 30px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .name {
        font-size: 14px;
        color: #000; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .expiration {
        margin: -6px 0 0 48px; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .expiration.cc-fee-text {
          margin-top: 5px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .date {
        font-size: 14px;
        font-family: 'latobold';
        padding-left: 8px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .primary {
        font-size: 14px;
        font-family: 'latobold';
        padding-left: 8px;
        float: right; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail__store-credit {
      float: left;
      margin-top: 20px;
      width: 66%;
      margin-left: 45px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail__store-credit img {
        float: left;
        margin-right: 8px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail__store-credit samp {
        color: #000;
        font-style: normal;
        float: left;
        line-height: 30px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail__store-credit .name {
        font-size: 14px;
        color: #000; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee {
      float: right;
      width: 20%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee span {
        font-size: 12px;
        color: #4a4a4a;
        padding-right: 2%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field {
        background: #fff;
        padding: 0 5px;
        border: 1px solid #eee;
        width: 93%; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field input {
          border: 0; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputField > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputFieldrdonly > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputPlaceholder > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputFieldError > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputFieldImp > div:before {
          border: 0 !important; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputField > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputFieldrdonly > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputPlaceholder > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputFieldError > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .cancellationFee .white-field .form-inputFieldImp > div:after {
          border: 0 !important; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .amount {
      float: right;
      width: 23%;
      margin-bottom: 6px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .amount.cancellation {
        width: 20%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .amount.cc-fee {
        font-size: 12px;
        text-align: center;
        margin-top: 5px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .amount span:first-child {
        font-size: 12px;
        color: #4a4a4a;
        padding-right: 2%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field {
        background: #fff;
        padding: 0 5px;
        border: 1px solid #eee;
        width: 93%; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field input {
          border: 0; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputField > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputFieldrdonly > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputPlaceholder > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputFieldError > div:before, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputFieldImp > div:before {
          border: 0 !important; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputField > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputFieldrdonly > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputPlaceholder > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputFieldError > div:after, .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputFieldImp > div:after {
          border: 0 !important; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .detail {
      clear: both;
      text-align: right; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .detail a {
        font-size: 14px;
        margin-right: 15px;
        margin-bottom: 2px !important;
        display: inline-block; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .detail a i {
          vertical-align: middle;
          padding-left: 4px; }
  .card-section-outer.shipment .card-steps .strip-content .row:last-child {
    border: 0 !important; }

.card-section-outer.shipment.disabled samp {
  color: #00000080 !important; }

.card-section-outer.shipment.disabled .detail .collapsible i {
  color: #00000080 !important;
  cursor: default; }
  .card-section-outer.shipment.disabled .detail .collapsible i:hover {
    border: 1px solid #ddd;
    border-radius: 3px;
    background: white !important; }
    .card-section-outer.shipment.disabled .detail .collapsible i:hover:before {
      color: #00000080 !important; }

.card-section-outer .credit-card-empty {
  text-align: center;
  font-size: 12px;
  padding: 10px;
  color: gray; }
  .card-section-outer .credit-card-empty div:first-child {
    color: #989898;
    line-height: 1px; }

.white-field {
  width: 92px; }
  .white-field .form-inputField > div:first-child:before, .white-field .form-inputFieldrdonly > div:first-child:before, .white-field .form-inputPlaceholder > div:first-child:before, .white-field .form-inputFieldError > div:first-child:before, .white-field .form-inputFieldImp > div:first-child:before {
    border: 0 !important; }
  .white-field .form-inputField > div:first-child:after, .white-field .form-inputFieldrdonly > div:first-child:after, .white-field .form-inputPlaceholder > div:first-child:after, .white-field .form-inputFieldError > div:first-child:after, .white-field .form-inputFieldImp > div:first-child:after {
    border: 0 !important; }
  .white-field .form-inputField > div:first-child input, .white-field .form-inputFieldrdonly > div:first-child input, .white-field .form-inputPlaceholder > div:first-child input, .white-field .form-inputFieldError > div:first-child input, .white-field .form-inputFieldImp > div:first-child input {
    background: #fff;
    border: 1px solid #f4f4f6;
    text-align: center;
    font-size: 12px !important; }

.paymentPopup .popupOneColLeft .mainContent .wraper .section-block .radio-col span + span {
  color: #000;
  font-size: 14px; }

.paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .button.absPos {
  position: absolute;
  bottom: 8px;
  right: 30px; }

.DialogBoxfooter.payment {
  height: 52px; }

.DialogBox fieldset.totalRow .inputField {
  float: none;
  background: #f9f9f9;
  border: 1px solid #e6eaee;
  border-radius: 3px;
  margin: 6px 0 !important;
  width: 100%;
  display: table; }
  .DialogBox fieldset.totalRow .inputField .label {
    float: none;
    font-family: 'latobold' !important;
    color: #000;
    font-weight: normal;
    margin: 0 0px 0 0;
    line-height: 34px;
    font-size: 14px;
    text-align: right;
    width: 81%;
    padding-right: 10px;
    display: table-cell; }
  .DialogBox fieldset.totalRow .inputField .labelData {
    float: none;
    width: 19%;
    font-family: 'latobold' !important;
    color: #000;
    font-weight: normal;
    line-height: 34px;
    margin: 0 0px 0 0;
    text-align: right;
    padding-right: 20px;
    display: table-cell; }

.bold12Txt {
  font-family: 'latobold' !important;
  font-size: 12px !important;
  color: #000 !important;
  font-weight: normal; }

.opacity5 {
  opacity: 0.5; }

.quoteProgressSection {
  width: 100%;
  float: left;
  min-height: 30px; }
  .quoteProgressSection .wraper {
    width: 100%;
    float: left;
    padding: 0px 30px 0;
    text-align: center; }
    .quoteProgressSection .wraper ul {
      margin: 0 auto;
      padding: 0;
      list-style: none; }
      .quoteProgressSection .wraper ul li {
        margin: 0 34px 0 0;
        padding: 0 10% 0 0;
        display: inline-block;
        position: relative; }
        .quoteProgressSection .wraper ul li:last-child {
          padding-right: 0;
          margin-right: 0; }
          .quoteProgressSection .wraper ul li:last-child:before {
            right: 6px; }
          .quoteProgressSection .wraper ul li:last-child a {
            padding-right: 0; }
        .quoteProgressSection .wraper ul li i {
          display: none;
          position: absolute;
          left: -26px;
          top: 3px; }
          .quoteProgressSection .wraper ul li i:before {
            color: #0093d0; }
        .quoteProgressSection .wraper ul li.active a {
          color: #0093d0; }
          .quoteProgressSection .wraper ul li.active a:before {
            border-color: #0093d0; }
        .quoteProgressSection .wraper ul li.done a {
          color: #0093d0; }
          .quoteProgressSection .wraper ul li.done a:before {
            border-color: #0093d0; }
        .quoteProgressSection .wraper ul li.done i {
          display: block; }
        .quoteProgressSection .wraper ul li a {
          display: block;
          font-family: 'latobold';
          font-size: 15px;
          position: relative;
          background: #C0C8D0;
          padding-right: 10px;
          color: #9b9b9b; }
          .quoteProgressSection .wraper ul li a:before {
            content: '';
            position: absolute;
            border: 2px solid #9b9b9b;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            top: 4px;
            left: -26px; }
        .quoteProgressSection .wraper ul li:before {
          content: '';
          position: absolute;
          width: 68%;
          height: 2px;
          background: #9b9b9b;
          left: inherit;
          right: -8px;
          top: 13px; }
        .quoteProgressSection .wraper ul li .edit_link {
          float: left;
          font-style: normal;
          color: #ff6c2f;
          font-size: 12px; }
    .quoteProgressSection .wraper .message12Txt {
      font-size: 12px;
      color: #4a4a4a;
      padding: 8px 0 0; }

.breadcrumbBar.quote {
  border: 0; }
  .breadcrumbBar.quote .breadcrumb .backLink {
    display: inline-block;
    margin-right: 20px; }
    .breadcrumbBar.quote .breadcrumb .backLink a {
      background: #fff;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 3px;
      text-align: center; }
      .breadcrumbBar.quote .breadcrumb .backLink a i {
        position: relative;
        top: 3px; }
        .breadcrumbBar.quote .breadcrumb .backLink a i:before {
          color: #4a4a4a; }
    .breadcrumbBar.quote .breadcrumb .backLink .tooltiptext {
      width: 60px;
      margin-left: -30px; }
  .breadcrumbBar.quote .breadcrumb li {
    vertical-align: middle; }

.white-section.quote {
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 25px; }
  .white-section.quote h1 {
    font-family: 'latobold';
    font-weight: normal;
    font-size: 18px;
    position: relative; }
    .white-section.quote h1 .closeBtn {
      position: absolute;
      left: -24px;
      top: -28px; }
  .white-section.quote h3 {
    font-family: 'latobold';
    font-weight: normal;
    color: #000; }
  .white-section.quote .fSize30 {
    font-size: 30px;
    line-height: 32px;
    font-family: 'latoregular'; }
  .white-section.quote .fSize15 {
    font-size: 15px;
    color: #4a4a4a;
    font-style: normal; }
  .white-section.quote .grayIcon {
    vertical-align: middle; }
    .white-section.quote .grayIcon:before {
      font-size: 15px;
      color: #c9c9c9;
      margin-left: 4px; }
  .white-section.quote .head-section {
    width: 100%;
    float: left; }
    .white-section.quote .head-section .col-grid2 .col {
      margin-bottom: 0; }
    .white-section.quote .head-section .iconlink {
      font-family: 'latobold';
      font-size: 14px;
      vertical-align: top;
      line-height: 18px;
      display: inline-block; }
      .white-section.quote .head-section .iconlink i {
        vertical-align: top;
        display: inline-block; }
        .white-section.quote .head-section .iconlink i:before {
          font-size: 18px;
          margin-right: 6px;
          vertical-align: top; }
  .white-section.quote .content-section {
    width: 100%;
    float: left; }
    .white-section.quote .content-section .item-detail {
      width: 100%;
      float: left; }
      .white-section.quote .content-section .item-detail .col-left {
        width: 79%;
        float: left;
        height: auto;
        padding: 0;
        min-height: 212px; }
        .white-section.quote .content-section .item-detail .col-left .row {
          width: 100%;
          float: left;
          margin-bottom: 20px; }
          .white-section.quote .content-section .item-detail .col-left .row .column1 {
            width: 25%;
            float: left; }
            .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 {
              width: 40px;
              float: right; }
              .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp label + div > div > div {
                padding-left: 0px !important;
                padding-right: 15px !important; }
                .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp label + div > div > div > div div[role="button"] {
                  background: transparent !important; }
                  .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp label + div > div > div > div div[role="button"] span {
                    padding-left: 0;
                    background: transparent; }
          .white-section.quote .content-section .item-detail .col-left .row .column3 {
            width: 75%;
            float: left;
            padding-left: 25px;
            padding-right: 25px; }
            .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension {
              width: 80%;
              float: left; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension .col {
                width: 25%;
                float: left;
                padding-right: 20px; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension .custom-select .formControl {
                margin-top: 0;
                margin-bottom: 0; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension a {
                font-family: 'latobold';
                font-size: 12px; }
            .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check {
              width: 20%;
              float: left;
              padding-top: 8px; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check label {
                margin: 0; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check .checkbox-lbl {
                margin-top: 0px; }
          .white-section.quote .content-section .item-detail .col-left .row .noteTxt {
            color: #9b9b9b;
            font-size: 11px;
            clear: both;
            max-width: 210px;
            line-height: 14px;
            padding-top: 6px; }
          .white-section.quote .content-section .item-detail .col-left .row .or {
            color: #9b9b9b;
            font-size: 11px;
            line-height: 14px;
            font-style: normal; }
      .white-section.quote .content-section .item-detail .col-right {
        width: 21%;
        float: left;
        padding: 0 0 0 25px;
        overflow: inherit; }
        .white-section.quote .content-section .item-detail .col-right .row {
          padding-top: 20px;
          width: 100%;
          float: left;
          padding-right: 20px;
          position: relative; }
          .white-section.quote .content-section .item-detail .col-right .row .label-highlight {
            background: #c9c9c9;
            border-bottom: 1px solid #9b9b9b;
            color: #4a4a4a;
            padding: 8px 12px;
            float: left;
            width: 100%;
            font-size: 14px; }
          .white-section.quote .content-section .item-detail .col-right .row .grayIcon {
            position: absolute;
            right: 0;
            top: 31px; }

.white-section.Extrashipmentservices label {
  margin-bottom: 2px; }

.icon-field {
  border: 0px solid #cccfd4;
  border-right: 0px;
  width: 25%;
  float: left;
  min-height: 35px;
  margin-top: 13px;
  border-right: 0px; }
  .icon-field:last-child {
    border: 1px solid #cccfd4;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .icon-field:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .icon-field.icon-field75 {
    width: 75%; }
    .icon-field.icon-field75 .radioGroupWrap {
      height: 33px;
      width: 67%;
      float: left; }
      .icon-field.icon-field75 .radioGroupWrap .radioGroup {
        display: block;
        width: 100%; }
        .icon-field.icon-field75 .radioGroupWrap .radioGroup .radio-col {
          margin: 0;
          width: 50%;
          float: left;
          height: 33px;
          border-right: 1px solid #cccfd4; }
          .icon-field.icon-field75 .radioGroupWrap .radioGroup .radio-col span:first-child {
            width: 30px;
            height: 30px;
            opacity: 0; }
          .icon-field.icon-field75 .radioGroupWrap .radioGroup .radio-col span:last-child {
            font-family: 'latobold' !important;
            font-size: 12px !important;
            width: 80%;
            text-align: center;
            padding-right: 24px; }
    .icon-field.icon-field75 .checkboxGroupSelect {
      width: 33%;
      float: left;
      background: url("./../../assets/images/icon-pallet.png") 10px 6px no-repeat; }
      .icon-field.icon-field75 .checkboxGroupSelect > .div {
        width: 100%;
        float: left; }
        .icon-field.icon-field75 .checkboxGroupSelect > .div.custom-select {
          border: 1px solid #cccfd4;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px; }
          .icon-field.icon-field75 .checkboxGroupSelect > .div.custom-select span {
            padding-left: 40px !important; }
      .icon-field.icon-field75 .checkboxGroupSelect label.checkBoxWrap {
        margin: 0; }
        .icon-field.icon-field75 .checkboxGroupSelect label.checkBoxWrap span:first-child {
          width: 33px;
          height: 33px; }
  .icon-field .formControl {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 0px; }
    .icon-field .formControl label + div {
      margin-top: 0px; }
      .icon-field .formControl label + div:before {
        border: 0px !important; }
      .icon-field .formControl label + div:after {
        border: 0px !important; }
      .icon-field .formControl label + div div[role="button"] span {
        color: #9b9b9b !important;
        font-family: 'latobold' !important;
        font-size: 12px !important; }
      .icon-field .formControl label + div div[aria-pressed] {
        padding-right: 20px !important; }
  .icon-field.pallet .radioGroupWrap .radioGroup label {
    border: 1px solid #cccfd4 !important;
    height: 35px !important;
    background: url("./../../assets/images/icon-create.png") 10px 6px no-repeat; }
    .icon-field.pallet .radioGroupWrap .radioGroup label.checked {
      background: #e5f4fa url("./../../assets/images/icon-create-act.png") 10px 6px no-repeat;
      border: 1px solid #2da6d8 !important; }
      .icon-field.pallet .radioGroupWrap .radioGroup label.checked span + span {
        color: #1a9ed4 !important; }
    .icon-field.pallet .radioGroupWrap .radioGroup label + label {
      background: url("./../../assets/images/icon-box.png") 10px 6px no-repeat; }
      .icon-field.pallet .radioGroupWrap .radioGroup label + label.checked {
        background: #e5f4fa url("./../../assets/images/icon-box-act.png") 10px 6px no-repeat; }
  .icon-field.other-unit-type .formSelectBoxImp label[data-shrink="true"] {
    transition: inherit !important;
    transform: inherit !important;
    font-family: 'latobold' !important;
    font-size: 12px !important;
    color: #9b9b9b !important;
    margin-top: 8px;
    padding-left: 10px !important; }
  .icon-field.other-unit-type .formControl label {
    transition: inherit !important;
    transform: inherit !important;
    font-family: 'latobold' !important;
    font-size: 12px !important;
    color: #9b9b9b !important;
    margin-top: 8px;
    padding-left: 10px !important; }
    .icon-field.other-unit-type .formControl label + div div[role="button"] span {
      padding-left: 20px !important; }

.checkboxGroupSelect .formControl label {
  transition: inherit !important;
  transform: inherit !important;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 12px !important;
  color: #9b9b9b !important;
  margin-top: 8px;
  padding-left: 36px !important; }
  .checkboxGroupSelect .formControl label + div div[role="button"] span {
    padding-left: 20px !important; }

.pickup-delivery-info h3 i {
  vertical-align: middle;
  line-height: 18px;
  cursor: pointer; }
  .pickup-delivery-info h3 i:before {
    color: #0093d0; }

.pickup-delivery-info .datePicker .calendar-icon {
  right: 0px; }

.pickup-delivery-info .pickupInfo:first-child {
  border-right: 1px solid #ddd; }

.pickup-delivery-info .col-grid2 .col:first-child {
  padding-right: 20px; }

.pickup-delivery-info .col-grid2 .col:last-child {
  padding-left: 20px; }

.pickup-delivery-info .fType {
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 15px; }
  .pickup-delivery-info .fType h4 {
    color: #000;
    font-family: 'latobold';
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 12px; }
  .pickup-delivery-info .fType .gCol1 .col {
    padding-right: 0;
    width: 100%; }
    .pickup-delivery-info .fType .gCol1 .col:last-child {
      padding-left: 0; }
    .pickup-delivery-info .fType .gCol1 .col .radioGroup {
      display: block;
      float: left;
      width: 100%; }
      .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col {
        margin-bottom: 12px;
        margin-right: 0;
        width: 50%;
        float: left;
        align-items: flex-start; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col:nth-child(2n) {
          padding-left: 40px; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col:nth-child(2n+1) {
          clear: both; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col > span:first-child {
          width: 40px;
          height: 40px;
          margin-top: -12px; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col > span + span {
          font-size: 14px;
          color: #000; }
    .pickup-delivery-info .fType .gCol1 .col .checkBoxWrap {
      margin-bottom: 0; }
      .pickup-delivery-info .fType .gCol1 .col .checkBoxWrap > span + span {
        font-size: 14px;
        color: #000;
        font-family: "latoregular", sans-serif !important;
        letter-spacing: 0.3px !important; }
    .pickup-delivery-info .fType .gCol1 .col .noteTxt {
      font-size: 13px;
      color: #4a4a4a;
      line-height: 20px; }
  .pickup-delivery-info .fType .gCol2 {
    width: 100%;
    float: left; }
    .pickup-delivery-info .fType .gCol2 .col {
      width: 50%;
      float: left;
      padding-right: 20px;
      margin-bottom: 0; }
      .pickup-delivery-info .fType .gCol2 .col:last-child {
        padding-left: 0px; }
      .pickup-delivery-info .fType .gCol2 .col:nth-child(2n) {
        padding-left: 20px; }
      .pickup-delivery-info .fType .gCol2 .col .radioGroup .radio-col {
        margin-bottom: 0;
        margin-right: 0; }
        .pickup-delivery-info .fType .gCol2 .col .radioGroup .radio-col > span:first-child {
          width: 40px;
          height: 40px; }
        .pickup-delivery-info .fType .gCol2 .col .radioGroup .radio-col > span + span {
          font-size: 14px;
          color: #000; }
  .pickup-delivery-info .fType.bdr-bottom {
    border-bottom: 1px solid #ddd; }

.a-link12 {
  cursor: pointer;
  font-size: 12px; }
  .a-link12:hover {
    color: #0093d0; }

.formControl.autosuggest {
  margin-top: 16px; }
  .formControl.autosuggest > label {
    transform: translate(-10px, -17px) scale(0.75); }
    .formControl.autosuggest > label + div {
      border: 0 !important;
      border-bottom: 1px solid #9b9b9b !important; }
      .formControl.autosuggest > label + div:before {
        border: 0 !important; }
      .formControl.autosuggest > label + div > div {
        border: 0 !important;
        border-bottom: 0px solid #9b9b9b !important;
        border-radius: 0;
        box-shadow: 0 0 0 #fff;
        background: none;
        min-height: inherit; }
        .formControl.autosuggest > label + div > div:hover {
          border-bottom: 0px solid #9b9b9b !important; }
        .formControl.autosuggest > label + div > div + div {
          border: 0 !important; }
        .formControl.autosuggest > label + div > div div[aria-hidden="true"] {
          padding: 4px;
          background: transparent;
          margin-left: -2px; }
          .formControl.autosuggest > label + div > div div[aria-hidden="true"]:before {
            content: '\F2F2';
            font-family: "Material-Design-Iconic-Font" !important;
            position: absolute;
            right: 4px;
            font-size: 25px;
            color: #4a4a4a;
            height: 22px; }
          .formControl.autosuggest > label + div > div div[aria-hidden="true"] svg {
            opacity: 0; }
        .formControl.autosuggest > label + div > div input {
          padding-top: 0 !important;
          padding-bottom: 0 !important; }
        .formControl.autosuggest > label + div > div > div {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          border: 0;
          background: transparent; }
          .formControl.autosuggest > label + div > div > div > div {
            color: #4a4a4a;
            font-family: "latoregular", sans-serif !important; }
            .formControl.autosuggest > label + div > div > div > div span {
              background: transparent; }
            .formControl.autosuggest > label + div > div > div > div > div:hover:before {
              border-bottom: 0 !important; }

.autosuggest > label + div > div > span[aria-live="assertive"] + div {
  box-shadow: 0 0 0 #fff !important;
  border: 0; }

.autosuggest > label + div > div > span[aria-live="assertive"] + div + div > div > div {
  color: #4a4a4a;
  padding: 4px 10px;
  font-size: 14px !important;
  line-height: 24px !important;
  font-family: "latobold", sans-serif !important;
  font-weight: normal !important; }

.autosuggest > label + div > div > div {
  min-height: 30px; }

.autosuggest > label + div > div > div > div > div {
  font-size: 14px;
  color: #4a4a4a; }

.react-select__option {
  background: #000 !important; }

.formControl.autosuggest > label + div > div > div:before {
  height: 24px; }

.formControl.autosuggest > label + div > div > span + div + div {
  z-index: 2;
  background: #fff; }

.requiredClass .formControl.autosuggest label + div {
  border-left: 2px solid #ff6868 !important; }

.formAction.quoteForm {
  padding: 15px; }
  .formAction.quoteForm .btnInactive.tooltipWrap {
    pointer-events: auto; }
    .formAction.quoteForm .btnInactive.tooltipWrap .tooltiptext {
      bottom: 40px;
      width: 106%;
      left: -3%;
      margin: 0;
      font-family: "latoregular", sans-serif;
      font-weight: normal !important;
      text-transform: none;
      font-size: 12px;
      text-align: left;
      padding: 8px 10px; }
      .formAction.quoteForm .btnInactive.tooltipWrap .tooltiptext:after {
        bottom: -10px;
        border-color: #000 transparent transparent transparent; }
  .formAction.quoteForm button {
    margin: 0 18px;
    min-width: 160px;
    max-width: 209px;
    padding-left: 0 !important;
    padding-right: 0 !important; }

.tooltipWrap {
  cursor: pointer; }
  .tooltipWrap .tooltiptext.stackable {
    bottom: 43px;
    top: inherit;
    width: 220px;
    line-height: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
    left: -86px; }
    .tooltipWrap .tooltiptext.stackable:after {
      bottom: -10px;
      border-color: #000 transparent transparent transparent; }
  .tooltipWrap .tooltiptext.hUnit {
    bottom: 33px;
    top: inherit;
    width: 230px;
    line-height: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
    left: 0; }
    .tooltipWrap .tooltiptext.hUnit:after {
      bottom: -10px;
      border-color: #000 transparent transparent transparent; }
  .tooltipWrap .tooltiptext.fClass {
    margin: 0;
    left: inherit;
    right: 20px;
    width: 240px;
    top: -10px;
    bottom: inherit;
    text-align: left;
    padding: 8px 10px;
    line-height: 14px; }
    .tooltipWrap .tooltiptext.fClass:after {
      bottom: inherit;
      margin: 0;
      top: 14px;
      height: 8px;
      border-color: transparent transparent transparent #000;
      left: inherit;
      right: -10px; }

.div.custom-select.selectSingle.active {
  border-color: #0093d0 !important;
  background: #e5f4fa url("./../../assets/images/icon-pallet-act.png") 10px 6px no-repeat !important; }

.div.custom-select.selectSingle.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] {
  border: 0; }

.div.custom-select.selectSingle.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  color: #0093d0 !important; }

.icon-field.other-unit-type.active {
  border-color: #0093d0 !important;
  background: #e5f4fa; }

.icon-field.other-unit-type.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] {
  border: 0; }

.icon-field.other-unit-type.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  color: #0093d0 !important; }

.closeBtn {
  background: #0093d0;
  border: 0;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 23px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer; }

.tooltipWrapFocus:focus .tooltiptext {
  visibility: visible;
  opacity: 1; }

.column1.focus .tooltipWrap .tooltiptext.hUnit {
  visibility: visible;
  opacity: 1; }

.tooltipWrap.focus .tooltiptext.stackable {
  visibility: visible;
  opacity: 1; }

.formAction.quoteForm button.focus .tooltiptext {
  visibility: visible;
  opacity: 1; }

/* quote form focus css */
.icon-field.icon-field75 .radioGroupWrap > .focusout {
  width: 50%;
  float: left; }
  .icon-field.icon-field75 .radioGroupWrap > .focusout .radioGroup .radio-col {
    width: 100%; }

.icon-field.icon-field75 .radioGroupWrap > .focused {
  width: 50%;
  float: left; }
  .icon-field.icon-field75 .radioGroupWrap > .focused .radioGroup .radio-col {
    width: 100%;
    border-color: #0093d0 !important; }

.other-unit-type#otherFocused {
  border-color: #0093d0 !important; }

.icon-field.icon-field75 .checkboxGroupSelect.focused > div {
  border-color: #0093d0 !important; }

/* end quote form focus css */
.quote-result-wraper {
  width: 100%;
  float: left; }
  .quote-result-wraper .left-section {
    width: 252px;
    float: left;
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-box-sizing: 4px;
    border-radius: 4px;
    padding: 15px 0; }
    .quote-result-wraper .left-section h1 {
      padding: 0 15px;
      margin: 0 0 20px 0;
      font-family: 'latobold';
      font-style: normal; }
    .quote-result-wraper .left-section .block-section {
      width: 100%;
      float: left;
      padding: 0 15px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px; }
      .quote-result-wraper .left-section .block-section:last-child {
        border: 0; }
      .quote-result-wraper .left-section .block-section .block-title .blue-arrow {
        position: absolute;
        left: 50%;
        margin-left: -2px;
        margin-top: -2px;
        cursor: pointer; }
        .quote-result-wraper .left-section .block-section .block-title .blue-arrow i {
          cursor: pointer; }
          .quote-result-wraper .left-section .block-section .block-title .blue-arrow i:before {
            font-size: 17px;
            color: #0093d0; }
      .quote-result-wraper .left-section .block-section .block-content .ship-icons {
        margin: 0;
        padding: 0;
        list-style: none; }
        .quote-result-wraper .left-section .block-section .block-content .ship-icons li {
          float: left;
          width: 25%;
          margin-bottom: 20px; }
          .quote-result-wraper .left-section .block-section .block-content .ship-icons li .tooltiptext {
            margin: 0 0 0 -70%;
            top: -34px;
            bottom: inherit;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
            width: auto;
            white-space: nowrap;
            left: inherit;
            right: inherit; }
            .quote-result-wraper .left-section .block-section .block-content .ship-icons li .tooltiptext:after {
              left: 48%;
              bottom: inherit;
              top: 100%;
              border-color: #000 transparent transparent transparent; }
          .quote-result-wraper .left-section .block-section .block-content .ship-icons li.active svg path {
            fill: #ff6c2f; }
      .quote-result-wraper .left-section .block-section h3 {
        font-family: 'latobold';
        font-style: normal;
        color: #000; }
      .quote-result-wraper .left-section .block-section .line-item {
        width: 100%;
        float: left;
        padding: 30px 0 0 0; }
        .quote-result-wraper .left-section .block-section .line-item .row {
          width: 100%;
          float: left;
          margin-bottom: 30px; }
          .quote-result-wraper .left-section .block-section .line-item .row h3 {
            margin-bottom: 10px;
            color: #000; }
          .quote-result-wraper .left-section .block-section .line-item .row .col {
            width: 33.3%;
            float: left;
            padding-right: 8px; }
            .quote-result-wraper .left-section .block-section .line-item .row .col.colwd41 {
              width: 41%; }
            .quote-result-wraper .left-section .block-section .line-item .row .col.colwd25 {
              width: 25%; }
            .quote-result-wraper .left-section .block-section .line-item .row .col:last-child {
              padding-right: 0; }
            .quote-result-wraper .left-section .block-section .line-item .row .col h4 {
              font-family: 'latobold';
              font-style: normal;
              font-size: 14px;
              color: #000; }
            .quote-result-wraper .left-section .block-section .line-item .row .col p {
              font-size: 12px;
              color: #4a4a4a; }
    .quote-result-wraper .left-section .col-grid2 .col {
      padding: 0;
      margin-bottom: 8px; }
      .quote-result-wraper .left-section .col-grid2 .col h3 {
        margin: 0;
        padding: 0; }
      .quote-result-wraper .left-section .col-grid2 .col p {
        font-size: 12px; }
  .quote-result-wraper .quote-result-section {
    float: none;
    padding-left: 262px;
    padding-right: 15px; }
    .quote-result-wraper .quote-result-section .wraper {
      width: 100%;
      float: left; }
      .quote-result-wraper .quote-result-section .wraper .result-list {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        border-bottom: 1px solid #9b9b9b;
        padding-bottom: 20px; }
        .quote-result-wraper .quote-result-section .wraper .result-list ul {
          margin: 0;
          padding: 0;
          width: 100%;
          list-style: none; }
          .quote-result-wraper .quote-result-section .wraper .result-list ul li {
            margin: 0 0 10px 0;
            padding: 0;
            float: left;
            width: 100%;
            background: #fff;
            min-height: 160px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-box-sizing: 4px;
            border-radius: 4px; }
            .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 {
              padding: 10px 0; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 .col {
                margin-bottom: 0; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 .col:last-child {
                  border-left: 1px solid #e5e5e5; }
            .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section {
              width: 100%;
              float: left; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type {
                width: 50%;
                float: left;
                padding: 6px 0;
                padding-left: 30px;
                padding-right: 20px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type:first-child {
                  border-right: 1px solid #e5e5e5;
                  padding-left: 0px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .ship-status {
                  width: 100%;
                  min-height: 34px;
                  margin-bottom: 12px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type h3 {
                  margin: 0;
                  padding: 0;
                  color: #000;
                  font-weight: normal;
                  font-family: 'latobold'; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .smText-greyish {
                  color: #4a4a4a;
                  font-size: 12px;
                  line-height: 18px;
                  margin-bottom: 12px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price {
                  font-family: 'latobold';
                  margin-bottom: 12px;
                  text-overflow: ellipsis; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price samp {
                    color: #ff6c2f;
                    font-family: 'latobold';
                    margin-bottom: 12px; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price i {
                    margin-left: 4px;
                    cursor: pointer; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price i:before {
                      font-size: 14px;
                      color: #b1b1b1; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup {
                    left: -328px;
                    max-width: 526px; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left {
                      width: 100%; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .head-section:before {
                        border: 0; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .head-section:after {
                        border: 0; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .col-grid2 {
                        padding: 0; }
                        .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .col-grid2 .col {
                          border: 0;
                          padding-left: 10px;
                          padding-right: 0px; }
                          .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .col-grid2 .col h4 {
                            color: #9b9b9b; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-right {
                      width: 1px; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-right i {
                        right: 8px; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup:before {
                      left: 350px; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup:after {
                      left: 350px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type h4 {
                  margin: 0;
                  padding: 0;
                  color: #000;
                  font-weight: normal;
                  font-family: 'latobold';
                  font-size: 12px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .btn-set {
                  margin: 12px 0 0 0; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .btn-set button {
                    padding: 0 24px !important;
                    min-width: inherit;
                    min-height: 28px; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .btn-set button span {
                      font-family: 'latoregular';
                      font-size: 14px;
                      text-transform: none;
                      font-weight: normal !important; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price-disable {
                  color: #cdcccd;
                  font-family: 'latobold';
                  margin-bottom: 12px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type.guranteedOff {
                  border: 0;
                  position: relative; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type.guranteedOff .btn-set {
                    position: absolute;
                    right: -90px;
                    top: 50%;
                    margin-top: -12px; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section {
                min-height: inherit;
                padding: 0; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type {
                  padding-left: 20px;
                  border: 0;
                  padding-right: 0; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type:last-child {
                    padding-right: 0; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .ship-image {
                    float: left;
                    width: 50%;
                    text-align: center; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .ship-image h4 {
                      margin-top: 8px; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping {
                    float: left;
                    width: 50%;
                    text-align: center; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck {
                      border-left: 3px solid #0093d0;
                      border-right: 3px solid #0093d0;
                      border-top: 3px solid #0093d0;
                      height: 19px;
                      width: 24px;
                      position: relative;
                      cursor: pointer; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:before {
                        position: relative;
                        top: -1px;
                        left: 1px;
                        font-size: 18px;
                        color: #0093d0; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:after {
                        content: '';
                        position: absolute;
                        border: 12px solid transparent;
                        border-bottom: 5px solid #0093d0;
                        left: -3px;
                        top: -20px; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck em {
                        width: 5px;
                        height: 20px;
                        background: #ffff;
                        display: block;
                        position: absolute;
                        right: -4px;
                        top: 6px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .table {
                  display: table;
                  width: 100%; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .table .cell {
                    display: table-cell;
                    height: 120px;
                    vertical-align: middle; }
            .quote-result-wraper .quote-result-section .wraper .result-list ul li .carrierDetails {
              width: 100%;
              float: left;
              background: #f8f6f6; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .carrierDetails .col-grid2 {
                padding: 0; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .carrierDetails .col-grid2 .col {
                  border: 0px; }

.carriersNotused-section {
  width: 100%;
  float: left;
  margin-bottom: 40px; }
  .carriersNotused-section .white-section.search-cutomer .filter-title-row {
    padding: 0 20px 15px; }
    .carriersNotused-section .white-section.search-cutomer .filter-title-row h2 {
      margin-left: 0;
      font-size: 18px;
      font-weight: normal;
      font-family: 'latobold'; }
  .carriersNotused-section .white-section.search-cutomer .inwraper {
    padding: 5px 20px;
    background: #f8f6f6; }
    .carriersNotused-section .white-section.search-cutomer .inwraper h2 {
      color: #5b595a; }
  .carriersNotused-section .grid-carriersNotused {
    width: 100%;
    float: left; }
    .carriersNotused-section .grid-carriersNotused h2 {
      margin-top: 10px; }
    .carriersNotused-section .grid-carriersNotused .col {
      width: 25%;
      float: left;
      text-align: left; }
      .carriersNotused-section .grid-carriersNotused .col img {
        margin: -5px 0 0 0; }
      .carriersNotused-section .grid-carriersNotused .col + .col {
        width: 75%;
        float: left; }
  .carriersNotused-section .carriersNotusedResult {
    width: 100%;
    float: left; }
    .carriersNotused-section .carriersNotusedResult .white-section {
      padding: 15px 20px;
      margin-top: 6px; }

.a-link13R {
  font-size: 13px;
  font-family: 'latoregular';
  vertical-align: top;
  line-height: 24px; }
  .a-link13R i:before {
    font-size: 15px;
    top: 2px;
    position: relative;
    margin-left: 2px; }

.trace-link {
  position: absolute;
  right: 30px;
  bottom: 0; }

.shipDestinationpopup {
  background: #fff;
  width: 560px;
  max-width: 560px;
  box-shadow: 0px 1px 5px 1px #ddd;
  position: absolute;
  left: -54px;
  top: 14px;
  z-index: 10;
  display: block;
  text-align: left; }
  .shipDestinationpopup .col-grid2 {
    padding: 15px 0px 10px 0px !important; }
    .shipDestinationpopup .col-grid2 .col {
      padding: 20px 30px !important; }
      .shipDestinationpopup .col-grid2 .col p {
        max-width: 140px;
        margin-top: 10px; }
  .shipDestinationpopup .button-set {
    width: 100%;
    float: left;
    padding: 15px 0;
    text-align: center;
    border-top: 2px solid #ebebeb; }
    .shipDestinationpopup .button-set button {
      padding: 10px 32px !important;
      margin: 0 10px; }
  .shipDestinationpopup .zmdi-close {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1; }
    .shipDestinationpopup .zmdi-close:before {
      font-size: 18px !important; }

.cDetailcollapsible {
  border-top: 1px solid #e5e5e5;
  width: 100%;
  float: left;
  padding: 24px 20px 20px; }
  .cDetailcollapsible h2 {
    font-family: 'latobold';
    font-style: normal;
    color: #000;
    margin-bottom: 8px; }
  .cDetailcollapsible .smallTxt {
    font-size: 13px; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price sup {
  color: #ff6c2f;
  font-family: 'latobold';
  margin-bottom: 12px;
  position: relative;
  top: -3px;
  font-size: 14px; }

.rangefilter .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #ddd;
  outline: none;
  -webkit-transition: .2s;
  padding: 0 !important;
  margin-bottom: 18px;
  float: left;
  border: 0 !important; }

.rangefilter .slider:hover {
  opacity: 1; }

.rangefilter .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: #0092cc;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px; }

.rangefilter .slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #0092cc;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px; }

.rangefilter p {
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 2px 2px;
  font-size: 12px;
  color: #868585;
  float: right;
  width: 21%;
  margin-top: -12px;
  text-align: center; }

.rangefilter .handle {
  position: absolute;
  width: 13%;
  height: 2px;
  background: #0092cc;
  max-width: 100%; }

.rangefilter .rfilterLeft {
  float: left;
  width: 70%; }

.rangefilter div[role="slider"] > div div:first-child {
  background: #0092cc;
  z-index: inherit; }

.rangefilter button {
  background: #0092cc;
  z-index: inherit; }
  .rangefilter button + div {
    background: #717171; }

.rfilterLeft.relative div {
  z-index: inherit; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck {
  border-color: #ddd;
  border-left: 3px solid #ddd;
  border-right: 3px solid #ddd;
  border-top: 3px solid #ddd; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:before {
  color: #ddd; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:after {
  border-bottom: 5px solid #ddd; }

button.borderBtn {
  border: 1px solid #9b9b9b;
  border-radius: 2px;
  margin-top: 1px;
  padding: 0 3px 0 8px !important;
  min-height: 28px;
  margin: 0 10px; }
  button.borderBtn span {
    float: left; }
  button.borderBtn .userText {
    padding: 0 6px 0 0px !important;
    text-transform: none;
    display: block;
    white-space: inherit;
    font-size: 11px;
    color: #000; }
  button.borderBtn .zmdi-square-down {
    margin-top: 3px;
    margin-right: 6px; }
    button.borderBtn .zmdi-square-down:before {
      font-size: 16px;
      color: #4a4a4a; }
  button.borderBtn .dropdownIcon {
    padding-right: 6px;
    padding-left: 3px; }
    button.borderBtn .dropdownIcon i {
      border-width: 0 1px 1px 0;
      border-color: #333;
      position: relative;
      top: -2px;
      padding: 2px;
      width: 6px;
      height: 6px; }

.tbl-data.white-head {
  border: 0; }
  .tbl-data.white-head thead tr {
    background: none; }
  .tbl-data.white-head tbody tr td:first-child {
    border-left: 1px solid #e6eaee; }
  .tbl-data.white-head tbody tr td:last-child {
    border-right: 1px solid #e6eaee; }
  .tbl-data.white-head tbody tr td h4 {
    font-weight: normal;
    font-family: 'latobold';
    font-size: 13px;
    color: #000; }
  .tbl-data.white-head tbody tr td.action i {
    margin: 0 10px; }

.DialogBox .pagination-section .pagination-wraper .pagination li {
  padding: 0 !important; }

.DialogBox .paginations-set p {
  font-size: 14px; }

.DialogBox h2 {
  font-weight: normal;
  font-family: 'latobold';
  color: #000; }

.DialogBox .small-btn1 {
  padding: 0 10px !important; }

.radioButton .radioGroup {
  display: block; }
  .radioButton .radioGroup .radio-col span + span {
    color: #000;
    font-size: 13px; }

i.rotate180 {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  position: relative;
  top: -2px; }
  i.rotate180:before {
    margin-right: 0;
    margin-left: 5px; }

.popup.getDoc {
  left: inherit;
  right: 10px;
  width: 192px;
  padding: 10px 0 0; }
  .popup.getDoc .checkBoxWrap {
    margin: 2px 0 2px 8px; }
    .popup.getDoc .checkBoxWrap span:first-child {
      width: 40px;
      height: 40px; }
    .popup.getDoc .checkBoxWrap span + span {
      font-size: 12px;
      font-family: 'latobold' !important;
      color: #000; }
  .popup.getDoc .btn-set {
    border-top: 2px solid #ececec;
    padding: 10px 0 12px;
    text-align: center;
    margin: 4px 0 0 0; }
    .popup.getDoc .btn-set button {
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-top: 0 !important;
      padding-bottom: 0px !important;
      min-height: 30px; }
      .popup.getDoc .btn-set button span {
        font-family: 'latoregular' !important;
        font-weight: normal !important;
        font-size: 14px; }

.DropzoneBlock {
  float: left;
  width: 100%;
  margin: 0 0 20px 0; }
  .DropzoneBlock > div {
    width: 100% !important;
    height: 98px !important;
    background: #e6e6e6;
    border: 1px dashed #999999 !important;
    border-radius: 4px; }
    .DropzoneBlock > div .noteTxtOuter {
      max-width: 290px;
      margin: 0 auto; }
    .DropzoneBlock > div .noteTxt {
      display: table;
      height: 98px;
      width: 100%; }
      .DropzoneBlock > div .noteTxt .noteTxtinn {
        display: table-cell;
        vertical-align: middle; }
        .DropzoneBlock > div .noteTxt .noteTxtinn h5 {
          font-family: 'latobold' !important;
          color: #000;
          font-weight: normal;
          padding: 5px 0 0 0; }
          .DropzoneBlock > div .noteTxt .noteTxtinn h5 span {
            color: #0093d0;
            font-family: 'latobold' !important; }
        .DropzoneBlock > div .noteTxt .noteTxtinn p {
          font-size: 11px;
          color: #8c8c8c; }
        .DropzoneBlock > div .noteTxt .noteTxtinn .zmdi-square-down {
          float: left; }
          .DropzoneBlock > div .noteTxt .noteTxtinn .zmdi-square-down:before {
            color: #fff;
            font-size: 48px;
            margin-left: 20px;
            font-weight: normal; }
      .DropzoneBlock > div .noteTxt .UploadnoteTxtinn {
        display: table-cell;
        vertical-align: middle; }
        .DropzoneBlock > div .noteTxt .UploadnoteTxtinn h5 {
          font-family: 'latobold' !important;
          color: #000;
          font-weight: normal;
          padding: 5px 0 0 0; }
          .DropzoneBlock > div .noteTxt .UploadnoteTxtinn h5 span {
            color: #0093d0;
            font-family: 'latobold' !important; }
        .DropzoneBlock > div .noteTxt .UploadnoteTxtinn p {
          font-size: 11px;
          color: #8c8c8c;
          line-height: 14px; }
        .DropzoneBlock > div .noteTxt .UploadnoteTxtinn img {
          float: left;
          margin-top: 5px;
          margin-right: 10px; }
        .DropzoneBlock > div .noteTxt .UploadnoteTxtinn .zmdi-image {
          float: left;
          margin-top: 5px; }
          .DropzoneBlock > div .noteTxt .UploadnoteTxtinn .zmdi-image:before {
            color: #c1c1c1;
            font-size: 36px;
            margin-right: 12px;
            font-weight: normal; }
        .DropzoneBlock > div .noteTxt .UploadnoteTxtinn .progress {
          height: 3px;
          line-height: 3px;
          padding-left: 34px;
          margin: 2px 0 1px 0; }
          .DropzoneBlock > div .noteTxt .UploadnoteTxtinn .progress span {
            height: 3px;
            line-height: 3px;
            background: #fff;
            width: 100%;
            display: block;
            border-radius: 3px; }
            .DropzoneBlock > div .noteTxt .UploadnoteTxtinn .progress span em {
              width: 100%;
              height: 3px;
              background: #0093d0;
              display: block;
              border-radius: 3px;
              transition: all 3s; }

.borderField .selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] {
  padding-top: 3px; }

.borderField .selectSingle .formControl {
  margin-top: 0;
  margin-bottom: 0; }

.borderField .selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  font-size: 12px;
  color: #4a4a4a !important;
  padding-left: 10px;
  line-height: 23px; }

.white-section.reportResult {
  margin-bottom: 10px;
  padding: 20px 40px 1px; }

.white-section.search-report .col-grid4 > .col {
  padding-right: 20px;
  padding-left: 20px;
  min-height: 190px; }
  .white-section.search-report .col-grid4 > .col:first-child {
    padding-left: 0; }
  .white-section.search-report .col-grid4 > .col:last-child {
    padding-right: 0; }
  .white-section.search-report .col-grid4 > .col h3 {
    margin-bottom: 10px; }

.white-section.search-report .checkboxList .checkBoxWrap {
  margin-bottom: 0;
  margin-left: -7px; }
  .white-section.search-report .checkboxList .checkBoxWrap span:first-child {
    width: 34px;
    height: 34px; }

.bdr-Right {
  border-right: 1px solid #ddd; }

.reportStatusSection {
  width: 100%;
  float: left;
  padding: 14px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #9b9b9b; }
  .reportStatusSection ul {
    float: right; }
    .reportStatusSection ul li {
      padding: 0;
      float: left;
      font-family: 'latobold';
      font-style: normal;
      color: #9b9b9b;
      font-size: 14px;
      margin: 0 0px 0 36px;
      position: relative; }
      .reportStatusSection ul li:before {
        content: '';
        position: absolute;
        left: -18px;
        top: 7px;
        width: 8px;
        height: 8px;
        background: #f8e71c;
        border-radius: 100%; }
      .reportStatusSection ul li.hight:before {
        background: #d0021b; }
      .reportStatusSection ul li.medium:before {
        background: #f5a623; }
      .reportStatusSection ul li.low:before {
        background: #f8e71c; }
      .reportStatusSection ul li.autoOn:before {
        background: #417505; }
      .reportStatusSection ul li.autoOff:before {
        background: #f5a623; }
      .reportStatusSection ul li.mOn:before {
        background: #4a90e2; }
      .reportStatusSection ul li.mOff:before {
        background: #d0021b; }
      .reportStatusSection ul li.goalLess70:before {
        background: #d0021b; }
      .reportStatusSection ul li.goal70To85:before {
        background: #f5a623; }
      .reportStatusSection ul li.goal85To99:before {
        background: #f8e71c; }
      .reportStatusSection ul li.goalGr99:before {
        background: #417505; }

.userList.myCustomer.heightAuto .listItem.reports li {
  border-left: 3px solid transparent; }
  .userList.myCustomer.heightAuto .listItem.reports li .listNotes .col {
    margin: 0;
    background: #fbfbfb; }
  .userList.myCustomer.heightAuto .listItem.reports li .listNotes p {
    color: #262526;
    font-size: 12px;
    padding: 6px 10px;
    line-height: 16px; }
    .userList.myCustomer.heightAuto .listItem.reports li .listNotes p em {
      display: inline-block;
      vertical-align: top;
      font-style: normal; }
    .userList.myCustomer.heightAuto .listItem.reports li .listNotes p span {
      color: #9b9b9b;
      padding-left: 5px;
      display: inline-block; }
  .userList.myCustomer.heightAuto .listItem.reports li.hight {
    border-left-color: #d0021b; }
  .userList.myCustomer.heightAuto .listItem.reports li.medium {
    border-left-color: #f5a623; }
  .userList.myCustomer.heightAuto .listItem.reports li.low {
    border-left-color: #f8e71c; }
  .userList.myCustomer.heightAuto .listItem.reports li.goalLess70 {
    border-left-color: #d0021b; }
  .userList.myCustomer.heightAuto .listItem.reports li.goal70To85 {
    border-left-color: #f5a623; }
  .userList.myCustomer.heightAuto .listItem.reports li.goal85To99 {
    border-left-color: #f8e71c; }
  .userList.myCustomer.heightAuto .listItem.reports li.goalGr99 {
    border-left-color: #417505; }
  .userList.myCustomer.heightAuto .listItem.reports li h5 {
    position: relative; }
    .userList.myCustomer.heightAuto .listItem.reports li h5 .tooltiptext {
      left: -10px;
      margin-left: 0;
      bottom: 100%;
      top: inherit;
      position: absolute;
      color: #fff;
      text-align: left;
      padding: 7px 10px; }
      .userList.myCustomer.heightAuto .listItem.reports li h5 .tooltiptext:after {
        border-color: #000 transparent transparent transparent; }
      .userList.myCustomer.heightAuto .listItem.reports li h5 .tooltiptext.reportDateCol {
        width: 246px !important;
        bottom: inherit;
        left: inherit;
        right: -20%; }
        .userList.myCustomer.heightAuto .listItem.reports li h5 .tooltiptext.reportDateCol:after {
          border-color: transparent transparent #000 transparent;
          right: 21%;
          left: inherit; }
    .userList.myCustomer.heightAuto .listItem.reports li h5 .date {
      width: 85px !important; }
  .userList.myCustomer.heightAuto .listItem.reports li .tooltpWraper {
    position: relative; }
    .userList.myCustomer.heightAuto .listItem.reports li .tooltpWraper .tooltiptext {
      left: -10px;
      margin-left: 0;
      bottom: inherit;
      top: 40px;
      position: absolute;
      color: #fff;
      text-align: left;
      padding: 7px 10px;
      width: 256px !important; }
      .userList.myCustomer.heightAuto .listItem.reports li .tooltpWraper .tooltiptext:after {
        border-color: transparent transparent #000 transparent;
        left: 20%; }

.list-section .userlist-head .col .sortImg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px; }

.registrationRepotCol > .col {
  padding-right: 6px !important;
  padding-left: 0px !important;
  border-right: 1px solid #ddd; }
  .registrationRepotCol > .col:first-child {
    padding-left: 0 !important; }
    .registrationRepotCol > .col:first-child .labelTxt {
      padding-left: 0px !important; }
    .registrationRepotCol > .col:first-child .checkbox-row {
      padding-left: 0px !important; }
  .registrationRepotCol > .col:last-child {
    border-right: 0px; }
  .registrationRepotCol > .col .checkbox-row {
    padding-right: 15px;
    padding-left: 20px !important; }
  .registrationRepotCol > .col .labelTxt {
    padding-left: 17px;
    margin-bottom: 5px; }
  .registrationRepotCol > .col .checkBoxWrap > span:first-child {
    width: 40px;
    height: 40px; }

.registrationRepotFilter > .col {
  padding-right: 30px !important;
  padding-left: 20px;
  margin-bottom: 15px; }

.boldCheckbox .checkBoxWrap > span:first-child {
  width: 40px;
  height: 40px; }

.boldCheckbox .checkBoxWrap > span:last-child {
  font-size: 15px;
  line-height: 17px;
  font-family: "latoregular",sans-serif;
  font-weight: bold; }

.left33 {
  padding-left: 7.5% !important; }

.userList.myCustomer.heightAuto .listItem li.grey {
  background: #d8d8d8;
  border-color: #d8d8d8;
  margin-top: 30px; }
  .userList.myCustomer.heightAuto .listItem li.grey:first-child {
    margin-top: 0; }
  .userList.myCustomer.heightAuto .listItem li.grey .col .NormalTxt {
    text-align: left;
    margin-left: 0% !important; }
  .userList.myCustomer.heightAuto .listItem li.grey .col span {
    color: #000;
    font-family: 'latobold'; }

.userList.myCustomer.heightAuto .listItem li .col .boldTxt {
  margin-left: 33% !important;
  font-family: 'latobold'; }
  .userList.myCustomer.heightAuto .listItem li .col .boldTxt:hover {
    color: #000;
    cursor: default; }

.userList.myCustomer.heightAuto .listItem li .col .NormalTxt {
  margin-left: 33% !important; }

.white-section.search-cutomer .searchbaar.closed.jd {
  transition: none !important; }

.zmdi-network.zmdi-lead {
  transform: rotate(45deg); }
  .zmdi-network.zmdi-lead:before {
    font-size: 18px !important;
    position: relative;
    top: -3px;
    left: -3px; }
  .zmdi-network.zmdi-lead:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 13px;
    background: #9b9b9b;
    transform: rotate(-45deg);
    left: 27px;
    top: 24px; }

.left-sidebar-nav .listWrap .listItem:hover > i:after {
  background: #ff6c2f; }

.left-sidebar-nav .listWrap .listItem.active > i:after {
  background: #ff6c2f; }

.carrierBillSectionOuter {
  border: 0px; }

.carrierBillSection {
  width: 100%;
  float: left; }
  .carrierBillSection > h2 {
    margin-bottom: 20px; }
  .carrierBillSection .tableFieldset {
    float: left;
    width: 65%;
    padding-right: 30px; }
    .carrierBillSection .tableFieldset tbody tr {
      background: #fff; }
    .carrierBillSection .tableFieldset .table-content .tbl-data {
      border: 1px solid #e6eaee !important; }
  .carrierBillSection .tableFieldsetDes {
    float: left;
    width: 35%; }
    .carrierBillSection .tableFieldsetDes tbody tr {
      background: #fff; }
    .carrierBillSection .tableFieldsetDes.textareabdr0 div:before {
      border: 0px !important; }
    .carrierBillSection .tableFieldsetDes.textareabdr0 div:after {
      border: 0px !important; }

.zmdi-network.zmdi-lead {
  transform: rotate(45deg); }
  .zmdi-network.zmdi-lead:before {
    font-size: 18px !important;
    position: relative;
    top: -3px;
    left: -3px; }
  .zmdi-network.zmdi-lead:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 13px;
    background: #9b9b9b;
    transform: rotate(-45deg);
    left: 27px;
    top: 24px; }

.left-sidebar-nav .listWrap .listItem:hover > i:after {
  background: #ff6c2f; }

.left-sidebar-nav .listWrap .listItem.active > i:after {
  background: #ff6c2f; }

.sort-img-2 {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px; }

.white-section.search-report.hAuto .col-grid4 > .col {
  min-height: 90px; }

form[name="FilterBAQueueForm"] .col-grid4 {
  float: left;
  width: 100%; }
  form[name="FilterBAQueueForm"] .col-grid4 > .col {
    margin-bottom: 20px; }

form[name="FilterBAQueueForm"] .datePicker .calendar-icon {
  right: 10px; }
  form[name="FilterBAQueueForm"] .datePicker .calendar-icon.rt0 {
    right: 0px; }

form[name="FilterBAQueueForm"] input[type="date"] {
  padding-left: 10px !important; }

.list-section .userList .listItem.billingAdj li {
  border: 0px; }
  .list-section .userList .listItem.billingAdj li .flBox .userlist-body {
    padding-left: 8px; }
    .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col {
      width: 10.5%; }
      .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col > h5 {
        height: 47px; }
        .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col > h5 > span {
          display: block; }
      .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.details {
        width: 32%; }
        .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.details td {
          padding-right: 10px; }
        .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.details .samp {
          min-width: 82px;
          display: inline-block; }
        .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.details .boldText {
          font-family: latobold; }
      .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.date {
        width: 10%; }
        .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.date > span {
          padding-top: 10px; }
      .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.review {
        width: 6%; }
        .list-section .userList .listItem.billingAdj li .flBox .userlist-body > .col.review > span {
          padding-top: 10px; }

.list-section .filterBar .gridFilterHead.rowClicked .userlist-head.billingAdj-head {
  padding-left: 8px; }
  .list-section .filterBar .gridFilterHead.rowClicked .userlist-head.billingAdj-head > .col {
    width: 10.5%; }
    .list-section .filterBar .gridFilterHead.rowClicked .userlist-head.billingAdj-head > .col.details {
      width: 32%; }
    .list-section .filterBar .gridFilterHead.rowClicked .userlist-head.billingAdj-head > .col.date {
      width: 10%; }
    .list-section .filterBar .gridFilterHead.rowClicked .userlist-head.billingAdj-head > .col.review {
      width: 6%; }

.scrollTbl thead {
  display: block;
  padding-right: 17px;
  background: #f5f5f5;
  border-bottom: 1px solid #e6eaee; }
  .scrollTbl thead tr {
    display: block; }
    .scrollTbl thead tr td {
      display: inline-block;
      width: 100%;
      padding-right: 10px;
      border-bottom: 0px; }
      .scrollTbl thead tr td.wd10 {
        width: 10%; }
      .scrollTbl thead tr td.wd20 {
        width: 20%; }
      .scrollTbl thead tr td.wd18 {
        width: 18%; }
      .scrollTbl thead tr td.wd30 {
        width: 30%; }
      .scrollTbl thead tr td.wd50 {
        width: 50%; }
      .scrollTbl thead tr td.wd60 {
        width: 60%; }
    .scrollTbl thead tr.col-5 td {
      width: 18%; }
      .scrollTbl thead tr.col-5 td:last-child {
        width: 28%; }
    .scrollTbl thead tr.col-3 td {
      width: 33.3%; }

.scrollTbl tbody {
  display: block;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 150px;
  border-bottom: 1px solid #ddd; }
  .scrollTbl tbody tr {
    display: block;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd; }
    .scrollTbl tbody tr td {
      display: inline-block;
      border: 0;
      padding-right: 10px;
      width: 100%; }
      .scrollTbl tbody tr td:first-child {
        border: 0px !important; }
      .scrollTbl tbody tr td.wd10 {
        width: 10%; }
      .scrollTbl tbody tr td.wd18 {
        width: 18%; }
      .scrollTbl tbody tr td.wd20 {
        width: 20%; }
      .scrollTbl tbody tr td.wd30 {
        width: 30%; }
      .scrollTbl tbody tr td.wd50 {
        width: 50%; }
      .scrollTbl tbody tr td.wd60 {
        width: 60%; }
    .scrollTbl tbody tr.col-5 td {
      width: 18%; }
      .scrollTbl tbody tr.col-5 td:last-child {
        width: 28%; }
    .scrollTbl tbody tr.col-3 td {
      width: 33.3%; }

.scrollTblFoot {
  padding-right: 17px; }
  .scrollTblFoot .row {
    display: block;
    width: 100%;
    text-align: right;
    padding-top: 3px;
    padding-bottom: 3px; }
    .scrollTblFoot .row > .col {
      width: 100%;
      display: inline-block;
      float: none;
      padding-left: 10px;
      padding-right: 10px; }
    .scrollTblFoot .row.col-5 {
      vertical-align: top; }
      .scrollTblFoot .row.col-5 > .col {
        width: 20%;
        font-family: latobold;
        font-size: 12px;
        margin: 0px;
        vertical-align: top; }
        .scrollTblFoot .row.col-5 > .col .fsize {
          font-size: 14px;
          font-family: latobold; }
        .scrollTblFoot .row.col-5 > .col:first-child {
          width: 60%; }
        .scrollTblFoot .row.col-5 > .col:last-child {
          width: 28%; }
          .scrollTblFoot .row.col-5 > .col:last-child .start-Adornmen p {
            padding-left: 10px; }

.buttonSet.grayBg {
  background: #f9f9f9;
  padding: 15px;
  text-align: center; }

form[name="FilterBAQueueForm"] .formControl.autosuggest > label + div > div > span + div + div > div {
  max-height: 166px !important; }

.DialogBox .DialogBoxcontent fieldset.graypopup {
  padding: 0px 24px 10px;
  background: #f9f9f9; }

.DialogBox .DialogBoxcontent fieldset.graypopupPaddingBoth {
  padding: 20px 24px 20px;
  background: #f9f9f9; }

.white-section.search-cutomer .searchbaar.slideNo {
  z-index: 1;
  position: relative;
  transition: inherit !important; }
  .white-section.search-cutomer .searchbaar.slideNo form {
    transform: translateY(0%); }

.white-section.search-cutomer .searchbaar.open.slideNo {
  overflow: visible; }

.whiteBoxFiled .labelData {
  background: #fff;
  width: 100%;
  padding: 5px 12px;
  border: 1px solid #efecec; }

.txtBlackLabelData .labelData {
  color: #000 !important;
  font-family: latobold; }

.buttonPaddlow button {
  padding: 10px 30px !important; }

.buttonMar10 button {
  margin: 0px 10px !important; }

.scrollTblFoot .txtValueRight .inputField .labelData .adornmentSign {
  float: left; }

.txtValueRight .inputField .labelData .adornmentSign {
  float: left; }

form[name="CarrierBillForm"] .scrollTblFoot .row.col-5 > .col:last-child .fsize {
  padding-left: 10px !important; }

.boldTxt1 {
  font-weight: normal;
  font-family: 'latobold' !important;
  font-size: 15px; }

.inputField .label:empty {
  display: none; }

.responsive {
  display: none; }

.more-vert {
  display: none; }

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=time]::-webkit-inner-spin-button,
input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .topSearch {
    display: none; }
  .userRole {
    display: none; }
  .topBar-userProfile {
    padding: 0;
    border: 0px; }
    .topBar-userProfile .loggedinUser {
      display: none; }
    .topBar-userProfile .userProfile {
      padding: 0;
      border: 0px;
      position: relative;
      float: right; }
      .topBar-userProfile .userProfile .userIcon {
        display: inline-block;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        margin-right: 10px; }
      .topBar-userProfile .userProfile .dropdownIcon {
        height: 100%;
        vertical-align: middle;
        display: inline-block;
        line-height: 7px;
        position: relative;
        top: -2px; }
      .topBar-userProfile .userProfile button {
        padding: 0px 10px !important;
        vertical-align: top !important;
        min-height: 54px !important;
        border-radius: 0px !important;
        min-width: 50px !important; }
        .topBar-userProfile .userProfile button:hover {
          background: #C0C8D0; }
      .topBar-userProfile .userProfile .popup {
        left: inherit;
        right: -10px; }
        .topBar-userProfile .userProfile .popup i::before {
          position: relative;
          top: 2px; }
  .topbar .fixedtopbarTop54 {
    top: 0px !important; }
  .fixedLayout .list-section .stickySection {
    position: static;
    top: inherit; }
  .topBar-navCenter {
    display: none; }
  .notificationMob {
    width: 100vw;
    line-height: 30px;
    height: 30px;
    display: block; }
    .notificationMob .notificationMarquee {
      height: 30px;
      width: 100%;
      white-space: nowrap;
      font-size: 13px; }
      .notificationMob .notificationMarquee div {
        white-space: nowrap;
        margin: 0 15px 0 15px; }
        .notificationMob .notificationMarquee div span {
          white-space: nowrap; }
  .notification {
    display: none; }
  .topBar-userProfile {
    padding: 0;
    border: 0px;
    align-self: end; }
    .topBar-userProfile .loggedinUser {
      display: none; }
    .topBar-userProfile .userProfile {
      padding: 0;
      border: 0px; }
  .logoSection {
    width: calc(100vw - 100px);
    border-right: 0px; }
    .logoSection .menuIconWrap {
      float: left; }
    .logoSection .navbarLogo {
      float: none;
      position: absolute;
      left: 50%;
      margin-left: -70px;
      width: auto;
      margin-right: 12px;
      padding: 13px 0; }
  .topbar {
    z-index: 3;
    height: 84px; }
  .more-vert {
    display: block; }
  .responsive {
    display: block; }
  .relativeRes {
    position: relative; }
  .left-sub-nav {
    display: none !important; }
  .responsiveHide {
    display: none !important; }
  .main-wraper .main-content {
    padding: 0 10px; }
  .DialogBox > div[role=document] {
    margin: 10px; }
  .DialogBox .DialogBoxcontent fieldset {
    padding: 10px; }
  .DialogBox.small .DialogTitle {
    padding-left: 10px; }
  .DialogBox #scroll-dialog-title {
    padding: 15px 15px 15px; }
  .link1 {
    font-size: 12px; }
  /* leftsidebaar */
  .left-sidebar-nav {
    left: -54px;
    z-index: 2;
    padding-top: 84px; }
    .left-sidebar-nav.sideNav-active {
      left: 0;
      z-index: 2;
      padding-top: 84px; }
  /* End leftsidebaar */
  .main-panel {
    width: 100%;
    margin-left: 0;
    margin-top: 70px; }
    .main-panel .listingPageResponsive .white-section.search-cutomer {
      padding: 5px 0 5px 0; }
      .main-panel .listingPageResponsive .white-section.search-cutomer .filter-title-row {
        padding: 0 10px 1px; }
        .main-panel .listingPageResponsive .white-section.search-cutomer .filter-title-row .chips-section {
          width: 50%; }
        .main-panel .listingPageResponsive .white-section.search-cutomer .filter-title-row .collapsible i {
          width: 22px;
          height: 22px; }
          .main-panel .listingPageResponsive .white-section.search-cutomer .filter-title-row .collapsible i:before {
            font-size: 21px; }
        .main-panel .listingPageResponsive .white-section.search-cutomer .filter-title-row button {
          margin-top: -4px; }
        .main-panel .listingPageResponsive .white-section.search-cutomer .filter-title-row h2 {
          margin-top: 4px; }
      .main-panel .listingPageResponsive .white-section.search-cutomer .searchbaar .inwraper {
        padding: 0 10px; }
      .main-panel .listingPageResponsive .white-section.search-cutomer .buttonSet {
        padding: 10px; }
    .main-panel .listingPageResponsive .list-section {
      width: 100%;
      margin-bottom: 36px; }
      .main-panel .listingPageResponsive .list-section .userList .listItem {
        padding-right: 0 !important; }
    .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li {
      border: 0; }
      .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox {
        display: block; }
        .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked {
          min-height: 40px; }
          .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .col.checkbox {
            width: 40px !important;
            display: block !important;
            background: #f9f9f9;
            padding-left: 0px;
            float: left;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1; }
            .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .col.checkbox span {
              padding-right: 0; }
          .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body {
            width: 100% !important;
            display: block !important; }
            .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body .col {
              width: 100% !important;
              border-bottom: 1px solid #dfdfe4;
              min-height: 32px;
              padding-left: 50%;
              display: none;
              min-height: 41px; }
              .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body .col h5 {
                height: inherit;
                padding-top: 12px; }
              .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body .col span {
                height: inherit;
                padding-left: 0%;
                text-align: left; }
                .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body .col span.date {
                  width: 90px; }
              .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body .col:before {
                content: attr(data-head);
                position: absolute;
                left: 0;
                top: 0;
                font-family: 'latobold';
                font-size: 12px;
                background: #f9f9f9;
                height: 100%;
                padding: 14px 3px 10px 40px;
                width: 48%;
                color: #9b9b9b; }
              .main-panel .listingPageResponsive .userList.myCustomer.heightAuto .listItem li .flBox.rowClicked .userlist-body .col:first-child {
                display: block; }
  .col-grid3 .col {
    width: 100% !important;
    padding-right: 0; }
  .col-grid3 .datePicker .calendar-icon.col3Full {
    right: 0; }
  .filterBar .gridFilterHead.rowClicked .userlist-head {
    display: none !important; }
  .filterBar + div {
    height: auto !important; }
  .filterBar + div > div {
    position: static !important;
    overflow: visible !important; }
  .footer-section.fixedfooter {
    padding: 5px;
    height: auto;
    z-index: 1; }
  .footer-section .floatRight p,
  .pagination-section .pagination-wraper .item-per-page .text-perpage {
    display: none; }
  .pagination-section .pagination-wraper .pagination li {
    min-width: inherit; }
    .pagination-section .pagination-wraper .pagination li a {
      min-width: inherit;
      cursor: pointer; }
    .pagination-section .pagination-wraper .pagination li button {
      width: 22px;
      height: 22px;
      margin: 0; }
      .pagination-section .pagination-wraper .pagination li button i:before {
        font-size: 23px; }
  .pagination-section .pagination-wraper .item-per-page {
    margin: 0;
    float: right; }
    .pagination-section .pagination-wraper .item-per-page.selectoption select {
      padding: 5px 4px 5px 5px !important; }
  .breadcrumbBar {
    padding: 5px 10px !important;
    position: relative; }
    .breadcrumbBar .container-fluid {
      padding: 0; }
    .breadcrumbBar .breadcrumb {
      display: none; }
    .breadcrumbBar .currentPageIcon.tooltipWrap .tooltiptext {
      margin-left: -45px; }
    .breadcrumbBar .right-button {
      position: relative;
      width: 100%; }
      .breadcrumbBar .right-button ul {
        position: absolute;
        left: 0;
        top: 40px;
        background: #fff;
        padding: 10px;
        z-index: 1;
        border-radius: 4px; }
        .breadcrumbBar .right-button ul button {
          margin-bottom: 10px; }
        .breadcrumbBar .right-button ul:before {
          content: '';
          position: absolute;
          border: 10px solid transparent;
          border-bottom: 10px solid #fff;
          top: -20px;
          right: 5px; }
      .breadcrumbBar .right-button .more-vert {
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        text-align: center;
        right: 5px;
        top: -2px;
        border-radius: 100%;
        background: #0093d0; }
        .breadcrumbBar .right-button .more-vert i:before {
          color: #fff;
          line-height: 31px; }
  /* quote Form */
  .quoteProgressSection .wraper {
    padding: 0px 10px 0; }
    .quoteProgressSection .wraper ul li {
      margin: 0 32px 0 0;
      padding: 0 2% 0 0;
      width: 19%;
      vertical-align: top; }
      .quoteProgressSection .wraper ul li a {
        font-size: 12px;
        line-height: 13px; }
  .layout-outer-one-col .breadcrumbBar {
    padding: 0 10px 5px; }
  .layout-outer-one-col .main-content {
    padding: 8px 10px 0; }
  .white-section.quote {
    padding: 15px 15px;
    margin-bottom: 6px; }
    .white-section.quote .content-section .col-grid2 .col {
      padding-right: 0;
      padding-left: 0; }
      .white-section.quote .content-section .col-grid2 .col .col50 {
        width: 100%; }
    .white-section.quote .content-section .item-detail .col-left {
      width: 100%; }
      .white-section.quote .content-section .item-detail .col-left .row .column3,
      .white-section.quote .content-section .item-detail .col-left .row .column1 {
        width: 100%;
        padding-left: 0;
        padding-right: 0; }
        .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension,
        .white-section.quote .content-section .item-detail .col-left .row .column1 .dimension {
          width: 100%; }
          .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension .col,
          .white-section.quote .content-section .item-detail .col-left .row .column1 .dimension .col {
            width: 50%; }
        .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check,
        .white-section.quote .content-section .item-detail .col-left .row .column1 .dimension-check {
          width: 100%; }
    .white-section.quote .content-section .item-detail .col-right {
      width: 100%;
      padding-left: 0; }
  /* my customer */
  .main-wraper .main-content .left-section {
    width: 100%;
    padding: 0;
    margin: 0 0 10px 0; }
    .main-wraper .main-content .left-section .block-section {
      padding: 15px 15px 8px 15px; }
      .main-wraper .main-content .left-section .block-section.customer-summary .block-title h2 em {
        display: none; }
      .main-wraper .main-content .left-section .block-section.customer-summary .block-title .collapsible {
        position: absolute;
        right: 0;
        top: -2px; }
      .main-wraper .main-content .left-section .block-section.responsiveMargins {
        margin-top: 32px; }
      .main-wraper .main-content .left-section .block-section.pinned-note .block-title button {
        position: static;
        margin: 10px 0 0 0; }
      .main-wraper .main-content .left-section .block-section.pinned-note .block-title .collapsible {
        position: absolute;
        right: 0;
        top: -2px; }
      .main-wraper .main-content .left-section .block-section.pinned-note .block-footer {
        margin: 0;
        padding: 0; }
      .main-wraper .main-content .left-section .block-section.pinned-note .block-content > div:first-child {
        overflow: visible !important;
        height: auto !important; }
        .main-wraper .main-content .left-section .block-section.pinned-note .block-content > div:first-child > div {
          position: static !important;
          overflow: visible !important;
          margin: 0 !important; }
      .main-wraper .main-content .left-section .block-section.pinned-note .block-content .note-scroll {
        max-height: inherit; }
      .main-wraper .main-content .left-section .block-section.pinned-note .block-content .AddNoteForm-outer {
        position: static; }
      .main-wraper .main-content .left-section .block-section .block-content {
        min-height: inherit; }
        .main-wraper .main-content .left-section .block-section .block-content .section-grid .col-grid2 .col {
          width: 100%;
          padding-left: 0;
          border: 0px; }
        .main-wraper .main-content .left-section .block-section .block-content .section-grid.basic-info .col-grid2 .col {
          width: 100%;
          padding-left: 0;
          border: 0px; }
        .main-wraper .main-content .left-section .block-section .block-content.responsiveHide + .block-footer {
          display: none; }
      .main-wraper .main-content .left-section .block-section .block-footer {
        margin: 0 -15px;
        padding: 5px 15px; }
  .main-wraper .main-content .right-section {
    width: 100%;
    padding: 0;
    margin: 40px 0 0 0;
    float: left; }
    .main-wraper .main-content .right-section .wraper .fixed-content-nav {
      height: auto;
      z-index: 1;
      min-height: 560px; }
    .main-wraper .main-content .right-section .wraper .content-scroll-body {
      padding: 10px 5px 0px 44px;
      min-height: 560px; }
      .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form[name="CustomerForm1"] > div:first-child {
        overflow: visible !important;
        height: auto !important; }
        .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer form[name="CustomerForm1"] > div:first-child > div {
          position: static !important;
          overflow: visible !important;
          margin: 0 !important; }
      .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container {
        height: auto;
        padding-right: 0px; }
        .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset {
          display: block;
          margin-bottom: 10px; }
          .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head {
            min-height: 30px; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head.std1 {
              margin-top: 0px; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head.std3 {
              margin-bottom: 10px;
              margin-top: 0;
              padding-top: 0; }
            .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head button {
              padding: 0 8px !important;
              margin-bottom: 5px; }
              .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset .title-head button span {
                font-size: 13px; }
          .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset#Contract, .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset#Detailed-Info, .main-wraper .main-content .right-section .wraper .content-scroll-body .content-outer .scroll-container fieldset#Basic-Info {
            display: block; }
  .viewport-block > div {
    max-width: 100%  !important;
    width: 100% !important; }
  .responsiveTbl .resCol50 {
    width: 50%;
    float: left;
    border: 0; }
  .responsiveTbl .resCol40 {
    width: 40%;
    float: left;
    border: 0; }
  .responsiveTbl .resCol60 {
    width: 60%;
    float: left;
    padding-right: 38px;
    white-space: inherit !important;
    border: 0; }
  .responsiveTbl .resCol100 {
    width: 100%;
    float: left;
    border: 0; }
  .responsiveTbl .resColHide {
    display: none; }
  .responsiveTbl .resColcollapse {
    display: none; }
  .responsiveTbl thead {
    display: block; }
    .responsiveTbl thead tr {
      display: block;
      float: left;
      width: 100%; }
      .responsiveTbl thead tr td {
        display: block; }
  .responsiveTbl tbody {
    display: block; }
    .responsiveTbl tbody tr {
      display: block;
      float: left;
      width: 100%;
      border-bottom: 1px solid #e6eaee; }
      .responsiveTbl tbody tr td {
        display: block;
        width: 100%;
        float: left;
        border-top: 1px solid #e6eaee;
        border-bottom: 0px solid #e6eaee; }
        .responsiveTbl tbody tr td.dataHead {
          position: relative;
          padding-left: 50%;
          background: #f5f5f5;
          min-height: 36px; }
          .responsiveTbl tbody tr td.dataHead:before {
            content: attr(data-head);
            position: absolute;
            left: 10px;
            top: 8px;
            font-family: 'latobold';
            font-size: 14px;
            color: #9b9b9b; }
  .responsiveTbl .collapsible {
    position: absolute;
    right: 8px;
    top: 10px; }
    .responsiveTbl .collapsible i {
      width: 22px;
      height: 22px;
      border: 1px solid #ddd !important; }
      .responsiveTbl .collapsible i:before {
        font-size: 21px;
        color: #0093d0; }
      .responsiveTbl .collapsible i:hover {
        background: none; }
  .label-dragable {
    font-size: 10px; }
  /* End my customer */
  /* start shipment */
  #heightAutoId > div > div {
    position: static !important;
    margin: 0  !important;
    overflow: visible !important; }
  fieldset#itemDetails {
    width: 100%; }
    fieldset#itemDetails .table-content {
      width: calc(100vw - 70px);
      overflow: auto; }
      fieldset#itemDetails .table-content > table {
        width: 800px; }
  fieldset#ShippingLog {
    width: 100%; }
    fieldset#ShippingLog .CustomerContractList {
      width: calc(100vw - 70px);
      overflow: auto; }
      fieldset#ShippingLog .CustomerContractList > table {
        width: 500px; }
  fieldset#TransactionDetails {
    width: 100%; }
    fieldset#TransactionDetails .table-content {
      width: calc(100vw - 70px);
      overflow: auto; }
      fieldset#TransactionDetails .table-content > table {
        width: 800px; }
  fieldset .api-request {
    padding: 4px; }
    fieldset .api-request .borderBox {
      padding: 0px 5px; }
      fieldset .api-request .borderBox .whiteBox {
        padding: 6px; }
  .main-wraper .main-content .page-title .alert {
    font-size: 12px; }
  .block-title .ship-status samp {
    left: -15px; }
  form[name="ShipmentForm"] fieldset#Basic-Info .title-head h2 {
    padding-bottom: 20px; }
  /* end shipment */
  .paymentPopup .popupOneColLeft .mainContent {
    float: left;
    margin: 0;
    padding: 0; }
  .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-type {
    width: 75%; }
    .paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-type samp {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block; }
  .AddNoteForm-outer + div {
    height: auto !important; }
  /* start QuoteForm */
  form[name="QuoteForm"] .col-left {
    border-right: 0px; }
  form[name="QuoteForm"] .column3 .icon-field {
    width: 100%; }
    form[name="QuoteForm"] .column3 .icon-field.icon-field {
      width: 100%; }
      form[name="QuoteForm"] .column3 .icon-field.icon-field .checkboxGroupSelect {
        width: 100%;
        margin-bottom: 12px; }
      form[name="QuoteForm"] .column3 .icon-field.icon-field .radioGroupWrap {
        width: 100%; }
  form[name="QuoteForm"] .formAction.quoteForm button {
    padding-left: 10px !important;
    padding-right: 10px !important; }
  /* start Quote Result */
  .quote-result-wraper .left-section {
    width: 100%; }
  .quote-result-wraper .quote-result-section {
    float: left;
    padding-left: 0;
    padding-right: 0;
    margin-top: 10px; }
    .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 {
      padding-bottom: 0px; }
    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section {
      padding: 0 10px; }
      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section {
        border-bottom: 1px solid #ddd; }
  /* dashboard */
  .main-wraper .main-content.col-rightlayout .card {
    padding: 10px; }
  .main-wraper .main-content.col-rightlayout.dashboardBreadcrumb .left-section {
    width: 100%; }
    .main-wraper .main-content.col-rightlayout.dashboardBreadcrumb .left-section .col {
      width: 100% !important; }
  .main-wraper .main-content.col-rightlayout .left-section {
    width: 100%; }
    .main-wraper .main-content.col-rightlayout .left-section .dashboardPopup.leadPopup {
      right: -600px; }
    .main-wraper .main-content.col-rightlayout .left-section .col-grid2 > .col {
      float: left;
      padding-right: 0px; }
      .main-wraper .main-content.col-rightlayout .left-section .col-grid2 > .col:first-child {
        padding-right: 0px; }
  .main-wraper .main-content.col-rightlayout .right-section {
    width: 100%;
    max-width: 100%; }
  .main-wraper .main-content.col-rightlayout .sortBlock .sort {
    width: 48%;
    margin-right: 2%; }
    .main-wraper .main-content.col-rightlayout .sortBlock .sort:last-child {
      margin-right: 0px; }
  /* dashboard end */ }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* dashboard */
  .main-wraper .main-content.col-rightlayout .card {
    padding: 10px; }
  .main-wraper .main-content.col-rightlayout .left-section .dashboardPopup.leadPopup {
    right: -600px; }
  .main-wraper .main-content.col-rightlayout .left-section .col-grid2 > .col {
    float: left;
    padding-right: 0px;
    width: 100%; }
    .main-wraper .main-content.col-rightlayout .left-section .col-grid2 > .col:first-child {
      padding-right: 0px; }
  .main-wraper .main-content.col-rightlayout .sortBlock .sort {
    width: 48%;
    margin-right: 2%; }
    .main-wraper .main-content.col-rightlayout .sortBlock .sort:last-child {
      margin-right: 0px; }
  /* dashboard end */ }

@media only screen and (min-width: 990px) and (max-width: 1252px) {
  /* quote form */
  .topSearch .btnsrc {
    background: #fff; }
  .topSearch input {
    padding-right: 70px !important;
    text-overflow: ellipsis; }
  .white-section.quote {
    padding: 20px 15px; }
  .white-section.quote .content-section .item-detail .col-left .row .column1 {
    width: 33%; }
  .white-section.quote .content-section .item-detail .col-left .row .column3 {
    width: 67%;
    padding-left: 20px;
    padding-right: 20px; }
  .white-section.quote .content-section .item-detail .col-left .row + .row .column3 .dimension {
    width: 100%; }
  .white-section.quote .content-section .item-detail .col-left .row + .row .column3 .dimension-check {
    width: 100%; }
    .white-section.quote .content-section .item-detail .col-left .row + .row .column3 .dimension-check .checkbox-lbl {
      width: 50%;
      float: left; }
  .icon-field.other-unit-type .formControl label {
    white-space: nowrap;
    overflow: hidden;
    width: 77%;
    text-overflow: ellipsis; }
  .white-section.quote.pickupInfo.Extrashipmentservices .col-grid4 > .col {
    margin-bottom: 10px; } }
